import { superState_itemIds } from "../../superState/superState_itemIds";
import { state_numLoadedItems } from "../state_numLoadedItems";
import { state_openedItemId } from "../state_openedItemId";

export function actions_jumpToItem(deltaIndexToOpenedItem: number) {
  const itemIds = superState_itemIds.get();

  if (itemIds.length === 0) {
    state_openedItemId.set(undefined);
    return;
  }

  const openedItemId = state_openedItemId.get();
  if (!openedItemId) return;

  const openedItemIdIndex = itemIds.findIndex(
    (itemId) => openedItemId === itemId
  );

  const newIndex = Math.min(
    state_numLoadedItems.get() - 1,
    itemIds.length - 1,
    Math.max(openedItemIdIndex + deltaIndexToOpenedItem, 0)
  );

  state_openedItemId.set(itemIds[newIndex]);
}
