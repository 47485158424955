import classNames from "classnames";
import React, { ReactNode } from "react";
import { lib_styleSheets } from "../../../libs/functions/lib_stylesheets";
import { View } from "../View/View";
import "./ButtonBar.scss";

const ButtonBar = ({
  children,
  opacity = 1,
  zIndex = 0,
  backgroundColor /* = "rgba(255, 255, 255, 0.5)"*/,
  backdropFilter /* = "blur(2px)"*/,
  borderBottom,
  flexDirection,
}: {
  children: ReactNode;
  opacity?: number;
  zIndex?: number;
  backgroundColor?: string;
  backdropFilter?: string;
  borderBottom?: number | string;
  flexDirection?: "column" | "row";
}) => {
  // console.log("🖌️ render <ButtonBar>", backdropFilter);

  const className = classNames({
    ButtonBar: true,
  });

  const styles = lib_styleSheets.create({
    ButtonBar: {
      zIndex,
      opacity,
      backgroundColor,
      backdropFilter,
      borderBottom,
      flexDirection,
    },
  });

  return (
    <View className={className} style={styles.ButtonBar}>
      {children}
    </View>
  );
};

export default React.memo(ButtonBar);
