import React from "react";
import { state_appMetaTheme } from "../../../state/state_appMetaTheme";

// This component makes the app scale-able whenever an image/album is
// opened into an overlay, allowing the user to pan & zoom
//
// Note: had tried to use react-helmet before but could'nt make it run
// so I switched to this vanillajs solution

function AppMetaTheme() {
  const appMetaTheme = state_appMetaTheme.useSubscribe();
  let viewport = document.querySelector("meta[name=theme-color]");
  viewport?.setAttribute("content", appMetaTheme);
  return null;
}

export default React.memo(AppMetaTheme);
