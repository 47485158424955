// WORKS!!!

import { dexieDb } from "../../dexieDb/dexieDb";

export async function hasIndexedDbBeenUsedBefore() {
  const result = await dexieDb.kvMap.get("hasIndexedDbBeenUsedBefore");

  if (result?.value !== "YES") {
    await dexieDb.kvMap.put({
      key: "hasIndexedDbBeenUsedBefore",
      value: "YES",
    });
  }

  return result?.value === "YES";
}
