import { lib_blobToFile } from "../../libs/libs_image";
import { $Item } from "../types";
import { $Base64ImageItem } from "./types";

export default async function userShare(item: $Item): Promise<ShareData> {
  return new Promise(async (resolve) => {
    const _item = item as $Base64ImageItem;

    const imageBlob = await fetch(_item.base64).then((res) => res.blob());
    const imageFile = lib_blobToFile(imageBlob, "lisd-download");

    resolve({ files: [imageFile] });
  });
}
