import { $ShoppingItem } from "../../plugins/shopping/types";
import { sounds_setIsBought } from "../../sounds/sounds";
import { state_hasBeenUpdatedItemId } from "../state_hasBeenUpdatedItemId";
import { state_items } from "../state_items";
import { state_openedItemId } from "../state_openedItemId";

export function actions_toggleItemIsBought(itemId: string) {
  const item = state_items.getItem(itemId);

  if (item.type !== "shopping") {
    return;
  }

  const shoppingItem = item as $ShoppingItem;
  if (!shoppingItem.isBought) {
    sounds_setIsBought.play();
  }

  state_items.updateItem(itemId, { isBought: !shoppingItem.isBought });
  state_hasBeenUpdatedItemId.set(itemId);

  if (itemId !== state_openedItemId.get()) {
    state_openedItemId.set(undefined);
  }
}
