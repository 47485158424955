import React from "react";
import { registeredPlugins } from "../../../plugins/registeredPlugins";
import { state_items } from "../../../state/state_items";
import ItemProcessedFailure from "../ItemProcessedFailure/ItemProcessedFailure";
import ItemProcessing from "../ItemProcessing/ItemProcessing";
import "./ItemContent.scss";

function ItemContent({
  itemId,
  isInFocus,
  isOpened,
}: {
  itemId: string;
  isInFocus?: boolean;
  isOpened?: boolean;
}) {
  const item = state_items.useSubscribeItem(itemId);
  if (item === undefined) {
    return null;
  }

  const itemType = item.type as string;
  // console.log({ itemType, registeredPlugins });
  const plugin = registeredPlugins[itemType];

  const doRetry = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (plugin.restartItemProcessing) {
      plugin.restartItemProcessing(itemId);
    }
  };

  if (!item.isProcessed) {
    return (
      <div className="ItemContent">
        <ItemProcessing caption="Processing..." doOpen={(e) => {}} />
      </div>
    );
  }

  // processed but has failed...
  if (item.isProcessedFailure) {
    return (
      <div className="ItemContent">
        <ItemProcessedFailure
          caption={
            item.isProcessedFailure ||
            "TODO REPLACE THIS WITH A PROPER ERROR MESSAGE"
          }
          doOpen={(e) => {}}
          doRetry={doRetry}
        />
      </div>
    );
  }
  const ItemPlugin = plugin.ItemPlugin;
  return (
    <div className="ItemContent">
      <ItemPlugin item={item} isInFocus={isInFocus} isOpened={isOpened} />
    </div>
  );
}

export default React.memo(ItemContent);
