import React, { useState } from "react";
import "./BasicItemImage.scss";

type $BasicItemImage = {
  url: string;
  alt?: string;
  width?: number;
  height?: number;
  onClick?: React.MouseEventHandler;
};

function BasicItemImage({ url, alt, width, height, onClick }: $BasicItemImage) {
  const [, setImageError] = useState(false);
  return (
    <div className="BasicItemImage">
      <img
        src={url}
        alt={alt}
        width={width}
        height={height}
        onClick={onClick}
        onError={() => setImageError(true)}
        loading="lazy"
      />
    </div>
  );
}

export default React.memo(BasicItemImage);
