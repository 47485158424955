import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React from "react";
import Button from "../../../components/lib/Button/Button";
import "./ItemToolbar.scss";

function ItemToolbar({
  isOpened = false,
  isInFocus = false,
}: {
  isOpened: boolean;
  isInFocus?: boolean;
}) {
  if (isInFocus) return null;

  return (
    <div className={classNames("ItemToolBar", { isOpened })}>
      {!isOpened && (
        <Button
          buttonSize={40}
          icon={faBars}
          iconScale={1.5}
          iconColor={"rgba(0,0,0,0.125)"}
        />
      )}

      {isOpened && (
        <Button
          buttonSize={40}
          icon={faTimes}
          iconScale={1.5}
          iconColor={"rgba(0,0,0,0.125)"}
        />
      )}
    </div>
  );
}

export default React.memo(ItemToolbar);
