import { $Item } from "../types";
import { $ShoppingItem } from "./types";

export function userShare(item: $Item): Promise<ShareData> {
  return new Promise((resolve) => {
    const _item = item as $ShoppingItem;
    resolve({
      text: "🛒 " + _item.text,
    });
  });
}
