import "animate.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/app/App/App";
import {ErrorBoundary} from "./components/app/ErrorBoundary/ErrorBoundary";
import "./index.scss";
import {startup_importRegisteredPlugins} from "./startup/startup_importRegisteredPlugins";
import {startup_loadItemData} from "./startup/startup_loadItemData";
import {startup_registerEmptyPushState} from "./startup/startup_registerEmptyPushState";
import {startup_registerGoBackBindings} from "./startup/startup_registerGoBackBindings";
import {startup_registerGyroscopeListener} from "./startup/startup_registerGyroscopeListener";
import {startup_registerKeyBindings} from "./startup/startup_registerKeyBindings";
import {startup_registerProcessUnprocessedItems} from "./startup/startup_registerProcessUnprocessedItems";
import {startup_registerReActions} from "./startup/startup_registerReActions";
import {startup_registerSendItemUpdatesToServer} from "./startup/startup_registerSendItemUpdatesToServer";
import {startup_registerServiceWorker} from "./startup/startup_registerServiceWorker";
import {startup_registerVirtualKeyboard} from "./startup/startup_virtualKeyboard";

import {Monitoring} from "react-scan/dist/core/monitor/index";

// startup: load data, add event listeners, run timeouts and intervals
startup_registerServiceWorker();
startup_importRegisteredPlugins();
startup_registerReActions();
startup_loadItemData();
startup_registerProcessUnprocessedItems();
startup_registerSendItemUpdatesToServer();
startup_registerEmptyPushState();
startup_registerVirtualKeyboard();
startup_registerKeyBindings();
startup_registerGoBackBindings();
startup_registerGyroscopeListener();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <Monitoring
        apiKey="_VnXZXAaAgzCojsGXdUu6s254jMFhcCZ"
        params={{}}
        path="/"
        url="https://monitoring.react-scan.com/api/v1/ingest"
      />
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);
