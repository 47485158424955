import { dripdrop } from "dripdrop";

let isItemsDataLoaded: boolean = false;

function set(value: boolean) {
  if (isItemsDataLoaded === value) return;

  isItemsDataLoaded = value;
  dripdrop.notifySubscribers("isItemsDataLoaded");
}

function get() {
  return isItemsDataLoaded;
}

function useSubscribe() {
  dripdrop.useSubscribe("isItemsDataLoaded");
  return isItemsDataLoaded;
}

export const state_isItemsDataLoaded = {
  set,
  get,
  useSubscribe,
};
