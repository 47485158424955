import { dripdrop } from "dripdrop";

export type $GyroscopeData = {
  alpha: number;
  beta: number;
  gamma: number;
};

let gyroscopeData: $GyroscopeData | undefined = undefined;

function set(value: $GyroscopeData) {
  if (gyroscopeData === value) return;

  gyroscopeData = value;
  dripdrop.notifySubscribers("gyroscopeData");
}

function useSubscribe() {
  dripdrop.useSubscribe("gyroscopeData");
  return gyroscopeData;
}

export const state_gyroscopeData = { set, useSubscribe };
