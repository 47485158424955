// prevent back button from closing the app
// unsure why but must be called even before the <App> is rendered

export function startup_registerEmptyPushState() {
  // https://stackoverflow.com/a/49719812
  // https://florianherlings.de/posts/2020-04-22-history-javascript-pushstate/
  const loadHandler = () => {
    window.history.pushState({}, "");
  };

  window.addEventListener("load", loadHandler);
}
