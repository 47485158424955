import { dripdrop } from "dripdrop";

let isRecording = false;
let isProcessing = false;
let filterSummary: string | null = null;
let previousQuery: string | null = null;

function setIsRecording(value: boolean) {
  if (isRecording === value) return;
  isRecording = value;
  dripdrop.notifySubscribers("filterRecording");
}

function setIsProcessing(value: boolean) {
  if (isProcessing === value) return;
  isProcessing = value;
  dripdrop.notifySubscribers("filterRecording");
}

function setFilterSummary(value: string | null) {
  if (filterSummary === value) return;
  filterSummary = value;
  dripdrop.notifySubscribers("filterRecording");
}

function setPreviousQuery(value: string | null) {
  if (previousQuery === value) return;
  previousQuery = value;
  dripdrop.notifySubscribers("filterRecording");
}

function get() {
  return {
    isRecording,
    isProcessing,
    filterSummary,
    previousQuery
  };
}

function useSubscribe() {
  dripdrop.useSubscribe("filterRecording");
  return get();
}

export const state_filterRecording = {
  setIsRecording,
  setIsProcessing,
  setFilterSummary,
  setPreviousQuery,
  useSubscribe,
  get
};
