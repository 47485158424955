import { ReactNode, useEffect, useState } from "react";
import { ANIMATIONS_DURATION } from "../../../config/constants";

function DelayRender({
  delay = ANIMATIONS_DURATION / 2,
  children,
}: {
  delay?: number;
  children: ReactNode;
}) {
  const [isDelayOver, setIsDelayOver] = useState(false);

  useEffect(() => {
    let timeout: number | undefined = undefined;

    timeout = window.setTimeout(() => {
      setIsDelayOver(true);
    }, delay);

    return () => window.clearTimeout(timeout);
  }, [delay]);

  return isDelayOver ? <>{children}</> : null;
}

export default DelayRender;
