import { dripdrop } from "dripdrop";
import { ANIMATIONS_DURATION } from "../config/constants";

let hasBeenUpdatedItemId: string | undefined = undefined;

function set(value?: string, callback?: () => void) {
  if (hasBeenUpdatedItemId === value) return;

  hasBeenUpdatedItemId = value;
  dripdrop.notifySubscribers("hasBeenUpdatedItemId");

  setTimeout(() => {
    if (callback) callback();
    hasBeenUpdatedItemId = undefined;
    dripdrop.notifySubscribers("hasBeenUpdatedItemId");
  }, ANIMATIONS_DURATION * 4);
}

function useSubscribe() {
  dripdrop.useSubscribe("hasBeenUpdatedItemId");
  return hasBeenUpdatedItemId;
}

export const state_hasBeenUpdatedItemId = {
  set,
  useSubscribe,
};
