/* eslint-disable react/prop-types */
// https://stackoverflow.com/a/69769645
/* eslint-disable jsx-a11y/anchor-has-content */
import { faSearchMinus, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import BasicItemText from "../../../components/app/BasicItem/BasicItemText";
import Button from "../../../components/lib/Button/Button";
import Loader from "../../../components/lib/Loader/Loader";
import { Position } from "../../../components/lib/Position/Position";
import { RelativePosition } from "../../../components/lib/RelativePosition/RelativePosition";
import { SimpleAnimation } from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import {
  ANIMATIONS_DURATION,
  ANIMATIONS_ENTRY_ANIMATION,
} from "../../../config/constants";
import { lib_isMobileBrowser } from "../../../libs/functions/lib_isMobileBrowser";
import { lib_getDateCaption } from "../../../libs/libs_date";
import { state_itemDraft } from "../../../state/state_itemDraft";
import { state_items } from "../../../state/state_items";
import { state_openedItemId } from "../../../state/state_openedItemId";
import { $Item } from "../../types";
import { MAP_MAX_ZOOM_LEVEL, MAP_MIN_ZOOM_LEVEL } from "../constants";
import { restartItemProcessing } from "../processRawInput";

import { $LatLng, $LocationItem, $MapData } from "../types";
import "./LocationItem.scss";

function doOpenLatLngExternal(latLng: $LatLng) {
  const latLngParams = `${latLng.lat},${latLng.lng}`;
  if (lib_isMobileBrowser()) {
    window.location.href = `geo:${latLngParams}`;
  } else {
    window.location.href = `https://maps.google.com/?q=${latLngParams}`;
  }
}

function LocationItem({
  item,
  isInFocus,
}: {
  item: $Item;
  isInFocus?: boolean;
}) {
  const locationItem = item as $LocationItem;

  // return <LocationItemProcessedFailure locationItem={locationItem} />;
  // return <LocationItemProcessing locationItem={locationItem} />;
  // still processing...

  // processed with success

  const text =
    (isInFocus && state_itemDraft.get(item.id)?.text) ||
    locationItem.text ||
    "No Name";

  return (
    <div className="LocationItem">
      <BasicItemText itemId={item.id} text={text} isInFocus={isInFocus} />
      <LocationMap item={item} mapData={locationItem.mapData} />

      <span className="LocationAttribution">
        <a href="https://openstreetmap.org">&copy; OpenStreetMap</a>
      </span>
    </div>
  );
}

export default LocationItem;

function LocationMap({ item, mapData }: { item: $Item; mapData?: $MapData }) {
  const [, setImageError] = useState(false);
  const [isZooming, setIsZooming] = useState(false);
  const locationItem = item as $LocationItem;
  const isOpened = state_openedItemId.useSubscribeIsEqualToOpenedItemId(
    item.id
  );

  const doOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (locationItem.mapData?.imageUrl) {
      doOpenLatLngExternal(locationItem.latLng);
    }
  };

  const doZoom = (zoomLevel: number) => {
    if (mapData) {
      setIsZooming(true);
      // calls plugin-specific processor
      state_items.updateItem(item.id, { mapData: { zoomLevel } });
      restartItemProcessing(item.id);
    }
  };

  const doZoomIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (mapData && mapData.zoomLevel < MAP_MAX_ZOOM_LEVEL) {
      doZoom(mapData.zoomLevel + 1);
    }
  };

  const doZoomOut = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (mapData && mapData.zoomLevel > MAP_MIN_ZOOM_LEVEL) {
      doZoom(mapData.zoomLevel - 1);
    }
  };

  const onImageError = () => {
    setIsZooming(false);
    setImageError(true);
  };

  const onImageLoad = () => {
    setIsZooming(false);
  };

  return (
    <SimpleAnimation
      className="LocationMap_Animation"
      showAnimation={ANIMATIONS_ENTRY_ANIMATION}
      showAnimationDuration={ANIMATIONS_DURATION}
    >
      <div className="LocationMap" onClick={doOpen}>
        {mapData && (
          <>
            <img
              src={mapData.imageUrl}
              alt={mapData.title}
              onError={onImageError}
              onLoad={onImageLoad}
              loading="lazy"
            />

            <Position
              bottom={5}
              left={5}
              opacity={isOpened ? 1 : 0}
              pointerEvents={isOpened ? "auto" : "none"}
            >
              <Button
                icon={faSearchMinus}
                iconScale={1.5}
                onClick={doZoomOut}
                iconColor={
                  mapData.zoomLevel === MAP_MIN_ZOOM_LEVEL
                    ? "rgba(0,0,0,0.25)"
                    : "black"
                }
              />
            </Position>

            <Position
              bottom={5}
              right={5}
              opacity={isOpened ? 1 : 0}
              pointerEvents={isOpened ? "auto" : "none"}
            >
              <Button
                icon={faSearchPlus}
                iconScale={1.5}
                onClick={doZoomIn}
                iconColor={
                  mapData.zoomLevel === MAP_MAX_ZOOM_LEVEL
                    ? "rgba(0,0,0,0.25)"
                    : "black"
                }
              />
            </Position>

            {isZooming && (
              <Position
                inset={0}
                alignItems="center"
                justifyContent="center"
                pointerEvents="none"
              >
                <Loader />
              </Position>
            )}
          </>
        )}

        <LocationItemDateCaption createdAt={item.createdAt} />
      </div>
    </SimpleAnimation>
  );
}

function LocationItemDateCaption({ createdAt }: { createdAt: Date }) {
  return (
    <Position
      pointerEvents="none"
      zIndex={1}
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0,0,0,0.125)"
      left={0}
      right={0}
      top={0}
    >
      <RelativePosition className="LocationItemDateCaption">
        {lib_getDateCaption(createdAt).captionLight}
      </RelativePosition>
    </Position>
  );
}
