import Dexie, { Table, Transaction } from "dexie";
import { $Item } from "../plugins/types";

type $KV = {
  key: string;
  value: string;
};

type $ItemQueueEntry = {
  itemId: string;
  createdAt: Date;
};

export class LisdDexieDb extends Dexie {
  // 'items' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  items!: Table<$Item>;
  qItemIdsAdded!: Table<$ItemQueueEntry>;
  itemIdsUpdatedQueue!: Table<$ItemQueueEntry>;
  itemIdsDeletedQueue!: Table<$ItemQueueEntry>;

  qItemIdsUpdated!: Table<$ItemQueueEntry>;
  qItemIdsDeleted!: Table<$ItemQueueEntry>;

  kvMap!: Table<$KV>;

  constructor() {
    super("lisd-database");
    this.version(1).stores({
      items: "++id, createdAt", // Primary key and indexed props
      qItemIdsUpdated: "++itemId, createdAt",
      qItemIdsDeleted: "++itemId, createdAt",
      kvMap: "key, value", // Primary key and indexed props
    });

    const migrateQueuesV1ToV2 = async (
      tx: Transaction,
      oldQueue: string,
      newQueue: string
    ) => {
      return await tx
        .table(oldQueue)
        .toArray()
        .then(async (itemIds: string[]) => {
          return tx.table(newQueue).bulkAdd(itemIds);
        });
    };

    // version 2 renames 2 queue tables and modifies the item id primary key
    // https://github.com/dexie/Dexie.js/issues/713#issuecomment-393865518
    this.version(2) // https://dexie.org/docs/Tutorial/Design#database-versioning
      .stores({
        itemIdsUpdatedQueue: "itemId, createdAt", // create new updated queue
        itemIdsDeletedQueue: "itemId, createdAt", // create new deleted queue
      })
      .upgrade(async (tx) => {
        await migrateQueuesV1ToV2(tx, "qItemIdsUpdated", "itemIdsUpdatedQueue");
        await migrateQueuesV1ToV2(tx, "qItemIdsDeleted", "itemIdsDeletedQueue");
      });

    this.version(3).stores({
      qItemIdsUpdated: null, // delete old updated queue
      qItemIdsDeleted: null, // delete old deleted queue
    });

    // TODO will give this up for now, find a workaround later:
    // it is not easily possible to take away the unnecessary ++ flag for "items.id"
    // https://github.com/dexie/Dexie.js/issues/1025#issuecomment-922372266
    //
    // Throws: 'UpgradeError Not yet support for changing primary key'
    // console.log("v4: 'id' is not an autoincrement value anymore");
    // this.version(4).stores({
    //   items: "id, createdAt",
    // });
  }
}

export const dexieDb = new LisdDexieDb();
