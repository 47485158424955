import { lib_checkIsValidHttpUrl } from "../../libs/functions/lib_checkIsValidHttpUrl";
import { state_filterItem } from "../../state/state_filterItem";
import { state_items } from "../../state/state_items";
import { sendUrlToApiAndGetDataForWebsite } from "./sendUrlToApiAndGetDataForWebsite";
import { $LinkItem } from "./types";

// console.log(
//   isValidHttpUrl(
//     "https://stackoverflow.com/questions/12739171/javascript-epoch-time-in-days"
//   )
// );

export function restartItemProcessing(itemId: string) {
  state_items.startOrRestartItemProcessing(itemId);
  const linkItem = state_items.getItem(itemId) as $LinkItem;
  sendUrlToApiAndGetDataForWebsite(itemId, linkItem.url);
}

export function processRawInput(itemId: string, rawInput: string) {
  const isLink = lib_checkIsValidHttpUrl(rawInput);
  if (!isLink) return false;

  state_items.updateItem(itemId, {
    type: "link",
    isProcessed: true,
    url: rawInput,
  });

  if (state_filterItem.getIsSet()) {
    state_filterItem.set({ type: "link", isHearted: false });
  }

  state_items.startOrRestartItemProcessing(itemId);
  sendUrlToApiAndGetDataForWebsite(itemId, rawInput);
  return "link";
}
