import React, { useCallback } from "react";
import BasicItem from "../../../components/app/BasicItem/BasicItem";
import { actions_toggleItemIsDone } from "../../../state/actions/actions_setItemIsDone";
import { state_itemDraft } from "../../../state/state_itemDraft";

import { $Item } from "../../types";
import { $TodoItem } from "../types";
import TodoItemCheckbox from "./TodoItemCheckbox";

function TodoItem({ item, isInFocus }: { item: $Item; isInFocus?: boolean }) {
  const todoItem = item as $TodoItem;
  const isDone = !!todoItem.isDone; // important as isDone can also be undefined

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      actions_toggleItemIsDone(item.id);
    },
    [item.id]
  );

  const text =
    (isInFocus && state_itemDraft.get(item.id)?.text) || todoItem.text || "";
  return (
    <BasicItem
      itemId={item.id}
      onClick={onClick}
      icon={<TodoItemCheckbox isDone={isDone} />}
      text={text}
      isInactive={todoItem.isDone}
      isInFocus={isInFocus}
    />
  );
}

export default TodoItem;
