import { dripdrop } from "dripdrop";
import { $Prompt } from "./PromptBottomSheet.types";

let prompt: $Prompt | undefined = undefined;

export function actionSetPrompt(value: undefined | $Prompt) {
  if (prompt === value) return;

  prompt = value;
  dripdrop.notifySubscribers("prompt");
}

export function useSubscribePrompt() {
  dripdrop.useSubscribe("prompt");
  return prompt;
}
