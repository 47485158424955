import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import colorAlpha from "color-alpha";
import React, {useCallback, useLayoutEffect, useRef} from "react";
import {ANIMATIONS_DURATION} from "../../../config/constants";
import {$ItemType} from "../../../plugins/types";
import {state_filterItem} from "../../../state/state_filterItem";
import {state_isListScrolledToBottom} from "../../../state/state_isListScrolledToBottom";
import {superState_currentScreen} from "../../../superState/superState_currentScreen";
import {superState_sortedTypes} from "../../../superState/superState_sortedTypes";
import {Position} from "../../lib/Position/Position";
import {RelativePosition} from "../../lib/RelativePosition/RelativePosition";
import {SimpleAnimation} from "../../lib/SimpleAnimation/SimpleAnimation";
import "./ItemFilterType.scss";

function ItemFilterType() {
  const typesListRef = useRef<HTMLDivElement>(null);
  const sortedTypes = superState_sortedTypes.useSubscribe();
  const currentScreen = superState_currentScreen.useSubscribe();

  const prevIndex = useRef(0);
  useLayoutEffect(() => {
    const currentRef = typesListRef.current;
    const typeIndex = sortedTypes.findIndex(
      (t) => t.type === state_filterItem.getType()
    );

    if (typeIndex !== prevIndex.current) {
      const left = typeIndex * (40 + 20);
      currentRef?.scrollTo({behavior: "smooth", left});
      prevIndex.current = typeIndex;
    }
  }, [sortedTypes]);

  const onClickType = useCallback(function onClickType(type: $ItemType) {
    state_filterItem.setType(type);
    state_isListScrolledToBottom.set(true);
  }, []);

  const doShow = ["list", "item-opened"].includes(currentScreen); //openedItemId === undefined;

  return (
    <Position
      left={0}
      right={0}
      bottom={0}
      flex={1}
      className="ItemFilterType_Position"
      opacity={doShow ? 1 : 0}
      pointerEvents={doShow ? "auto" : "none"}
      innerRef={typesListRef}
    >
      <div className="ItemFilterType">
        {sortedTypes.map((typeSpec, renderIdx) => {
          const isSet = state_filterItem.getType() === typeSpec.type;
          return (
            <SimpleAnimation
              key={typeSpec.type || "all"}
              showAnimation="bounceIn"
              showAnimationDuration={ANIMATIONS_DURATION * 2}
              showAnimationDelay={(renderIdx * ANIMATIONS_DURATION) / 10}
            >
              <RelativePosition
                className="ItemTypeWrapper"
                backgroundColor="white"
                scale={isSet ? 1.5 : 1}
              >
                <div
                  className={`ItemType${isSet ? " isSet" : ""}`}
                  style={{
                    backgroundColor: colorAlpha(typeSpec.color, 0.25),
                  }}
                  onClick={() => onClickType(typeSpec.type)}
                >
                  <FontAwesomeIcon
                    icon={typeSpec.icon}
                    color={typeSpec.color}
                    fontSize={20}
                  />
                </div>
              </RelativePosition>
            </SimpleAnimation>
          );
        })}
      </div>
    </Position>
  );
}

export default React.memo(ItemFilterType);
