import { Howl } from "howler";

const sounds_setIsHearted = new Howl({
  src: ["/sounds/select_002.ogg"],
  preload: true,
});

const sounds_setIsDone = new Howl({
  src: ["/sounds/select_004.ogg"],
  preload: true,
});

const sounds_setIsBought = new Howl({
  src: ["/sounds/select_004.ogg"],
  preload: true,
});

const sounds_setIsDeleted = new Howl({
  src: ["/sounds/select_005.ogg"],
  preload: true,
});

export {
  sounds_setIsHearted,
  sounds_setIsDone,
  sounds_setIsBought,
  sounds_setIsDeleted,
};
