import classNames from "classnames";
import React, { ReactNode } from "react";
import useShouldRenderChildren from "../../../hooks/useShouldRenderChildren";
import { Position } from "../Position/Position";
import "./BottomSheet.scss";
import BottomSheetContent from "./BottomSheetContent";
import BottomSheetCurtain from "./BottomSheetCurtain";
import BottomSheetFooter from "./BottomSheetFooter";
import BottomSheetHeader from "./BottomSheetHeader";

function BottomSheet({
  doShow,
  doShowCurtain = true,
  doHideCallback,
  backgroundColor = "white",
  shadowColor,
  canResizeFullscreen,
  isSwipeable,
  children,
  Header,
  Footer,
  additionalClassName,
}: {
  doShow: boolean;
  doShowCurtain?: boolean;
  doHideCallback?: () => void;
  backgroundColor?: string;
  shadowColor?: string;
  canResizeFullscreen?: boolean;
  isSwipeable?: boolean;
  children: ReactNode;
  Header?: ReactNode;
  Footer?: ReactNode;
  additionalClassName?: string;
}) {
  const doShowContent = useShouldRenderChildren(doShow);
  const doUnmount = !doShow && !doShowContent;

  if (doUnmount) return null;

  const BottomSheetClassObject = additionalClassName
    ? {
        BottomSheet: true,
        [additionalClassName]: additionalClassName,
      }
    : { BottomSheet: true };

  const classes = {
    BottomSheet: classNames(BottomSheetClassObject),
  };

  return (
    <Position
      inset={0}
      type="absolute"
      pointerEvents={"none"}
      flexDirection={"column"}
      className={classes.BottomSheet}
    >
      {doShowCurtain && (
        <BottomSheetCurtain onClick={doHideCallback} doHide={!doShow} />
      )}

      <BottomSheetContent
        doHide={!doShow}
        doHideCallback={doHideCallback}
        backgroundColor={backgroundColor}
        shadowColor={shadowColor}
        canResizeFullscreen={canResizeFullscreen}
        isSwipeable={isSwipeable}
        Header={<BottomSheetHeader>{Header}</BottomSheetHeader>}
        Footer={<BottomSheetFooter>{Footer}</BottomSheetFooter>}
      >
        {children}
      </BottomSheetContent>
    </Position>
  );
}

export default React.memo(BottomSheet);
