import { sounds_setIsDeleted } from "../../sounds/sounds";
import { superState_currentScreen } from "../../superState/superState_currentScreen";
import { superState_itemIds } from "../../superState/superState_itemIds";
import { state_items } from "../state_items";
import { state_openedItemId } from "../state_openedItemId";
import { state_toBeHiddenItemId } from "../state_toBeHiddenItemId";

function setAlternativeOpenedItemId(openedItemId: string) {
  const itemIds = superState_itemIds.get();
  const openedItemIdIndex = itemIds.findIndex(
    (itemId) => openedItemId === itemId
  );

  if (itemIds.length === 1) {
    state_openedItemId.set(undefined);
  } else if (openedItemIdIndex === itemIds.length - 1) {
    state_openedItemId.set(itemIds[openedItemIdIndex - 1]);
  } else {
    state_openedItemId.set(itemIds[openedItemIdIndex + 1]);
  }
}

export function actions_deleteOpenedItem() {
  if (superState_currentScreen.get() !== "item-opened") {
    return;
  }

  const openedItemId = state_openedItemId.get();
  if (openedItemId === undefined) {
    return;
  }

  state_toBeHiddenItemId.set(openedItemId, async () => {
    sounds_setIsDeleted.play();
    setAlternativeOpenedItemId(openedItemId);
    state_items.removeItem$(openedItemId);
  });
}
