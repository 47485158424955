import {
  ANIMATIONS_DURATION,
  MAX_ITEM_CONTENT_HEIGHT,
  MAX_ITEM_CONTENT_WIDTH,
  VERTICAL_MARGIN,
} from "../../../config/constants";
import {state_filterItem} from "../../../state/state_filterItem";
import PromptBottomSheet from "../../lib/PromptBottomSheet/PromptBottomSheet";
import PushNotifications from "../../lib/PushNotifications/PushNotifications";
import AddItemMenu from "../AddItemMenu/AddItemMenu";
import AddItemMenuButton from "../AddItemMenuButton/AddItemMenuButton";
import Background from "../Background/Background";
import FilterStats from "../FilterStats/FilterStats";
import ImageAlbum from "../ImageAlbum/ImageAlbum";
import ItemDeleteButton from "../ItemDeleteButton/ItemDeleteButton";
import ItemDraftToolbar from "../ItemDraftToolbar/ItemDraftToolbar";
import ItemFilterIsHearted from "../ItemFilterIsHearted/ItemFilterIsHearted";
import ItemFilterType from "../ItemFilterType/ItemFilterType";
import ItemInFocus from "../ItemInFocus/ItemInFocus";
import ItemShareButton from "../ItemShareButton/ItemShareButton";
import ItemsList from "../ItemsList/ItemsList";
import MainMenu from "../MainMenu/MainMenu";
import MainMenuButton from "../MainMenuButton/MainMenuButton";
import ScrollDownButton from "../ScrollDownButton/ScrollDownButton";
import "./App.scss";
import AppMetaTheme from "./AppMetaTheme";
import AppMetaViewport from "./AppMetaViewport";

function App() {
  const filterItem = state_filterItem.useSubscribe();

  const styleConstants = {
    "--VERTICAL_MARGIN": VERTICAL_MARGIN + "px",
    "--MAX_ITEM_CONTENT_WIDTH": MAX_ITEM_CONTENT_WIDTH + "px",
    "--MAX_ITEM_CONTENT_HEIGHT": MAX_ITEM_CONTENT_HEIGHT + "px",
    "--ANIMATIONS_DURATION": ANIMATIONS_DURATION + "ms",
    "--animate-duration": ANIMATIONS_DURATION + "ms", // needed to override the default duration of animate.css lib
  } as React.CSSProperties;

  const uniqueItemsListKey = `${filterItem.isHearted}.${filterItem.type}`;

  return (
    <>
      <Background />
      <div className="App" style={styleConstants}>
        <AppMetaViewport />
        <AppMetaTheme />
        <ItemsList key={uniqueItemsListKey} />
        <ItemInFocus />
        <ItemDraftToolbar />
        <ItemFilterType />
        <ItemFilterIsHearted />
        <FilterStats />
        <ScrollDownButton />
        <ItemDeleteButton />
        <ItemShareButton />
        {/* <FakeKeyboard /> */}
        <AddItemMenu />
        <AddItemMenuButton />
        <MainMenu />
        <MainMenuButton />
        <ImageAlbum />
        <PromptBottomSheet />
        <PushNotifications />
        {/* <RecordButton /> */}
        {/* <IsTypingDebugger /> */}
      </div>
    </>
  );
}

export default App;
