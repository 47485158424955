import {faHeart as faHeartRegular} from "@fortawesome/free-regular-svg-icons";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {state_filterItem} from "../../../state/state_filterItem";
import {state_openedItemId} from "../../../state/state_openedItemId";
import Button from "../../lib/Button/Button";
import {Position} from "../../lib/Position/Position";
import "./ItemFilterIsHearted.scss";

function ItemFilterIsHearted() {
  const filterItem = state_filterItem.useSubscribe();
  const isItemOpened = state_openedItemId.useSubscribeIsItemOpened();

  if (isItemOpened) return null;

  return (
    <Position
      className="ItemFilterIsHearted_Position"
      left={20}
      bottom={20}
      alignItems="center"
      // zIndex={1} // above color selector
      scale={filterItem.isHearted ? 1.5 : 1}
      rotate={(filterItem.isHearted ? -25 : 0) + "px"}
    >
      <div className="ItemFilterIsHearted">
        <Button
          buttonSize={30}
          icon={filterItem.isHearted ? faHeart : faHeartRegular}
          onClick={() => {
            state_filterItem.setIsHearted(!filterItem.isHearted);
          }}
        />
      </div>
    </Position>
  );
}

export default React.memo(ItemFilterIsHearted);
