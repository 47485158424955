import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import colorAlpha from "color-alpha";
import React, { useEffect, useState } from "react";
import { lib_downloadFile } from "../../../libs/libs_file";
import { $Item, $UserShareFunction } from "../../../plugins/types";
import Button from "../../lib/Button/Button";
import { actionPushNotification } from "../../lib/PushNotifications/pushNotifications.state";

function ItemShareButtonInner({
  openedItem,
  shareFunction,
}: {
  openedItem?: $Item;
  shareFunction?: $UserShareFunction;
}) {
  const [canShare, setCanShare] = useState(false);
  const [shareData, setShareData] = useState<ShareData>({});

  // setShareData and setCanShare
  useEffect(() => {
    async function doAsync() {
      if (!shareFunction) return;
      if (!openedItem) return;

      const _shareData = await shareFunction(openedItem);
      setShareData(_shareData);

      if (!navigator.canShare) return;
      if (!openedItem) return;

      if (!navigator.canShare(_shareData)) return;

      setCanShare(true);
    }

    doAsync();
  }, [openedItem, shareFunction]);

  const icon = (() => {
    if (canShare) return faShareNodes;
    if (shareData.files) return faDownload;
    return faClipboard;
  })();

  const caption = (() => {
    if (canShare) return "SHARE";
    if (shareData.files) return "DOWNLOAD";
    return "CLIPBOARD";
  })();

  const onClick = () => {
    if (canShare) {
      navigator.share(shareData);
      return;
    }

    if (shareData.files) {
      //console.log(shareData.files)
      lib_downloadFile(shareData.files[0]);
      return;
    }

    const clipboardText = shareData.text || "";
    navigator.clipboard.writeText(clipboardText).then(() => {
      actionPushNotification("Saved to System Clipboard");
    });
  };

  const doShow = openedItem && shareFunction;

  if (!doShow) return null;

  return (
    <Button
      icon={icon}
      iconScale={1.5}
      iconColor="black"
      captionColor={colorAlpha("black", 0.5)}
      backgroundColor="yellow"
      borderRadius={10}
      onClick={onClick}
      caption={caption}
    />
  );
}

export default React.memo(ItemShareButtonInner);
