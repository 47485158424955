import registerBase64ImagePlugin from "./base64Image/registerBase64ImagePlugin";
import registerImagePlugin from "./image/registerImagePlugin";
import registerLinkPlugin from "./link/registerLinkPlugin";
import registerLocationPlugin from "./location/registerLocationPlugin";
import registerShoppingPlugin from "./shopping/registerShoppingPlugin";
import registerTextPlugin from "./text/registerTextPlugin";
import registerTodoPlugin from "./todo/registerTodoPlugin";
import registerTweetPlugin from "./tweet/registerTweetPlugin";
import { $PluginsType } from "./types";
import registerYouTubePlugin from "./youTube/registerYouTubePlugin";

const registeredPlugins: $PluginsType = {
  tweet: registerTweetPlugin(),
  youTube: registerYouTubePlugin(),
  base64Image: registerBase64ImagePlugin(),
  image: registerImagePlugin(),
  link: registerLinkPlugin(),
  location: registerLocationPlugin(),
  todo: registerTodoPlugin(),
  shopping: registerShoppingPlugin(),
  text: registerTextPlugin(), // text must be LAST (or there will be no default processor)!!!
};

export { registeredPlugins };
