/** The purpose of this method is to  */

import { useEffect, useState } from "react";
import { ANIMATIONS_DURATION } from "../config/constants";

// returns immediately TRUE if doShow is TRUE but delays return of FALSE in case of doShow FALSE
function useShouldRenderChildren(doShow: boolean) {
  const [doShowContent, setDoShowContent] = useState(doShow);

  useEffect(() => {
    let timeout: number | undefined = undefined;

    if (doShow) {
      window.clearTimeout(timeout);
      setDoShowContent(true);
    } else {
      if (doShowContent) {
        timeout = window.setTimeout(() => {
          setDoShowContent(false);
        }, ANIMATIONS_DURATION);
      }
    }

    return () => window.clearTimeout(timeout);
  }, [doShow, doShowContent]);

  return doShowContent;
}

export default useShouldRenderChildren;
