import { dripdrop } from "dripdrop";
import { ANIMATIONS_DURATION } from "../config/constants";

let toBeHiddenItemId: string | undefined = undefined;

function set(value?: string | undefined, onHidden?: () => void) {
  if (toBeHiddenItemId === value) return;

  toBeHiddenItemId = value;
  dripdrop.notifySubscribers("toBeHiddenItemId");

  if (toBeHiddenItemId) {
    setTimeout(() => {
      if (onHidden) {
        onHidden();
      }

      setTimeout(() => {
        toBeHiddenItemId = undefined;
        dripdrop.notifySubscribers("toBeHiddenItemId");
      }, ANIMATIONS_DURATION);
    }, ANIMATIONS_DURATION);
  }
}

function useSubscribe() {
  dripdrop.useSubscribe("toBeHiddenItemId");
  return toBeHiddenItemId;
}

export const state_toBeHiddenItemId = {
  set,
  useSubscribe,
};
