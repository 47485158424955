import { $TypeSpec, allTypes } from "../plugins/types";
import { state_filterItem } from "../state/state_filterItem";
import { state_items } from "../state/state_items";

// sort item types by the number of items each type holds
function compareLastAdded(a: $TypeSpec, b: $TypeSpec) {
  const itemIdsMap = state_items.getItemIdsMap();
  const filterItem = state_filterItem.get();
  const mapKeyPostfix = filterItem.isHearted ? ".isHearted" : "";

  if (a.type === undefined) {
    return 1;
  }

  const aLastItemId = itemIdsMap[(a.type as string) + mapKeyPostfix][0];
  const aLastItem = state_items.getItem(aLastItemId);

  const bLastItemId = itemIdsMap[(b.type as string) + mapKeyPostfix][0];
  const bLastItem = state_items.getItem(bLastItemId);

  // if I delete Items too fast, aLastItem or(?) bLastItem might be undefined
  // TODO solve the source of this
  if (aLastItem === undefined || bLastItem === undefined) {
    return 0;
  }

  if (aLastItem.createdAt === bLastItem.createdAt) {
    return 0;
  }

  return aLastItem.createdAt < bLastItem.createdAt ? 1 : -1;
}

function get() {
  const itemIdsMap = state_items.getItemIdsMap();
  const filterItem = state_filterItem.get();
  const mapKeyPostfix = filterItem.isHearted ? ".isHearted" : "";

  const sortedTypes = Object.values(allTypes)
    .filter((t) => {
      return itemIdsMap[t.type + mapKeyPostfix]?.length > 0;
    })
    .sort(compareLastAdded);

  return sortedTypes;
}

function useSubscribe() {
  state_items.useSubscribeItemIdsMap();
  state_filterItem.useSubscribe();
  return get();
}

export const superState_sortedTypes = {
  get,
  useSubscribe,
};
