import classNames from "classnames";
import React from "react";
import {SimpleAnimation} from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import {ANIMATIONS_DURATION} from "../../../config/constants";
import {lib_isMobileBrowser} from "../../../libs/functions/lib_isMobileBrowser";
import {state_isTyping} from "../../../state/state_isTyping";
import {state_openedItemId} from "../../../state/state_openedItemId";
import {RelativePosition} from "../../lib/RelativePosition/RelativePosition";
import HeartIndicator from "../HeartIndicator/HeartIndicator";
import ItemContent from "../ItemContent/ItemContent";
import ItemToolbar from "../ItemToolbar/ItemToolbar";
import "./Item.scss";
import {$ItemParams} from "./Item.types";

function onClickItem(itemId: string, isOpened: boolean) {
  if (!state_isTyping.get()) {
    state_openedItemId.set(isOpened ? undefined : itemId);
  }
}

function Item({
  itemId,
  isOpened = false,
  isToBeHidden = false,
  isInFocus,
  hasBeenUpdated = false,
  isClickable = true,
}: $ItemParams) {
  const isTyping = state_isTyping.useSubscribe();
  const doScrollIntoView = isOpened && !lib_isMobileBrowser();
  // const doScrollIntoView = false;
  // console.log({ doScrollIntoView });
  return (
    <RelativePosition
      className="Item_Position"
      zIndex={isOpened && isTyping ? 2 : 0}
      flex={isInFocus ? 1 : undefined} // TODO ugly hack. needed to stretch item to full width while in focus. investigate why
      doScrollIntoView={doScrollIntoView}
    >
      <SimpleAnimation
        className="Item_Animation"
        doShow={!isToBeHidden}
        // showAnimation="fadeIn"
        // showAnimationDelay={
        //   renderIdx ? (renderIdx * ANIMATIONS_DURATION) / 10 : 0
        // }
        showAnimationDuration={ANIMATIONS_DURATION}
        hideAnimation="zoomOut"
        hideAnimationDuration={ANIMATIONS_DURATION * 2}
        flex={1}
      >
        <div
          className={classNames("Item", {
            isOpened,
            hasBeenUpdated,
          })}
          onClick={() => {
            onClickItem(itemId, isOpened);
          }}
        >
          <HeartIndicator itemId={itemId} isClickable={isClickable} />
          <ItemContent
            isInFocus={isInFocus}
            isOpened={isOpened}
            itemId={itemId}
          />
          <ItemToolbar isInFocus={isInFocus} isOpened={isOpened} />
        </div>
      </SimpleAnimation>
    </RelativePosition>
  );
}

export default React.memo(Item);
