import { ANIMATIONS_DURATION } from "../../config/constants";
import { state_isItemFocused } from "../state_isItemFocused";
import { state_isTyping } from "../state_isTyping";
import { state_itemDraft } from "../state_itemDraft";
import { state_items } from "../state_items";
import { state_openedItemId } from "../state_openedItemId";

export function action_cancelOpenedItemSubmit() {
  state_isTyping.set(false);
  const openedItemId = state_openedItemId.get();
  if (!openedItemId) return;

  state_itemDraft.set(openedItemId, undefined);
  const item = state_items.getItem(openedItemId);

  if (item.isCreated) {
    state_isItemFocused.set(false);
    return;
  }

  setTimeout(async () => {
    await state_items.removeItem$(openedItemId);
    state_openedItemId.set(undefined);
    state_isItemFocused.set(false);
  }, ANIMATIONS_DURATION);
}
