import { state_albumImages } from "../state_albumImages";
import { state_isAddItemMenuOpened } from "../state_isAddItemMenuOpened";
import { state_isItemFocused } from "../state_isItemFocused";
import { state_isMainMenuOpened } from "../state_isMainMenuOpened";
import { state_openedItemId } from "../state_openedItemId";
import { action_cancelOpenedItemSubmit } from "./actions_cancelOpenedItemSubmit";

export function actions_goBack() {
  window.history.pushState({}, "");

  if (state_isItemFocused.get()) {
    action_cancelOpenedItemSubmit();
    return;
  }

  state_albumImages.set(undefined)
  state_openedItemId.set(undefined);

  state_isAddItemMenuOpened.set(false);
  state_isMainMenuOpened.set(false);
}
