import { state_filterItem } from "../state_filterItem";
import { state_items } from "../state_items";
import { actions_addItem } from "./actions_addItem";
import { actions_closeAddItemMenu } from "./actions_closeAddItemMenu";

export function actions_addLocationItem() {
  actions_closeAddItemMenu(() => {
    if ("geolocation" in navigator) {
      const insertId = actions_addItem({
        type: "location",
        rawInput: "LOCATION_AWAITING_GPS",
      });

      if (state_filterItem.getIsSet()) {
        state_filterItem.set({ type: "location", isHearted: false });
      }

      navigator.geolocation.getCurrentPosition((position) => {
        state_items.updateItem(insertId, {
          rawInput: `{
                    "lat": ${position.coords.latitude}, 
                    "lng": ${position.coords.longitude}
                }`,
        });
      });
    }
  });
}
