import { state_items } from "../../state/state_items";

function isShoppingType(itemId: string) {
  return state_items.getItem(itemId).type === "shopping";
}

export function processRawInput(itemId: string, rawInput: string) {
  if (!isShoppingType(itemId)) {
    return false;
  }

  state_items.updateItem(itemId, {
    text: rawInput,
    isProcessed: true,
  });

  return "shopping";
}
