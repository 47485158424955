import classNames from "classnames";
import React, { MouseEventHandler, ReactNode } from "react";
import "./View.scss";

export const View = ({
  style,
  className = "",
  children,
  onClick,
}: {
  style?: React.CSSProperties;
  className?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler;
}) => {
  const viewClassName = classNames({
    View: true,
    hover: !!onClick,
    [className]: true,
  });

  return (
    <div style={style} className={viewClassName} onClick={onClick}>
      {children}
    </div>
  );
};
