import { sounds_setIsHearted } from "../../sounds/sounds";
import { state_filterItem } from "../state_filterItem";
import { state_hasBeenUpdatedItemId } from "../state_hasBeenUpdatedItemId";
import { state_items } from "../state_items";
import { state_openedItemId } from "../state_openedItemId";

export function actions_setItemIsHearted(itemId: string, isHearted: boolean) {
  if (isHearted) {
    sounds_setIsHearted.play();
  }

  state_items.setItemIsHearted(itemId, isHearted);
  state_hasBeenUpdatedItemId.set(itemId);

  // special case: user has filtered by isHearted and is
  // now un-setting isHearted of the openedItem. Then,
  // the list will be redrawn without the openedItem as
  // it does not meet the filter anymore. So as the
  // openedItem is not visible anymore, we have to also
  // unset openedItemId
  if (itemId === state_openedItemId.get()) {
    if (state_filterItem.get().isHearted && !isHearted) {
      state_openedItemId.set(undefined);
    }
  }
}
