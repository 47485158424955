async function getWebsiteDataFetcher(url: string) {
  return fetch(
    `/.netlify/functions/functions_getWebsiteData?url=${encodeURIComponent(
      url
    )}`
  ).then((response) => {
    return response.json();
  });
}

export { getWebsiteDataFetcher };
