import { actions_addItem } from "./actions_addItem";
import { actions_closeAddItemMenu } from "./actions_closeAddItemMenu";

export function actions_addBase64Image(base64: string) {
  actions_closeAddItemMenu(() => {
    actions_addItem({
      rawInput: base64,
      type: "base64Image",
    });
  });
}
