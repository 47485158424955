import { dripdrop } from "dripdrop";

let isItemFocused: boolean = false;

function set(value: boolean) {
  if (isItemFocused === value) return;

  isItemFocused = value;
  dripdrop.notifySubscribers("isItemFocused");
}

function get() {
  return isItemFocused;
}

function useSubscribe() {
  dripdrop.useSubscribe("isItemFocused");
  return isItemFocused;
}

export const state_isItemFocused = {
  get,
  set,
  useSubscribe,
};
