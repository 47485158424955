import { faRotate } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/lib/Button/Button";
import "./ItemProcessing.scss";

const ItemProcessing = ({
  caption,
  doOpen,
}: {
  caption: string;
  doOpen?: React.MouseEventHandler;
}) => {
  return (
    <div className="ItemProcessing" onClick={doOpen}>
      <div className="Rotate">
        <Button icon={faRotate} iconColor="rgba(0,0,0,0.5)" />
      </div>
      <span style={{ color: "rgba(0,0,0,0.5)" }}>{caption}</span>
    </div>
  );
};

export default ItemProcessing;
