import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEventHandler, ReactNode } from "react";
import { SimpleAnimation } from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import { ANIMATIONS_DURATION } from "../../../config/constants";
import "./BasicItemIcon.scss";

function BasicItemIcon({
  icon,
  onClick,
}: {
  icon: IconDefinition | ReactNode;
  onClick: MouseEventHandler;
}) {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <SimpleAnimation
      className="BasicItemIcon_Animation"
      showAnimation="bounceIn"
      showAnimationDuration={2 * ANIMATIONS_DURATION}
    >
      <div
        className="BasicItemIcon isInteractivePluginComponent"
        onClick={onClick}
      >
        {(icon as IconDefinition).icon && (
          <FontAwesomeIcon icon={icon as IconDefinition} />
        )}

        {!(icon as IconDefinition).icon && <>{icon}</>}
      </div>
    </SimpleAnimation>
  );
}

export default React.memo(BasicItemIcon);
