import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { ANIMATIONS_DURATION } from "../../../config/constants";
import { state_isMainMenuOpened } from "../../../state/state_isMainMenuOpened";
import { state_settingsBackground } from "../../../state/state_settingsBackground";
import { RelativePosition } from "../../lib/RelativePosition/RelativePosition";
import { SimpleAnimation } from "../../lib/SimpleAnimation/SimpleAnimation";
import "./BackgroundSelectionChoice.scss";

function BackgroundSelectionChoice({
  isSet,
  renderIndex,
}: {
  isSet: boolean;
  renderIndex: number;
}) {
  const isMainMenuOpened = state_isMainMenuOpened.useSubscribe();

  return (
    <RelativePosition
      className="BackgroundSelectionChoice_Position"
      key={renderIndex}
      backgroundColor="white"
      scale={isSet ? 1 : 0.9}
      zIndex={isSet ? 1 : undefined}
    >
      <SimpleAnimation
        doShow={isMainMenuOpened}
        showAnimation="fadeIn"
        showAnimationDelay={
          ANIMATIONS_DURATION + (renderIndex * ANIMATIONS_DURATION) / 10
        }
        showAnimationDuration={2 * ANIMATIONS_DURATION}
      >
        <RelativePosition
          width={100}
          height={100}
          className={classNames("BackgroundSelectionChoice", {
            ["backgroundPattern" + renderIndex]: true,
            isSet: isSet,
          })}
          onClick={() => {
            state_settingsBackground.set(renderIndex);
            setTimeout(
              () => state_isMainMenuOpened.set(false),
              ANIMATIONS_DURATION * 2
            );
          }}
        >
          {isSet && (
            <SimpleAnimation showAnimation="zoomIn">
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="yellow"
                fontSize={40}
              />
            </SimpleAnimation>
          )}
        </RelativePosition>
      </SimpleAnimation>
    </RelativePosition>
  );
}

export default React.memo(BackgroundSelectionChoice);
