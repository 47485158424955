import {$PositionProps, Position} from "../Position/Position";

export const RelativePosition = (props: $PositionProps) => {
  console.log("RelativePosition", props);
  return (
    <Position
      {...props}
      className={"RelativePosition " + (props.className || "")}
      type="relative"
    >
      {props.children}
    </Position>
  );
};
