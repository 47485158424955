import React from "react";
import {lib_DateSwitcher} from "../../../libs/libs_date";
import {state_items} from "../../../state/state_items";
import DateSeparator from "../DateSeparator/DateSeparator";
import Item from "../Item/Item";

function ItemsListItems({
  itemIds,
  openedItemId,
  toBeHiddenItemId,
  hasBeenUpdatedItemId,
}: {
  itemIds: string[];
  openedItemId: string | undefined;
  toBeHiddenItemId: string | undefined;
  hasBeenUpdatedItemId: string | undefined;
}) {
  const dateSwitcher = new lib_DateSwitcher();
  // console.count("AllItems");

  return (
    <>
      {itemIds?.map((itemId, idx) => {
        const item = state_items.getItem(itemId);
        if (!item) return null;
        const createdAt = item.createdAt;
        const showSeparator = dateSwitcher.hasDateDaySwitched(createdAt);
        return (
          <React.Fragment key={itemId}>
            {createdAt && showSeparator && (
              <DateSeparator createdAt={createdAt} />
            )}

            <Item
              itemId={itemId}
              isOpened={openedItemId === itemId}
              isToBeHidden={toBeHiddenItemId === itemId}
              hasBeenUpdated={hasBeenUpdatedItemId === itemId}
              renderIdx={idx}
              isClickable={!openedItemId || openedItemId === itemId}
            />
          </React.Fragment>
        );
      })}
    </>
  );
}

export default React.memo(ItemsListItems);
