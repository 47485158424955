// !! keep this file dependency free !!
// !! keep this file dependency free !!
// !! keep this file dependency free !!

// only put those constants here, that are used across the code base, in different components or actions.
// exception: constants you need to change very often and where you want to have a central place to do so.
// instead of searching the code within the right folder

// keep in sync with CSS vars

export const VERTICAL_MARGIN = 70;

export const MAX_APP_WIDTH = 600;

export const MAX_ITEM_CONTENT_WIDTH = 350;
export const MAX_ITEM_CONTENT_HEIGHT = 250;

export const ANIMATIONS_ENTRY_ANIMATION = "fadeIn";
export const ANIMATIONS_DURATION = 250; // the speed at which the players move inside the bubble

export const DEV_MODE = false;
export const TIMEOUT_PROCESSING = 2500;

export const SERVER_DIFF_INTERVAL =
  process.env.NODE_ENV === "development" ? 10 * 1000 : 10 * 60 * 1000; // interval to request an item sync from server
