import {faAnglesDown} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useGyroTranslations} from "../../../hooks/useGyroTranslations";
import {state_isListScrolledToBottom} from "../../../state/state_isListScrolledToBottom";
import {state_itemsListHandle} from "../../../state/state_itemListHandle";
import {state_openedItemId} from "../../../state/state_openedItemId";
import Button from "../../lib/Button/Button";
import {Position} from "../../lib/Position/Position";
import {SimpleAnimation} from "../../lib/SimpleAnimation/SimpleAnimation";
import "./ScrollDownButton.scss";

function ScrollDownButton() {
  const itemsListHandle = state_itemsListHandle.useSubscribe();
  const isListScrolledToBottom = state_isListScrolledToBottom.useSubscribe();
  const isItemOpened = state_openedItemId.useSubscribeIsItemOpened();
  const {gyroX, gyroY} = useGyroTranslations(3, 3);

  const doShow = !isListScrolledToBottom && !isItemOpened;

  // stop
  if (!(itemsListHandle instanceof HTMLDivElement)) {
    return null;
  }

  const scrollToBottom = () => {
    if (itemsListHandle) {
      // // Wait for DOM to stabilize if needed
      // setTimeout(() => {
      itemsListHandle.scrollTo({top: 0});
      // }, 50); // Adjust timing if necessary
    }
  };

  return (
    <Position
      className="ScrollDownButton_Position"
      right={10}
      top={10}
      pointerEvents={doShow ? "auto" : "none"}
      translateX={gyroX}
      translateY={gyroY}
    >
      <SimpleAnimation
        className="ScrollDownButton_Animation"
        doShow={doShow}
        showAnimation="zoomIn"
        hideAnimation="zoomOut"
        doHideOnFirstRender={true}
      >
        <div className="ScrollDownButton">
          <Button
            buttonSize={40}
            icon={faAnglesDown}
            onClick={scrollToBottom}
            backgroundColor="white"
          />
        </div>
      </SimpleAnimation>
    </Position>
  );
}

export default React.memo(ScrollDownButton);
