import { state_items } from "../../state/state_items";
import { getWebsiteDataFetcher } from "./getWebsiteDataFetcher";
import { $WebsiteData } from "./types";

export function sendUrlToApiAndGetDataForWebsite(
  newItemId: string,
  url: string
) {
  getWebsiteDataFetcher(url)
    .then((websiteData: $WebsiteData & { error: any }) => {
      if (websiteData.error) {
        throw Error("Unknown Error. Maybe no internet connection");
      }

      state_items.updateItem(newItemId, {
        isProcessed: true,
        url,
        logo: websiteData.logo?.url,
        text: websiteData.title,
        description: websiteData.description,
        publisher: websiteData.publisher,
        imageUrl: websiteData.image?.url,
        imageWidth: websiteData.image?.width,
        imageHeight: websiteData.image?.height,
      });
    })
    .catch((error: any) => {
      console.error(error);

      state_items.updateItem(newItemId, {
        isProcessed: true,
        isProcessedFailure: url,
        url,
      });
    });
}
