import { dripdrop } from "dripdrop";
import { PUSH_NOTIFICATIONS_VISIBILITY_DURATION } from "./pushNotifications.constants";

const timeouts: { [key: string]: number } = {};
export type $NOTE = { caption: string; sizeFactor: number; isSticky: boolean };

const pushNotifications: {
  [key: string]: $NOTE;
} = {};

export function actionPushNotification(
  caption: string,
  sizeFactor: number = 1,
  isSticky: boolean = false
) {
  console.log("actionPushNotification", caption);

  const mapIndex = caption;
  if (timeouts[mapIndex]) {
    clearTimeout(timeouts[mapIndex]);
  }

  pushNotifications[mapIndex] = { caption, sizeFactor, isSticky: isSticky };
  dripdrop.notifySubscribers("pushNotifications");
  if (!isSticky) {
    timeouts[mapIndex] = window.setTimeout(() => {
      delete pushNotifications[mapIndex];
      dripdrop.notifySubscribers("pushNotifications");
    }, PUSH_NOTIFICATIONS_VISIBILITY_DURATION);
  }
}

export function actionClosePushNotification(caption: string) {
  const mapIndex = caption;
  if (timeouts[mapIndex]) {
    clearTimeout(timeouts[mapIndex]);
  }

  delete pushNotifications[mapIndex];
  dripdrop.notifySubscribers("pushNotifications");
}

export function useSubscribePushNotifications() {
  dripdrop.useSubscribe("pushNotifications");
  return pushNotifications;
}
