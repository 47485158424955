import { actions_addItem } from "../state/actions/actions_addItem";

export async function populateModelAsIndexedDbIsEmpty() {
  console.log("load:populateModelAsIndexedDbIsEmpty");
  // actions_addItem({
  //   rawInput: "https://www.youtube.com/watch?v=6-L4vHmt1Dc",
  // });

  actions_addItem({
    rawInput: "Drop a YouTube video 👆",
    type: "text",
  });

  actions_addItem({
    rawInput: "Or a Twitter thread 👇",
    type: "text",
  });

  actions_addItem({
    rawInput: "https://twitter.com/tobiasrogers/status/1564232659059937280",
  });

  actions_addItem({
    rawInput: "An Image 👇",
    type: "text",
  });

  actions_addItem({
    rawInput:
      "https://www.wdrmaus.de/bilder/mediendb/maus_2015/extras/maus_international/dari/dari_teaser.jpg",
  });

  actions_addItem({
    rawInput: "Lisd has a plugin system for integrations!",
    type: "text",
  });

  actions_addItem({
    isDone: true,
    rawInput: "Todo 1",
    type: "todo",
  });

  actions_addItem({
    isDone: false,
    rawInput: "Todo 2",
    type: "todo",
  });

  actions_addItem({
    isDone: false,
    rawInput: "Todo 3",
    type: "todo",
  });

  actions_addItem({
    rawInput: "Bread",
    type: "shopping",
  });

  actions_addItem({
    rawInput: "Apples",
    type: "shopping",
  });

  actions_addItem({
    rawInput: "Grapes",
    type: "shopping",
  });

  actions_addItem({
    rawInput: "https://github.com/ayalpani/dripdrop",
  });

  actions_addItem({
    rawInput: "Hi. I'm a simple Text!",
    type: "text",
  });

  actions_addItem({
    rawInput: "Click bars to change 👉",
    type: "text",
  });

  actions_addItem({
    rawInput: "Add things below 👇",
    type: "text",
  });

  // for (let i = 0; i < 10000; i++) {
  //   actions_addItem({
  //     color: "green",
  //     rawInput: "Todo " + i,
  //     type: "todo",
  //   });
  // }
}
