import React, { useEffect, useState } from "react";
import { Position } from "../Position/Position";
import "./BottomSheetCurtain.scss";

function BottomSheetCurtain({
  onClick,
  doHide,
}: {
  onClick?: () => void;
  doHide: boolean;
}) {
  const [opacity, setOpacity] = useState(0); // on mount start with no opacity

  useEffect(() => {
    setOpacity(doHide ? 0 : 0.5);
  }, [doHide]);

  return (
    <Position
      inset={0}
      backgroundColor="black"
      type="absolute"
      onClick={onClick}
      opacity={opacity} // animated
      pointerEvents={doHide ? "none" : "auto"}
      className="BottomSheetCurtain"
    />
  );
}

export default React.memo(BottomSheetCurtain);
