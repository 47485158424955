import {useEffect, useRef} from "react";
import {ANIMATIONS_DURATION} from "../../../config/constants";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import {
  ITEMLIST_PAGESIZE,
  state_numLoadedItems,
} from "../../../state/state_numLoadedItems";
import {superState_itemIds} from "../../../superState/superState_itemIds";
import {RelativePosition} from "../../lib/RelativePosition/RelativePosition";
import {SimpleAnimation} from "../../lib/SimpleAnimation/SimpleAnimation";
import "./ListTopMarker.scss";
import React from "react";

function ListTopMarker() {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const itemIds = superState_itemIds.useSubscribe();
  const numLoadedItems = state_numLoadedItems.useSubscribe();

  // we need the total item count
  const numAllItems = itemIds.length;

  // how many have already been "loaded". loaded is only meant virtually
  // as numLoadedItems is just a limiter for splicing the grand array
  // of all items
  const existsMoreItems = numAllItems > numLoadedItems;

  // TODO 1: investigate why this marker is rendered so many times
  // TODO 2: investigate why it sometimes does not get triggered (happens when using keyboard only)
  // console.count("<ListTopMarker>");
  // console.log({
  //   numAllItems,
  //   existsMoreItems,
  //   isVisible,
  //   numLoadedItems,
  // });

  useEffect(() => {
    if (numAllItems && isVisible && existsMoreItems) {
      setTimeout(() => {
        state_numLoadedItems.set(numLoadedItems + ITEMLIST_PAGESIZE);
      }, ANIMATIONS_DURATION);
    }
  }, [numAllItems, existsMoreItems, isVisible, numLoadedItems]);

  return (
    <RelativePosition className="ListTopMarker_Position" opacity={0.5}>
      <SimpleAnimation
        className="ListTopMarker_Animation"
        flex={1}
        doShow={isVisible}
        showAnimation="fadeIn"
        hideAnimation="fadeOut"
      >
        <div className="ListTopMarker" ref={ref}>
          {existsMoreItems && <div>Loading...</div>}
          {!existsMoreItems && <div>List Top</div>}
        </div>
      </SimpleAnimation>
    </RelativePosition>
  );
}

export default React.memo(ListTopMarker);
