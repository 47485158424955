import classNames from "classnames";
import React, { FormEvent, useCallback, useEffect, useRef } from "react";
import { SimpleAnimation } from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import { ANIMATIONS_DURATION } from "../../../config/constants";

import { action_submitOpenedItem } from "../../../state/actions/actions_submitOpenedItem";
import { state_focusedItemHasText } from "../../../state/state_focusedItemHasText";
import { state_isItemFocused } from "../../../state/state_isItemFocused";
import { state_isTyping } from "../../../state/state_isTyping";
import { state_itemDraft } from "../../../state/state_itemDraft";
import { state_openedItemId } from "../../../state/state_openedItemId";
import "./BasicItemText.scss";

function BasicItemText({
  itemId,
  text,
  isInFocus = false,
}: {
  itemId: string;
  text: string;
  isInFocus?: boolean;
}) {
  const inputRef = useRef<HTMLDivElement>(null);

  const isTyping = state_isTyping.useSubscribe();
  const isEditable =
    state_openedItemId.useSubscribeIsEqualToOpenedItemId(itemId);

  const onClick = useCallback(() => {
    state_openedItemId.set(itemId);
  }, [itemId]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isInFocus) return;

    if (e.code === "Enter") {
      e.preventDefault();
      if (state_focusedItemHasText.get()) {
        action_submitOpenedItem();
      }
    }
  };

  const onInput = (e: FormEvent) => {
    if (!isInFocus) return;

    const target = e.target as HTMLDivElement;
    const value = target.innerText;
    const openedItemId = state_openedItemId.get();
    if (openedItemId !== undefined && isInFocus) {
      state_focusedItemHasText.set(value.trim() !== "");
      state_itemDraft.set(itemId, { text: value });
    }
  };

  const onFocus = () => {
    if (isInFocus) return;

    state_isItemFocused.set(true);

    if (inputRef.current) {
      state_isTyping.set(true);

      if (text === undefined || text === "") {
        inputRef.current.innerText = "";
        state_focusedItemHasText.set(false);
      } else {
        state_focusedItemHasText.set(true);
      }
    }
  };

  const onBlur = (e: FormEvent) => {
    if (!isInFocus) return;

    const target = e.target as HTMLDivElement;
    const value = target.innerText;

    state_focusedItemHasText.set(false);
    if (inputRef.current) {
      if (isTyping) {
        state_focusedItemHasText.set(value.trim() !== "");
        inputRef.current?.focus();
      }
    }
  };

  useEffect(() => {
    if (!isInFocus) return;

    if (!isTyping) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();

      const el = inputRef.current;
      if (el) {
        const selection = window.getSelection();
        if (selection) {
          const range = document.createRange();
          selection.removeAllRanges();
          range.selectNodeContents(el);
          range.collapse(false);
          selection.addRange(range);
          el.focus();
        }
      }
    }
  }, [isInFocus, isTyping]);

  return (
    <SimpleAnimation
      className="BasicItemText_Animation"
      showAnimation="fadeIn"
      showAnimationDelay={ANIMATIONS_DURATION / 2}
      showAnimationDuration={ANIMATIONS_DURATION}
      flex={1}
    >
      <div
        ref={inputRef}
        className={classNames("BasicItemText", {
          isEditable,
          isEmpty: text === undefined || text === "",
        })}
        data-placeholder="Type something..."
        contentEditable={true}
        onKeyDown={onKeyDown}
        onInput={onInput}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </SimpleAnimation>
  );
}

export default React.memo(BasicItemText);
