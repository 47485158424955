import { lib_encodeHtmlEntities } from "../../libs/functions/lib_encodeHtmlEntities";
import { actions_addItem } from "./actions_addItem";
import { actions_closeAddItemMenu } from "./actions_closeAddItemMenu";

export function actions_addClipboardText() {
  actions_closeAddItemMenu(() => {
    navigator.clipboard.readText().then((clipText) => {
      actions_addItem({
        rawInput: lib_encodeHtmlEntities(clipText),
      });
    });
  });
}
