import { dexieDb } from "../../dexieDb/dexieDb";
import { $Item } from "../../plugins/types";
import { state_isItemsDataLoaded } from "../../state/state_isItemDataLoaded";
import { state_items } from "../../state/state_items";

export async function loadFromIndexedDb() {
  console.log("load:loadFromIndexedDb");

  let itemsFromIndexedDb = await dexieDb.items.toArray();
  //
  // first
  const numItems = itemsFromIndexedDb.length;
  if (numItems > 0) {
    console.log(
      `load::loadFromIndexedDb: Copying ${numItems} Items from IndexedDb to Memory`
    );

    // save to memory and recalc the caches
    await state_items.setItemsBulk(itemsFromIndexedDb as $Item[]);
  }

  // signal readiness of data
  state_isItemsDataLoaded.set(true);
}
