import { dripdrop } from "dripdrop";

let isMainMenuOpened: boolean = false;

function set(value: boolean) {
  if (isMainMenuOpened === value) return;

  isMainMenuOpened = value;
  dripdrop.notifySubscribers("isMainMenuOpened");
}

function get() {
  return isMainMenuOpened;
}

function useSubscribe() {
  dripdrop.useSubscribe("isMainMenuOpened");
  return isMainMenuOpened;
}

export const state_isMainMenuOpened = {
  get,
  set,
  useSubscribe,
};
