import LogoLetter from "../LogoLetter/LogoLetter";
import "./LogoWord.scss";

export function LogoWord({ onlyShowFirst }: { onlyShowFirst?: boolean }) {
  return (
    <div className="LogoWord">
      <LogoLetter color="blue" letter="L" index={0} />
      {!onlyShowFirst && (
        <>
          <LogoLetter color="green" letter="i" index={1} />
          <LogoLetter color="darkorange" letter="s" index={2} />
          <LogoLetter color="red" letter="d" index={3} />
        </>
      )}
    </div>
  );
}

export default LogoWord;
