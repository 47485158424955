import React, { useEffect, useRef } from "react";
import BottomSheet from "../BottomSheet/BottomSheet";
import Button from "../Button/Button";
import ButtonBar from "../ButtonBar/ButtonBar";
import "./PromptBottomSheet.scss";
import { actionSetPrompt, useSubscribePrompt } from "./PromptBottomSheet.state";

function PromptBottomSheet() {
  const inputRef = useRef<HTMLInputElement>(null);
  const prompt = useSubscribePrompt();
  // console.log("🖌️ render <PromptBottomSheet>", prompt);

  const doHide = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    actionSetPrompt(undefined);
  };

  useEffect(() => {
    let timeout: number | undefined = undefined;

    if (prompt !== undefined) {
      timeout = window.setTimeout(() => {
        if (inputRef.current !== null) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(-1, -1);
        }
      }, 500);
    }

    return () => window.clearTimeout(timeout);
  }, [prompt]);

  const onSubmit = () => {
    if (prompt !== undefined && inputRef.current) {
      prompt.successHandler(inputRef.current.value);
      inputRef.current.value = "";

      if (!prompt.keepOpenOnSubmit) {
        doHide();
      } else {
        inputRef.current.focus();
      }
    }
  };

  const onCancel = () => {
    if (prompt?.cancelHandler) {
      if (inputRef.current) {
        prompt.cancelHandler(inputRef.current.value);
      } else {
        prompt.cancelHandler();
      }
    }
    doHide();
  };

  return (
    <BottomSheet
      key={Math.random().toString()}
      doShow={prompt !== undefined}
      doShowCurtain={true}
      doHideCallback={doHide}
      isSwipeable={true}
      backgroundColor="pink"
      Footer={
        <ButtonBar backgroundColor="transparent">
          <Button
            caption={prompt?.sendButtonCaption || "Send"}
            captionColor={prompt?.sendButtonCaptionColor}
            backgroundColor={prompt?.sendButtonBackgroundColor}
            onClick={onSubmit}
          />
          <Button
            caption={prompt?.cancelButtonCaption || "Cancel"}
            onClick={onCancel}
            type="secondary"
          />
        </ButtonBar>
      }
    >
      <div className="PromptBottomSheet">
        {prompt?.caption && (
          <div className="PromptCaption">{prompt.caption}</div>
        )}

        <input
          ref={inputRef}
          type="text"
          inputMode="search"
          placeholder={prompt?.placeholder}
          defaultValue={prompt?.defaultValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
        />
      </div>
    </BottomSheet>
  );
}

export default React.memo(PromptBottomSheet);
