import { faSadCry } from "@fortawesome/free-regular-svg-icons";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { actions_reloadApp } from "../../../state/actions/actions_reloadApp";
import Button from "../../lib/Button/Button";
import { Position } from "../../lib/Position/Position";
import "./ErrorBoundary.scss";

function ErrorPage({ error }: { error: Error }) {
  return (
    <Position
      inset={0}
      backgroundColor="rgba(255,0,0,0.125)"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={20}
    >
      <FontAwesomeIcon icon={faSadCry} color="red" size="10x" />

      <span className="ErrorText">{error.message}</span>

      <Button
        icon={faRotate}
        iconScale={1.5}
        caption="Reload App"
        onClick={actions_reloadApp}
        backgroundColor="white"
      />
    </Position>
  );
}

export { ErrorPage };
