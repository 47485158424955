import { dripdrop } from "dripdrop";

let appMetaTheme: string = "#000";

function set(value: string) {
  appMetaTheme = value;
  dripdrop.notifySubscribers("appMetaTheme");
}

function useSubscribe() {
  dripdrop.useSubscribe("appMetaTheme");
  return appMetaTheme;
}

export const state_appMetaTheme = {
  set,
  useSubscribe,
};
