import { state_items } from "../../state/state_items";
import { MAPTILER_KEY, MAP_DEFAULT_ZOOM_LEVEL } from "./constants";
import {
  $LatLng,
  $LocApiResults,
  $LocationItem,
  $LocInfos,
  $MapData,
} from "./types";

function parseLatLngStr(latLngStr: string): $LatLng {
  return JSON.parse(latLngStr) as $LatLng;
}

function isValidLatLng(rawInput?: string): boolean | $LatLng {
  if (rawInput === undefined) {
    return false;
  }

  try {
    const latLngCandidate = JSON.parse(rawInput);
    if ("lat" in latLngCandidate && "lng" in latLngCandidate) {
      return latLngCandidate;
    }
    return false;
  } catch (e) {
    return false;
  }
}

//console.log(isValidLatLng('{"lat":13,"lng":53}'));

//export function retryLocationItemProcessing(item: $Item, zoomLevel?: number) {
export function restartItemProcessing(itemId: string) {
  // state_items.startOrRestartItemProcessing(itemId);

  const locationItem = state_items.getItem(itemId) as $LocationItem;
  sendUrlToApiAndGetStaticMapForLocation(
    itemId,
    locationItem.latLng,
    locationItem.mapData?.zoomLevel
  );
}

export function processRawInput(itemId: string, rawInput: string) {
  const isValid = isValidLatLng(rawInput);
  if (!isValid) return false;

  const latLng = parseLatLngStr(rawInput);

  state_items.updateItem(itemId, {
    type: "location",
    rawInput,
    latLng,
  });

  state_items.startOrRestartItemProcessing(itemId);

  sendUrlToApiAndGetStaticMapForLocation(itemId, latLng);
  asyncQueryMapTilerForLocInfos(latLng, (locInfos) => {
    state_items.updateItem(itemId, {
      text: locInfos.street || locInfos.state || locInfos.country,
      infos: locInfos,
    });
  });

  return "location";
}

function sendUrlToApiAndGetStaticMapForLocation(
  newItemId: string,
  latLng: $LatLng,
  zoomLevel: number = MAP_DEFAULT_ZOOM_LEVEL
) {
  // console.log(
  //   "sendUrlToApiAndGetStaticMapForLocation",
  //   newItemId,
  //   latLng,
  //   zoomLevel
  // );
  fetch(
    `/.netlify/functions/functions_getStaticMapForLatLng?lat=${latLng.lat}&lng=${latLng.lng}&zoomLevel=${zoomLevel}`
  )
    .then((response) => {
      //actionPushNotification("actionPushNotification 1");
      return response.json();
    })
    .then((mapData: $MapData) => {
      console.log({ mapData });
      state_items.updateItem(newItemId, {
        isProcessed: true,
        mapData,
      });
    })
    .catch((error: any) => {
      state_items.updateItem(newItemId, {
        isProcessed: true,
        isProcessedFailure: "Error processing location",
      });
    });
}

export function asyncQueryMapTilerForLocInfos(
  latLng: $LatLng,
  callback: (locInfos: $LocInfos) => void
) {
  // console.log(`Querying MapTile for lat/lng ${latLng.lat}${latLng.lng}`);
  fetch(
    `https://api.maptiler.com/geocoding/${latLng.lng},${latLng.lat}.json?key=${MAPTILER_KEY}`
  )
    .then((response) => response.json())
    .then((data: $LocApiResults) => {
      const locInfos: $LocInfos = {
        street: undefined,
        state: undefined,
        country: undefined,
      };

      data.features.forEach((locationFeature) => {
        if (locationFeature.place_type.includes("street")) {
          locInfos.street = locationFeature.text;
        }

        if (locationFeature.place_type.includes("state")) {
          locInfos.state = locationFeature.text;
        }

        if (locationFeature.place_type.includes("country")) {
          locInfos.country = locationFeature.text;
        }
      });

      callback(locInfos);
    });
}
