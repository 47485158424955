import { dripdrop } from "dripdrop";

let isScrolling: boolean = false;
let isScrollingInterval = 0;

function set(value: boolean) {
  if (isScrollingInterval) window.clearInterval(isScrollingInterval);
  if (value) {
    isScrollingInterval = window.setInterval(() => {
      set(false);
    }, 1000);
  }

  if (isScrolling === value) return;

  isScrolling = value;
  dripdrop.notifySubscribers("isScrolling");
}

function get() {
  return isScrolling;
}

function useSubscribe() {
  dripdrop.useSubscribe("isScrolling");
  return get();
}

export const state_isScrolling = {
  set,
  get,
  useSubscribe,
};
