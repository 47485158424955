import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getSpecForType} from "../../../plugins/types";
import {state_filterItem} from "../../../state/state_filterItem";
import {state_items} from "../../../state/state_items";
import {state_filterRecording} from "../../../state/state_filterRecording";
import {superState_itemIds} from "../../../superState/superState_itemIds";

export function FilterStatsContent() {
  const {isProcessing, filterSummary} = state_filterRecording.useSubscribe();
  const allFilteredItemIds = superState_itemIds.useSubscribe();
  const isFilterSet = state_filterItem.useSubscribeIsSet();

  const typeSpec = getSpecForType(state_filterItem.getType());
  const color = typeSpec?.color || "black";
  const numAllItems = state_items.getNumAllItems();

  if (isProcessing) {
    return (
      <div className="FilterStatsLoader">
        <div className="Loader" />
        <span>Processing voice...</span>
      </div>
    );
  }

  if (filterSummary) {
    return <div className="FilterStatsSummary">{filterSummary}</div>;
  }

  return (
    <>
      <div className="FilterStatsCounter">
        {numAllItems > 0 && (
          <span style={{color}}>
            <strong>{allFilteredItemIds.length}</strong>{" "}
            {allFilteredItemIds.length === 1
              ? typeSpec.caption
              : typeSpec.captionPlural}
          </span>
        )}
      </div>

      {isFilterSet && (
        <div className="FilterStatsShowAll">
          <span>{numAllItems} total</span>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
    </>
  );
}
