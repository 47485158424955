import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { RelativePosition } from "../RelativePosition/RelativePosition";
import { View } from "../View/View";
import "./ToggleControl.scss";

function ToggleControl({
  value = false,
  title = "",
  captionTrue = "",
  captionFalse = "",
  iconTrue,
  iconFalse,
  onClick,
}: {
  value: boolean;
  title?: string;
  captionTrue?: string;
  captionFalse?: string;
  iconTrue?: IconProp;
  iconFalse?: IconProp;
  onClick: (newValue: boolean) => void;
}) {
  // console.log("🖌️ render <ToggleControl>");
  return (
    <RelativePosition
      className={classNames("ToggleControl", { isTrue: value })}
      flexDirection="column"
    >
      {title !== "" && (
        <RelativePosition className="ToggleControlTitle">
          {title}
        </RelativePosition>
      )}
      <RelativePosition className="ToggleControlKnobs" flexDirection="column">
        <RelativePosition
          className={classNames("ToggleControlKnob", { isSet: !value })}
          onClick={() => onClick(false)}
        >
          <RelativePosition
            className="ToggleControlIcon"
            width={30}
            height={30}
            alignItems="center"
            justifyContent="center"
          >
            {iconFalse !== undefined && <FontAwesomeIcon icon={iconFalse} />}
          </RelativePosition>
          <View className="ToggleControlCaption">{captionFalse}</View>
        </RelativePosition>

        <RelativePosition
          className={classNames("ToggleControlKnob", { isSet: value })}
          onClick={() => onClick(true)}
        >
          <RelativePosition
            className="ToggleControlIcon"
            width={30}
            height={30}
            alignItems="center"
            justifyContent="center"
          >
            {iconTrue !== undefined && <FontAwesomeIcon icon={iconTrue} />}
          </RelativePosition>
          <View className="ToggleControlCaption">{captionTrue}</View>
        </RelativePosition>
      </RelativePosition>
    </RelativePosition>
  );
}

export default React.memo(ToggleControl);
