import { state_items } from "../../state/state_items";

function isImageItem(url: string) {
  return url.match(/\.(jpeg|jpg|gif|png|webp)$/) !== null;
}

export function processRawInput(itemId: string, rawInput: string) {
  const isImage = isImageItem(rawInput);
  if (!isImage) return false;

  state_items.updateItem(itemId, {
    type: "image",
    isProcessed: true,
    imageSrc: rawInput,
  });

  return "image";
}
