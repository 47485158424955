import { dripdrop } from "dripdrop";

let isListScrolledToBottom: boolean = true;

function set(value: boolean) {
  if (isListScrolledToBottom === value) return;

  isListScrolledToBottom = value;
  dripdrop.notifySubscribers("isListScrolledToBottom");
}

function useSubscribe() {
  dripdrop.useSubscribe("isListScrolledToBottom");
  return isListScrolledToBottom;
}

export const state_isListScrolledToBottom = {
  set,
  useSubscribe,
};
