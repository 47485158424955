import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React from "react";

import classNames from "classnames";
import {useGyroTranslations} from "../../../hooks/useGyroTranslations";
import {state_isMainMenuOpened} from "../../../state/state_isMainMenuOpened";
import {state_openedItemId} from "../../../state/state_openedItemId";
import Button from "../../lib/Button/Button";
import {$PositionProps, Position} from "../../lib/Position/Position";
import {RelativePosition} from "../../lib/RelativePosition/RelativePosition";
import {
  $NamedAnimations,
  $SimpleAnimationProps,
  SimpleAnimation,
} from "../../lib/SimpleAnimation/SimpleAnimation";
import LogoWord from "../LogoWord/LogoWord";
import {MAIN_MENU_BUTTON_BORDERCOLOR} from "../MainMenu/MainMenu";
import "./MainMenuButton.scss";

type $MondrianRenderConfig = {
  Position: $PositionProps;
  SimpleAnimation: $SimpleAnimationProps;
};

function MainMenuButton() {
  const isMainMenuOpened = state_isMainMenuOpened.useSubscribe();
  const openedItemId = state_openedItemId.useSubscribe();
  const doShow = openedItemId === undefined;
  const {gyroX, gyroY} = useGyroTranslations(3, 3);

  const onClick = () => {
    if (doShow) {
      state_isMainMenuOpened.set(!isMainMenuOpened);
    }
  };

  const mondrianConfig: $MondrianRenderConfig = {
    Position: {
      className: "MainMenuButton_Position",
      left: 10,
      top: 10,
      justifyContent: "center",
      // zIndex: ZLAYERS_MAIN_MENU_BUTTON,
      onClick,
      pointerEvents: "none",
      translateX: gyroX,
      translateY: gyroY,
    },

    SimpleAnimation: {
      doShow: doShow,
      className: "MainMenuButton_Animation",
      showAnimation: "zoomIn" as $NamedAnimations,
      hideAnimation: "zoomOut" as $NamedAnimations,
    },
  };

  if (!doShow) {
    return null;
  }

  return (
    <Position {...mondrianConfig.Position}>
      <SimpleAnimation {...mondrianConfig.SimpleAnimation}>
        <RelativePosition
          rotate={(isMainMenuOpened ? 10 : 0) + "px"}
          scale={isMainMenuOpened ? 0.75 : 1}
        >
          <div className={classNames("MainMenuButton", {isMainMenuOpened})}>
            {!isMainMenuOpened && <LogoWord onlyShowFirst={true} />}
            {isMainMenuOpened && (
              <Button
                icon={faTimes}
                iconScale={2}
                iconRotate={80}
                iconColor={MAIN_MENU_BUTTON_BORDERCOLOR}
              />
            )}
          </div>
        </RelativePosition>
      </SimpleAnimation>
    </Position>
  );
}

export default React.memo(MainMenuButton);
