import { state_items } from "../../state/state_items";

export function processRawInput(itemId: string, rawInput: string) {
  const item = state_items.getItem(itemId);

  if (item.type === undefined || item.type === "text") {
    state_items.updateItem(itemId, {
      type: "text",
      text: rawInput,
      isProcessed: true,
    });

    return "text";
  }

  return false;
}
