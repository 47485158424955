import { $Item } from "../types";
import { $TodoItem } from "./types";

export default function userShare(item: $Item): Promise<ShareData> {
  return new Promise((resolve) => {
    const _item = item as $TodoItem;
    resolve({
      text: "✅ " + _item.text,
    });
  });
}
