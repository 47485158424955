// https://stackoverflow.com/a/69769645
/* eslint-disable jsx-a11y/anchor-has-content */
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/lib/Button/Button";
import { Position } from "../../../components/lib/Position/Position";
import { SimpleAnimation } from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import { ANIMATIONS_DURATION } from "../../../config/constants";
import { lib_styleSheets } from "../../../libs/functions/lib_stylesheets";
import { $Item } from "../../types";
import { $TweetItem } from "../types";
import "./TweetItem.scss";

type $TWITTER_LOADING_STATE =
  | "isLoading"
  | "hasLoadedWithError"
  | "hasLoadedSuccessfully";

function getTweetUrl(tweetId: string) {
  return `https://twitter.com/x/status/` + tweetId;
}

function TweetItem({ item }: { item: $Item }) {
  const ref = useRef<HTMLQuoteElement>(null);
  const tweetItem = item as $TweetItem;

  const [tweetLoadingState, setTweetLoadingState] =
    useState<$TWITTER_LOADING_STATE>("isLoading");

  useEffect(() => {
    //@ts-ignore
    //@ts-ignore
    if (window.twttr !== undefined) {
      if (tweetLoadingState === "isLoading") {
        //@ts-ignore
        window.twttr.events.bind("loaded", function (event) {
          if (event.widgets.length > 0) {
            setTweetLoadingState("hasLoadedSuccessfully");
          }
        });

        const timeout = window.setTimeout(() => {
          //@ts-ignore
          window.twttr.widgets.load(ref.current);
        }, ANIMATIONS_DURATION * 2);

        return () => window.clearTimeout(timeout);
      }
    }
  }, [tweetLoadingState]);

  if (item === undefined) return null;

  //console.log("<TweetItem>", item.id);

  const styles = lib_styleSheets.create({
    TweetItem: {},
  });

  return (
    <div className="TweetItem" style={styles.TweetItem}>
      {tweetLoadingState === "isLoading" && (
        <div className="LoadingTweet">
          <Button
            icon={faTwitter}
            iconColor="#1DA1F2"
            iconScale={3}
            buttonSize={100}
            onClick={() =>
              window.open(getTweetUrl(tweetItem.tweetId), "_blank")
            }
          />
          <span>Loading...</span>
        </div>
      )}

      {tweetLoadingState === "hasLoadedWithError" && (
        <div className="LoadingTweet hasLoadedWithError">
          <Button
            icon={faTwitter}
            iconColor="red"
            caption="Failed to load tweet"
          />
        </div>
      )}

      {tweetLoadingState !== "hasLoadedWithError" && (
        <Position inset={0}>
          <SimpleAnimation
            flex={1}
            doShow={tweetLoadingState === "hasLoadedSuccessfully"}
            showAnimation={"slideInUp"}
          >
            <blockquote
              className="twitter-tweet"
              ref={ref}
              style={{ display: "flex" }}
            >
              <a href={getTweetUrl(tweetItem.tweetId)}></a>
            </blockquote>
          </SimpleAnimation>
        </Position>
      )}
    </div>
  );
}

export default React.memo(TweetItem);
