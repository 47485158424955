import { dripdrop } from "dripdrop";

let itemsListHandle: HTMLDivElement | null | undefined = undefined;

function set(value: HTMLDivElement | null) {
  if (itemsListHandle === value) return;

  itemsListHandle = value;
  dripdrop.notifySubscribers("itemsListHandle");
}

function useSubscribe() {
  dripdrop.useSubscribe("itemsListHandle");
  return get();
}

function get() {
  return itemsListHandle;
}

export const state_itemsListHandle = {
  get,
  set,
  useSubscribe,
};
