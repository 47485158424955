import { state_isAddItemMenuOpened } from "../state/state_isAddItemMenuOpened";
import { state_isItemFocused } from "../state/state_isItemFocused";
import { state_isMainMenuOpened } from "../state/state_isMainMenuOpened";
import { state_openedItemId } from "../state/state_openedItemId";

type $SCREEN =
  | "list"
  | "item-opened"
  | "item-focused"
  | "main-menu"
  | "additem-menu";

function get(): $SCREEN {
  if (state_isAddItemMenuOpened.get()) {
    return "additem-menu";
  }

  if (state_isMainMenuOpened.get()) {
    return "main-menu";
  }

  if (state_isItemFocused.get()) {
    return "item-focused";
  }

  if (state_openedItemId.get() !== undefined) {
    return "item-opened";
  }

  return "list";
}

function useSubscribe() {
  state_isAddItemMenuOpened.useSubscribe();
  state_isMainMenuOpened.useSubscribe();
  state_isItemFocused.useSubscribe();
  state_openedItemId.useSubscribe();
  return get();
}

export const superState_currentScreen = {
  get,
  useSubscribe,
};
