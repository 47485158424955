export function lib_downloadFile(file: File | undefined) {
  if (file === undefined) {
    return;
  }

  const a = document.createElement("a");
  a.download = file.name;

  a.href = URL.createObjectURL(file);
  a.click();
}
