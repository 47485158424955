// credits:  https://thewebdev.info/2022/04/30/how-to-encode-html-entities-in-javascript/

// export function encodeHtmlEntities(text: string) {
//   return text.replace(/./gm, function (s) {
//     return text.match(/[a-z0-9\s]+/i) ? text : "&#" + text.charCodeAt(0) + ";";
//   });
// }

// https://stackoverflow.com/questions/18749591/encode-html-entities-in-javascript/39243641#39243641
export function lib_encodeHtmlEntities(text: string) {
  var textArea = document.createElement("textarea");
  textArea.innerText = text;
  return textArea.innerHTML;
}
