// Only called on startup if online
//
// 1. load ItemsMap from SupaBase
// 2. on success:
//    - if items were found: persist them to dexie & memory, fin.
//    - if nothing was found: load example data
// 3. on fail:
//    - load example data

import { dexieDb } from "../../dexieDb/dexieDb";
import { state_isItemsDataLoaded } from "../../state/state_isItemDataLoaded";
import { state_items } from "../../state/state_items";
import { populateModelAsIndexedDbIsEmpty } from "../populateModelAsIndexedDbIsEmpty";

export async function loadAllItemsFromSupabase() {
  console.log("load:loadAllItemsFromSupabase");

  state_items.loadAllItemsFromSupabase(
    async (loadedItems) => {
      console.log(`loadItemData: Found ${loadedItems.length} items on Server`);

      if (loadedItems.length > 0) {
        console.log(`loadItemData: Copying items from Server to Memory`);
        loadedItems.forEach(async (i) => {
          await dexieDb.items.put(i);
        });

        // save to memory and recalc the caches
        await state_items.setItemsBulk(loadedItems);
        state_isItemsDataLoaded.set(true);

        return;
      }

      populateModelAsIndexedDbIsEmpty();

      // signal readiness of data
      state_isItemsDataLoaded.set(true);
    },
    (e) => {
      // TODO handle this
      console.log(e);

      // signal readiness of data
      state_isItemsDataLoaded.set(true);
    }
  );
}
