import { v4 } from "uuid";
import { ANIMATIONS_DURATION } from "../../config/constants";
import { $Item } from "../../plugins/types";
import { $FilterItem, state_filterItem } from "../state_filterItem";
import { state_hasBeenUpdatedItemId } from "../state_hasBeenUpdatedItemId";
import { state_items } from "../state_items";

export function actions_addItem(
  item: Partial<$Item>,
  filterItem?: $FilterItem
) {
  filterItem = filterItem || {};

  const insertId = (() => {
    if (item.id) return item.id;
    return v4();
  })();

  const createdAt = (() => {
    if (item.createdAt) return item.createdAt;
    return new Date();
  })();

  const isCreated = (() => {
    if (item.isCreated === false) return false;
    return true;
  })();

  const isHearted = (() => {
    if (item.isHearted) return true;
    if (filterItem.isHearted) return true;

    return false;
  })();

  const type = (() => {
    if (item.type) return item.type;
    if (filterItem.type) return filterItem.type;

    return "text";
  })();

  // console.log({ template });
  const newItem = {
    ...item,
    id: insertId,
    isCreated,
    createdAt,
    type,
    isHearted,
  } as $Item;
  // console.log({ newItem });
  state_items.addItem(newItem);

  if (state_filterItem.getIsSet() && isCreated) {
    state_filterItem.set({ type, isHearted: false });
  }

  setTimeout(() => {
    state_hasBeenUpdatedItemId.set(insertId);
  }, ANIMATIONS_DURATION);

  return insertId;
}
