import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Position } from "../../../components/lib/Position/Position";
import { RelativePosition } from "../../../components/lib/RelativePosition/RelativePosition";
import { SimpleAnimation } from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import {
  ANIMATIONS_DURATION,
  ANIMATIONS_ENTRY_ANIMATION,
} from "../../../config/constants";
import { getSpecForType } from "../../types";
import ShoppingItemIconIsBoughtCheck from "./ShoppingItemIconIsBoughtCheck";

function ShoppingItemIcon({ isBought }: { isBought: boolean }) {
  const shoppingSpec = getSpecForType("shopping");
  return (
    <Position
      className="ShoppingItemIcon_Position"
      inset={0}
      alignItems="center"
      justifyContent="center"
      scale={isBought ? 1.5 : 1.75}
    >
      <SimpleAnimation
        className="ShoppingItemIcon_Animation"
        showAnimation={ANIMATIONS_ENTRY_ANIMATION}
        showAnimationDuration={ANIMATIONS_DURATION}
      >
        <RelativePosition
          className="ShoppingItemIcon"
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon icon={faCartShopping} color={shoppingSpec.color} />
          <ShoppingItemIconIsBoughtCheck isBought={isBought} />
        </RelativePosition>
      </SimpleAnimation>
    </Position>
  );
}

export default ShoppingItemIcon;
