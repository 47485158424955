import { ANIMATIONS_DURATION } from "../../../config/constants";
import { SimpleAnimation } from "../../lib/SimpleAnimation/SimpleAnimation";
import "./LogoLetter.scss";

function LogoLetter({
  color,
  index = 0,
  letter,
}: {
  color: string;
  index?: number;
  letter: string;
}) {
  return (
    <SimpleAnimation
      showAnimation="bounceIn"
      showAnimationDelay={(index * ANIMATIONS_DURATION) / 5}
    >
      <span
        style={{
          color,
          transform: `rotate(${index % 2 === 0 ? "-" : ""}5deg)`,
        }}
      >
        {letter}
      </span>
    </SimpleAnimation>
  );
}

export default LogoLetter;
