import React, { ErrorInfo, PropsWithChildren } from "react";
import "./ErrorBoundary.scss";
import { ErrorPage } from "./ErrorPage";

type $ErrorBoundaryState = { error?: Error; hasError: boolean };

class ErrorBoundary extends React.Component<
  PropsWithChildren,
  $ErrorBoundaryState
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError = (error: string) => {
    return { hasError: true };
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
  }

  render() {
    console.log("render ErrorBoundary");
    if (this.state.hasError) {
      return (
        <ErrorPage
          error={this.state.error || new Error("Something went wrong.")}
        />
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
