import React, { useCallback } from "react";
import BasicItem from "../../../components/app/BasicItem/BasicItem";
import { state_itemDraft } from "../../../state/state_itemDraft";
import { state_openedItemId } from "../../../state/state_openedItemId";
import { $Item } from "../../types";
import { $TextItem } from "../types";
import "./TextItem.scss";

function TextItem({ item, isInFocus }: { item: $Item; isInFocus?: boolean }) {
  const textItem = item as $TextItem;

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      console.log("clicked text item");
      e.stopPropagation();
      e.preventDefault();
      state_openedItemId.set(item.id);
    },
    [item.id]
  );

  const text =
    (isInFocus && state_itemDraft.get(item.id)?.text) || textItem.text;

  return (
    <BasicItem
      itemId={item.id}
      onClick={onClick}
      text={text}
      isInFocus={isInFocus}
    />
  );
}

export default TextItem;
