import { $Item } from "../types";
import { $TextItem } from "./types";

export function userShare(item: $Item): Promise<ShareData> {
  return new Promise((resolve) => {
    const _item = item as $TextItem;
    resolve({
      text: _item.text,
    });
  });
}
