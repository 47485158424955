import { dripdrop } from "dripdrop";

let isTyping: boolean = false;

function set(value: boolean) {
  if (isTyping === value) return;

  isTyping = value;
  dripdrop.notifySubscribers("isTyping");
}

function get() {
  return isTyping;
}

function useSubscribe() {
  dripdrop.useSubscribe("isTyping");
  return get();
}

export const state_isTyping = {
  set,
  get,
  useSubscribe,
};
