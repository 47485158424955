import React, {Suspense} from "react";
import {VERTICAL_MARGIN} from "../../../config/constants";
import {registeredPlugins} from "../../../plugins/registeredPlugins";
import {$Item, $UserShareFunction} from "../../../plugins/types";
import {state_isItemFocused} from "../../../state/state_isItemFocused";
import {state_isScrolling} from "../../../state/state_isScrolling";
import {state_isTyping} from "../../../state/state_isTyping";
import {state_items} from "../../../state/state_items";
import {state_openedItemId} from "../../../state/state_openedItemId";
import {FullscreenLoader} from "../../lib/Loader/Loader";
import {Position} from "../../lib/Position/Position";
import ItemShareButtonInner from "./ItemShareButtonInner";

function ItemShareButton() {
  const isTyping = state_isTyping.useSubscribe();
  const isItemFocused = state_isItemFocused.useSubscribe();
  const openedItemId = state_openedItemId.useSubscribe();
  const isScrolling = state_isScrolling.useSubscribe();

  let shareFunction: undefined | $UserShareFunction = undefined;
  let openedItem: $Item | undefined = undefined;
  if (openedItemId) {
    openedItem = state_items.getItem(openedItemId);
    shareFunction = registeredPlugins[openedItem.type as string].userShare;
  }

  let doShow = shareFunction && openedItemId && !isTyping && !isItemFocused;

  if (isScrolling) {
    doShow = false;
  }

  return (
    <Position
      className="ItemShareButton_Position"
      bottom={10 + VERTICAL_MARGIN}
      right={10}
      pointerEvents={doShow ? "auto" : "none"}
      opacity={doShow ? 1 : 0}
    >
      <div className="ItemShareButton">
        <Suspense fallback={<FullscreenLoader />}>
          <ItemShareButtonInner
            openedItem={openedItem}
            shareFunction={shareFunction}
          />
        </Suspense>
      </div>
    </Position>
  );
}

export default React.memo(ItemShareButton);
