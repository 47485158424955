import { dripdrop } from "dripdrop";

let albumImages: string[] | undefined = undefined;

function set(value?: string[]) {
  if (albumImages === value) return;

  albumImages = value;
  dripdrop.notifySubscribers("albumImages");
}

function useSubscribe() {
  dripdrop.useSubscribe("albumImages");
  return albumImages;
}

export const state_albumImages = { set, useSubscribe };
