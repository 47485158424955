import React from "react";
import { state_settingsBackground } from "../../../state/state_settingsBackground";
import { RelativePosition } from "../../lib/RelativePosition/RelativePosition";
import "./BackgroundSelection.scss";
import BackgroundSelectionChoice from "./BackgroundSelectionChoice";

function BackgroundSelection() {
  const settingsBackground = state_settingsBackground.useSubscribe();
  const patterns = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <RelativePosition
      className="BackgroundSelection"
      backgroundColor="#ddd"
      gap={10}
      flexDirection="column"
      alignItems="center"
    >
      <h3>Choose a Theme</h3>
      <RelativePosition className="BackgroundSelectionChoices" gap={10}>
        {patterns.map((idx) => {
          return (
            <BackgroundSelectionChoice
              key={idx}
              isSet={settingsBackground === idx}
              renderIndex={idx}
            />
          );
        })}
      </RelativePosition>
    </RelativePosition>
  );
}

export default React.memo(BackgroundSelection);
