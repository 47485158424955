import { faFlag } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../Button/Button";
import { Position } from "../Position/Position";
import {
  PUSH_NOTIFICATIONS_ANIMATION_DURATION,
  PUSH_NOTIFICATIONS_VISIBILITY_DURATION,
} from "./pushNotifications.constants";
import "./PushNotifications.scss";
import {
  $NOTE,
  actionClosePushNotification,
  useSubscribePushNotifications,
} from "./pushNotifications.state";

const UNIT_HEIGHT = 60;

function SinglePushNotification({
  note,
  index,
}: {
  note: $NOTE;
  index: number;
}) {
  const [doAnimateIn, setDoAnimateIn] = useState(true);
  const [doAnimateOut, setDoAnimateOut] = useState(false);

  const close = useCallback(() => {
    setDoAnimateOut(true);
    actionClosePushNotification(note.caption);
  }, [note.caption]);

  useEffect(() => {
    const timeout1 = window.setTimeout(() => {
      setDoAnimateIn(false);
    }, PUSH_NOTIFICATIONS_ANIMATION_DURATION);

    let timeout2: number | undefined = undefined;
    let timeout3: number | undefined = undefined;

    if (!note.isSticky) {
      const startAnimateOutAfterMs =
        PUSH_NOTIFICATIONS_VISIBILITY_DURATION -
        PUSH_NOTIFICATIONS_ANIMATION_DURATION;

      timeout2 = window.setTimeout(() => {
        setDoAnimateOut(true);
      }, startAnimateOutAfterMs);

      timeout3 = window.setTimeout(() => {
        setDoAnimateOut(false);
      }, startAnimateOutAfterMs + PUSH_NOTIFICATIONS_ANIMATION_DURATION);
    }

    return () => {
      window.clearTimeout(timeout1);
      if (timeout2) window.clearTimeout(timeout2);
      if (timeout3) window.clearTimeout(timeout3);
    };
  }, [note.isSticky]);

  const classes = {
    SinglePushNotification: classNames({
      SinglePushNotification: true,
      animate__animated: true,
      animate__bounceIn: doAnimateIn,
      animate__fadeOut: doAnimateOut,
    }),
  };

  return (
    <Position
      className="SinglePushNotification_Position"
      left={10}
      right={10}
      top={10}
      height={UNIT_HEIGHT}
      translateY={index * (UNIT_HEIGHT + 10)}
    >
      <div
        onClick={close}
        className={classes.SinglePushNotification}
        style={{
          animationDuration: 2 * PUSH_NOTIFICATIONS_ANIMATION_DURATION + "ms",
        }}
      >
        <div className="PushNotificationIcon">
          <FontAwesomeIcon icon={faFlag} color="black" fontSize={25} />
        </div>
        <span className="PushNotificationCaption">{note.caption}</span>
        {note.isSticky && (
          <Button icon={faTimes} buttonSize={UNIT_HEIGHT} iconScale={2} />
        )}
      </div>
    </Position>
  );
}

function PushNotifications() {
  const pushNotifications = useSubscribePushNotifications();

  return (
    <Position className="PushNotifications_Position" top={0} left={0} right={0}>
      {Object.values(pushNotifications).map((note, loopIndex) => (
        <SinglePushNotification
          key={note.caption}
          note={note}
          index={loopIndex}
        />
      ))}
    </Position>
  );
}

export default React.memo(PushNotifications);
