import { state_filterItem } from "../state/state_filterItem";
import { state_items } from "../state/state_items";

function get(): string[] {
  const filterItem = state_filterItem.get();
  const type = state_filterItem.getType();

  const itemIdsMap = state_items.getItemIdsMap();
  return itemIdsMap[type + (filterItem.isHearted ? ".isHearted" : "")] || [];
}

function useSubscribe() {
  state_items.useSubscribeItemIdsMap();
  state_filterItem.useSubscribe();
  return get();
}

export const superState_itemIds = {
  get,
  useSubscribe,
};
