import { Position } from "../Position/Position";
import "./Loader.scss";
const Loader = ({ sizePix }: { sizePix?: number }) => {
  const style = sizePix ? { width: sizePix, height: sizePix } : {};
  return <div className="Loader" style={style}></div>;
};

function FullscreenLoader() {
  return (
    <Position
      inset={0}
      alignItems="center"
      justifyContent="center"
      backgroundColor="#ccc"
    >
      <Loader />
    </Position>
  );
}

export default Loader;
export { FullscreenLoader };
