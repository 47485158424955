import React from "react";
import { state_albumImages } from "../../../state/state_albumImages";
import { Position } from "../../lib/Position/Position";
import "./ImageAlbum.scss";

function ImageAlbum() {
  const albumImages = state_albumImages.useSubscribe();

  const showAlbum = albumImages !== undefined;
  const doZoomOut = () => state_albumImages.set(undefined);

  return (
    <Position
      className="ImageAlbum"
      inset={0}
      onClick={doZoomOut}
      opacity={showAlbum ? 1 : 0}
      pointerEvents={showAlbum ? "auto" : "none"}
    >
      {albumImages?.map((imageSrc: string) => (
        <img
          key={imageSrc}
          src={imageSrc}
          alt="Put something meaningful here"
          loading="lazy"
        />
      ))}
    </Position>
  );
}

export default React.memo(ImageAlbum);
