import { ANIMATIONS_DURATION } from "../../config/constants";
import { state_filterItem } from "../state_filterItem";
import { state_hasBeenUpdatedItemId } from "../state_hasBeenUpdatedItemId";
import { state_isItemFocused } from "../state_isItemFocused";
import { state_isTyping } from "../state_isTyping";
import { state_itemDraft } from "../state_itemDraft";
import { state_items } from "../state_items";
import { state_openedItemId } from "../state_openedItemId";
import { state_toBeHiddenItemId } from "../state_toBeHiddenItemId";

export function action_submitOpenedItem() {
  const openedItemId = state_openedItemId.get();
  if (!openedItemId) return;

  const item = state_items.getItem(openedItemId);
  const isCreated = item.isCreated;

  const draft = state_itemDraft.get(openedItemId);
  if (draft?.text) {
    state_items.setItemText(openedItemId, draft.text);
    state_items.setItemRawInput(openedItemId, draft.text);
    state_items.setItemIsCreated(openedItemId);
  }

  if (!isCreated) {
    state_filterItem.setType(item.type);
  }

  // close keyboard
  state_isTyping.set(false);

  // wait so the keyboard is closed, then hide ItemFocus and show list again
  setTimeout(() => {
    state_isItemFocused.set(false);
    //state_openedItemId.set(undefined);

    // wait, then mark item as updated
    setTimeout(() => {
      const type = state_filterItem.get().type;

      const typeHasChanged =
        type !== "all" &&
        type !== undefined &&
        type !== state_items.getItem(openedItemId).type;

      if (typeHasChanged) {
        state_hasBeenUpdatedItemId.set(openedItemId, () => {
          state_toBeHiddenItemId.set(openedItemId, () => {
            state_openedItemId.set(undefined);
            state_items.triggerRecalcItemIdsMap();
          });
        });
      } else {
        if (!isCreated) {
          state_items.triggerRecalcItemIdsMap();
          state_openedItemId.set(undefined);
        } else {
          state_hasBeenUpdatedItemId.set(openedItemId, () => {
            state_items.triggerRecalcItemIdsMap();
          });
        }
      }
    }, ANIMATIONS_DURATION);
  }, ANIMATIONS_DURATION);
}
