import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Position } from "../../../components/lib/Position/Position";
import { SimpleAnimation } from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import "./ShoppingItemIconIsBoughtCheck.scss";

function ShoppingItemIconIsBoughtCheck({ isBought }: { isBought: boolean }) {
  return (
    <Position
      className="ShoppingItemIconIsBoughtCheck_Position"
      translateX={8}
      translateY={8}
    >
      <SimpleAnimation
        className="ShoppingItemIconIsBoughtCheck_Animation"
        doShow={isBought}
        showAnimation="slideInUp"
        hideAnimation="fadeOut"
        doHideOnFirstRender={!isBought}
        flex={1}
      >
        <div className="ShoppingItemIconIsBoughtCheck">
          <FontAwesomeIcon icon={faCheck} color="white" />
        </div>
      </SimpleAnimation>
    </Position>
  );
}

export default ShoppingItemIconIsBoughtCheck;
