import { dripdrop } from "dripdrop";

let keyboardHeight: number = 0;

function set(value: number) {
  if (keyboardHeight === value) return;

  keyboardHeight = value;
  dripdrop.notifySubscribers("keyboardHeight");
}

function useSubscribe() {
  dripdrop.useSubscribe("keyboardHeight");
  return keyboardHeight;
}

export const state_keyboardHeight = {
  set,
  useSubscribe,
};
