import {faBug, faRotate, faWind} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef} from "react";
import {ANIMATIONS_DURATION} from "../../../config/constants";
import {lib_getDeviceId} from "../../../libs/functions/lib_getDeviceId";
import {actions_reloadApp} from "../../../state/actions/actions_reloadApp";
import {state_areAnimationsSlowed} from "../../../state/state_areAnimationsSlowed";
import {state_isMainMenuOpened} from "../../../state/state_isMainMenuOpened";
import Button from "../../lib/Button/Button";
import DelayRender from "../../lib/DelayRender/DelayRender";
import {Position} from "../../lib/Position/Position";
import {actionPushNotification} from "../../lib/PushNotifications/pushNotifications.state";
import {RelativePosition} from "../../lib/RelativePosition/RelativePosition";
import {SimpleAnimation} from "../../lib/SimpleAnimation/SimpleAnimation";
import ToggleControl from "../../lib/ToggleControl/ToggleControl";
import BackgroundSelection from "../BackgroundSelection/BackgroundSelection";
import LogoWord from "../LogoWord/LogoWord";
import "./MainMenu.scss";

export const MAIN_MENU_BUTTON_BORDERCOLOR = "rgba(0,0,0,0.25)";

function MainMenu() {
  const isMainMenuOpened = state_isMainMenuOpened.useSubscribe();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMainMenuOpened) {
      ref.current?.scrollTo(0, 0);
    }
  }, [isMainMenuOpened]);

  return (
    <Position
      className="MainMenu_Position"
      inset={0}
      pointerEvents={isMainMenuOpened ? "auto" : "none"}
    >
      <SimpleAnimation
        showAnimation="slideInLeft"
        hideAnimation="slideOutLeft"
        doHideOnFirstRender={true}
        doShow={isMainMenuOpened}
      >
        <RelativePosition
          className="MainMenu"
          innerRef={ref}
          flex={1}
          flexDirection="column"
          alignItems="center"
        >
          <h1>
            <RelativePosition
              height={150}
              alignItems="center"
              justifyContent="center"
              className="LogoWord_Position"
            >
              {isMainMenuOpened && (
                <DelayRender delay={ANIMATIONS_DURATION / 1.5}>
                  <LogoWord onlyShowFirst={false} />
                </DelayRender>
              )}
            </RelativePosition>
          </h1>

          <span>Personal Life Manager</span>
          <span>Your New Best Friend</span>
          <span style={{fontSize: 50}}>❤️</span>

          <BackgroundSelection />

          <AreAnimationsSlowed />

          <RelativePosition className="FetchNewVersion">
            <Button
              icon={faRotate}
              iconScale={1.5}
              caption="Download Update"
              smallCaption={`Current Version: ${process.env.REACT_APP_VERSION}`}
              onClick={actions_reloadApp}
            />
          </RelativePosition>

          <RelativePosition
            className="CopyDeviceId"
            onClick={() =>
              navigator.clipboard
                .writeText(lib_getDeviceId())
                .then(() =>
                  actionPushNotification(
                    "Device Id has been saved to your clipboard"
                  )
                )
            }
          >
            {lib_getDeviceId()}
          </RelativePosition>

          <RelativePosition>
            <span>
              An{" "}
              <a
                href="mailto:arash@yalpani.de"
                target="_blank"
                rel="noreferrer"
              >
                Arash Yalpani
              </a>{" "}
              pet project!
            </span>
          </RelativePosition>

          {/* <span>
          More on{" "}
          <ParentLink url="https://github.com/ayalpani/lisd5" text="GitHub" />
        </span> */}
        </RelativePosition>
      </SimpleAnimation>
    </Position>
  );
}

export default MainMenu;

function AreAnimationsSlowed() {
  const areAnimationsSlowed = state_areAnimationsSlowed.useSubscribe();
  return (
    <RelativePosition className="AreAnimationsSlowed">
      <ToggleControl
        value={areAnimationsSlowed}
        title="Animation Speed"
        onClick={(result) => state_areAnimationsSlowed.set(result)}
        captionFalse="Normal"
        iconFalse={faWind}
        captionTrue="Slow"
        iconTrue={faBug}
      />
    </RelativePosition>
  );
}
