import { dexieDb } from "../dexieDb/dexieDb";
import { state_items } from "../state/state_items";

// starts up 2 one-second-intervals that check for item
// updates and for item deletes inside the dexiedb queues
// - and call functions to synch the backend for every
// itemId in the queue.
//
export function startup_registerSendItemUpdatesToServer() {
  setInterval(function sendItemUpdatesToServer() {
    dexieDb.itemIdsUpdatedQueue.each((entry) => {
      state_items.saveItemUpdateToSupabase(
        entry.itemId,
        () => {
          console.log("sendItemUpdatesToServer", entry.itemId);
          setTimeout(() => {
            console.log(
              "sendItemUpdatesToServer, delete queue entry",
              entry.itemId
            );
            // https://github.com/dexie/Dexie.js/issues/522#issuecomment-575190070
            dexieDb.itemIdsUpdatedQueue.delete(entry.itemId);
          }, 0);
        },
        () => {
          console.log(
            `Could not save item ${entry.itemId} to Supabase. Will try again`
          );
        }
      );
    });
  }, 1000);

  setInterval(function sendItemDeletesToServer() {
    dexieDb.itemIdsDeletedQueue.each((entry) => {
      console.log("call state_items.deleteItemFromSupabase");
      state_items.deleteItemFromSupabase(
        entry.itemId,
        () => {
          console.log("sendItemDeletesToServer", entry.itemId);
          setTimeout(() => {
            console.log(
              "sendItemDeletesToServer, delete queue entry",
              entry.itemId
            );
            // https://github.com/dexie/Dexie.js/issues/522#issuecomment-575190070
            dexieDb.itemIdsDeletedQueue.delete(entry.itemId);
          }, 0);
        },
        () => {
          console.log(
            `Could not delete item ${entry.itemId} from Supabase. Will try again`
          );
        }
      );
    });
  }, 1000);
}
