import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ANIMATIONS_DURATION } from "../../../config/constants";
import { action_cancelOpenedItemSubmit } from "../../../state/actions/actions_cancelOpenedItemSubmit";
import { action_submitOpenedItem } from "../../../state/actions/actions_submitOpenedItem";
import { state_focusedItemHasText } from "../../../state/state_focusedItemHasText";
import { state_isItemFocused } from "../../../state/state_isItemFocused";
import { state_isTyping } from "../../../state/state_isTyping";
import { state_keyboardHeight } from "../../../state/state_keyboardHeight";
import { state_openedItemId } from "../../../state/state_openedItemId";

import Button from "../../lib/Button/Button";
import { Position } from "../../lib/Position/Position";
import { SimpleAnimation } from "../../lib/SimpleAnimation/SimpleAnimation";
import "./ItemDraftToolbar.scss";

function ItemDraftToolbar() {
  const openedItemId = state_openedItemId.useSubscribe();
  const isItemFocused = state_isItemFocused.useSubscribe();
  const isItemOpened = openedItemId !== undefined;
  const isTyping = state_isTyping.useSubscribe();

  if (!isItemOpened) return null;
  if (!isItemFocused) return null;
  if (!isTyping) return null;

  return <ItemDraftToolbarInner />;
}

function ItemDraftToolbarInner() {
  const keyboardHeight = state_keyboardHeight.useSubscribe();
  const focusedItemHasText = state_focusedItemHasText.useSubscribe();

  return (
    <Position
      className="ItemDraftToolbar_Position"
      bottom={keyboardHeight}
      left={0}
      right={0}
    >
      <SimpleAnimation
        flex={1}
        className="ItemDraftToolbar_Animation"
        doShow={true}
        showAnimation="fadeIn"
        showAnimationDelay={ANIMATIONS_DURATION}
      >
        <div className="ItemDraftToolbar">
          <Button
            flex={1}
            caption="CLOSE"
            captionColor="#999"
            backgroundColor="white"
            borderRadius={10}
            onClick={action_cancelOpenedItemSubmit}
          />
          <Button
            //isDisabled={isDisabled}
            flex={1}
            iconColor="white"
            captionColor="white"
            backgroundColor="black"
            icon={faCheck}
            onClick={action_submitOpenedItem}
            iconScale={1.5}
            borderRadius={10}
            isDisabled={!focusedItemHasText}
            caption="FINISH"
          />
        </div>
      </SimpleAnimation>
    </Position>
  );
}

export default React.memo(ItemDraftToolbar);
