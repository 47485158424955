import classNames from "classnames";
import React, {ReactEventHandler, ReactNode, useEffect} from "react";
import "./Position.scss";

// https://stackoverflow.com/a/37829643
function scrollIntoViewIfNeeded(target: HTMLDivElement) {
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    target.scrollIntoView();
  }

  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView();
  }
}

export type $PositionProps = {
  innerRef?: React.RefObject<HTMLDivElement> | null;
  className?: string;
  type?: "absolute" | "relative";
  disallowTransition?: boolean;
  children?: ReactNode;
  onClick?: ReactEventHandler;
  doScrollIntoView?: boolean;
  translateX?: number;
  translateY?: number;
  scale?: number;
  rotate?: string | undefined;
} & React.CSSProperties;

export const Position = ({
  innerRef = null,
  className = undefined,
  type = "absolute",
  disallowTransition = false,
  children,
  onClick,
  doScrollIntoView = false,
  translateX,
  translateY,
  scale,
  rotate,
  ...style // Spread the rest of the props as style
}: $PositionProps) => {
  const transforms = [];

  if (translateX !== undefined) transforms.push(`translateX(${translateX}px)`);
  if (translateY !== undefined) transforms.push(`translateY(${translateY}px)`);
  if (scale !== undefined) transforms.push(`scale(${scale})`);
  if (rotate !== undefined) transforms.push(`rotate(${rotate})`);

  let ref: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  ref = innerRef || ref;

  useEffect(() => {
    if (doScrollIntoView) {
      ref && ref.current && scrollIntoViewIfNeeded(ref.current);
    }
  }, [doScrollIntoView, ref]);

  const combinedStyle: React.CSSProperties = {
    position: type,
    transform: transforms.join(" "),
    ...style,
  };

  const DEBUG_POSITION_COMPONENTS = false;
  if (DEBUG_POSITION_COMPONENTS) {
    if (type === "absolute") {
      combinedStyle.outline = "1px dotted red";
    } else {
      combinedStyle.outline = "0.5px dotted red";
    }
  }

  const positionClass =
    (className === undefined ? "" : className + " ") +
    classNames("Position", {
      disallowTransition,
    });

  return (
    <div
      ref={ref}
      style={combinedStyle}
      onClick={onClick}
      className={positionClass}
    >
      {children}
    </div>
  );
};
