import { state_gyroscopeData } from "../state/state_gyroscopeData";

const TRANSLATE_RANGE = 5;

export function useGyroTranslations(
  translateRangeX = TRANSLATE_RANGE,
  translateRangeY = TRANSLATE_RANGE
) {
  const gyroscopeData = state_gyroscopeData.useSubscribe();

  let gyroX = 0;
  let gyroY = 0;

  if (gyroscopeData && gyroscopeData.beta < 30) {
    gyroX = Math.min(
      translateRangeX,
      Math.max(-translateRangeX, -Math.floor(gyroscopeData.gamma))
    );

    gyroY = Math.min(
      translateRangeY,
      Math.max(-translateRangeY, -Math.floor(gyroscopeData.beta))
    );
  }

  return { gyroX, gyroY };
}
