import {faPlus} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React from "react";
import {VERTICAL_MARGIN} from "../../../config/constants";
import {state_isAddItemMenuOpened} from "../../../state/state_isAddItemMenuOpened";
import {state_openedItemId} from "../../../state/state_openedItemId";
import Button from "../../lib/Button/Button";
import {Position} from "../../lib/Position/Position";
import {RelativePosition} from "../../lib/RelativePosition/RelativePosition";
import {SimpleAnimation} from "../../lib/SimpleAnimation/SimpleAnimation";
import "./AddItemMenuButton.scss";

export const ADD_ITEM_MENU_HEIGHT = 280;

function AddItemMenuButton() {
  const isAddItemMenuOpened = state_isAddItemMenuOpened.useSubscribe();
  const isItemOpened = state_openedItemId.useSubscribeIsItemOpened();

  const onClick = () => {
    state_isAddItemMenuOpened.set(!isAddItemMenuOpened);
  };

  const doShow = !isItemOpened;

  return (
    <Position
      className="AddItemButton_Position"
      flex={1}
      bottom={VERTICAL_MARGIN + 10}
      left={10}
      right={10}
      height={60}
      justifyContent="center"
      pointerEvents="none"
      scale={isAddItemMenuOpened ? 0.9 : 1}
    >
      <SimpleAnimation
        className="AddItemButton_Animation"
        key={isItemOpened ? "hide" : "show"}
        doShow={doShow}
        showAnimation="zoomIn"
        hideAnimation="zoomOut"
      >
        <RelativePosition
          className="AddItemButton_RelativePosition"
          flex={1}
          rotate={(isAddItemMenuOpened ? 135 : 0) + "deg"}
          scale={isAddItemMenuOpened ? 2 : 1}
          pointerEvents={doShow ? "auto" : "none"}
        >
          <div
            className={classNames("AddItemMenuButton", {isAddItemMenuOpened})}
          >
            <Button
              flex={1}
              buttonSize={60}
              icon={faPlus}
              iconScale={2}
              iconColor={isAddItemMenuOpened ? "#ccc" : "#000"}
              backgroundColor={
                isAddItemMenuOpened ? "transparent" : "rgba(255,255,255,0.9)"
              }
              onClick={onClick}
            />
          </div>
        </RelativePosition>
      </SimpleAnimation>
    </Position>
  );
}

export default React.memo(AddItemMenuButton);
