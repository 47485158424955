import { lib_isBase64ImageItem } from "../../libs/libs_image";
import { state_items } from "../../state/state_items";

export function processRawInput(itemId: string, rawInput: string) {
  const isImage = lib_isBase64ImageItem(rawInput);
  if (!isImage) return false;

  state_items.updateItem(itemId, {
    type: "base64Image",
    isProcessed: true,
    base64: rawInput,
    rawInput: "",
  });

  return "base64Image";
}
