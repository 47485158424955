import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Button from "../../../components/lib/Button/Button";
import "./ItemProcessedFailure.scss";

const ItemProcessedFailure = ({
  caption,
  doOpen,
  doRetry,
}: {
  caption: string;
  doOpen?: React.MouseEventHandler;
  doRetry?: React.MouseEventHandler;
}) => {
  const onClickRetry = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (doRetry) doRetry(e);
  };

  return (
    <div className="ItemProcessing hasFailed" onClick={doOpen}>
      <Button
        icon={faExclamationTriangle}
        iconColor="red"
        onClick={onClickRetry}
        caption={caption}
        captionWeight="normal"
        justifyContent="flex-start"
      />
    </div>
  );
};

export default ItemProcessedFailure;
