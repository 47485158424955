import isOnline from "is-online";
import { dexieDb } from "../../dexieDb/dexieDb";
import { $Item } from "../../plugins/types";
import { state_items } from "../../state/state_items";

// get all those items missing on the client and add them to indexdb and memory
export async function loadDiffFromServer() {
  console.log("loadDiffFromServer()", { isOnline: await isOnline() });
  if (!(await isOnline())) {
    return;
  }

  const allItemIdsOnClient = Object.values(state_items.getItemsMap()).map(
    (i) => i.id
  );

  state_items.loadItemsDiffFromSupabase(
    allItemIdsOnClient,
    (items: $Item[]) => {
      if (items.length) {
        items.forEach((i) => dexieDb.items.put(i));
        state_items.addItemsBulk(items as $Item[]);
      }
    },
    (e) => {
      console.log("loadItemsDiffFromSupabase: failed", e);
    }
  );
  // console.log("allItemIdsOnClient:" + allItemIdsOnClient.toString());
}
