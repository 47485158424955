import isOnline from "is-online";
import { SERVER_DIFF_INTERVAL } from "../config/constants";
import { hasIndexedDbBeenUsedBefore } from "./startup_loadItemData/hasIndexedDbBeenUsedBefore";
import { loadDiffFromServer } from "./startup_loadItemData/loadDiffFromServer";
import { loadEmptyData } from "./startup_loadItemData/loadEmptyData";
import { loadFromIndexedDb } from "./startup_loadItemData/loadFromIndexedDb";
import { loadAllItemsFromSupabase } from "./startup_loadItemData/loadItemsMapFromSupabase";

export async function startup_loadItemData() {
  console.log("startup_loadItemData");
  if (await hasIndexedDbBeenUsedBefore()) {
    await loadFromIndexedDb();
    setupServerDiffInterval();
    return;
  }

  // we are sure looking at an empty IndexedDb
  if (await isOnline()) {
    await loadAllItemsFromSupabase();
  } else {
    await loadEmptyData();
  }

  setupServerDiffInterval();
}

function setupServerDiffInterval() {
  loadDiffFromServer();

  setInterval(() => {
    loadDiffFromServer();
  }, SERVER_DIFF_INTERVAL);
}
