import { state_items } from "../../state/state_items";

// https://www.seancdavis.com/posts/extract-twitter-handle-from-url-with-javascript/
function getTweetId(url: string) {
  const statusIdRegex = /\/status(es)?\/(\d+)/;
  try {
    const parsed = new URL(url);

    if (parsed.host !== "twitter.com") {
      return false;
    }

    if (parsed.pathname.indexOf("status") === -1) {
      return false;
    }

    var match = statusIdRegex.exec(parsed.pathname);
    if (match === null) {
      return false;
    }

    return match[2];
  } catch (e) {
    return false;
  }
}

export function processRawInput(itemId: string, rawInput: string) {
  const tweetId = getTweetId(rawInput);
  if (!tweetId) return false;

  state_items.updateItem(itemId, {
    type: "tweet",
    tweetId,
    isProcessed: true,
  });

  return "tweet";
}
