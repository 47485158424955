import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { getSpecForType } from "../../../plugins/types";
import { actions_addBasicItem } from "../../../state/actions/actions_addBasicItem";
import { actions_addClipboardText } from "../../../state/actions/actions_addClipboardText";
import { actions_addLocationItem } from "../../../state/actions/actions_addLocationItem";
import { state_isAddItemMenuOpened } from "../../../state/state_isAddItemMenuOpened";
import { Position } from "../../lib/Position/Position";
import Spacer from "../../lib/Spacer/Spacer";
import { ADD_ITEM_MENU_HEIGHT } from "../AddItemMenuButton/AddItemMenuButton";
import AddItemMenuTile from "../AddItemMenuTile/AddItemMenuTile";
import { ImageUploadButton } from "../ImageUploadButton/ImageUploadButton";
import PasteBar from "../PasteBar/PasteBar";
import "./AddItemMenu.scss";

function AddItemMenu() {
  const isAddItemMenuOpened = state_isAddItemMenuOpened.useSubscribe();

  const textSpec = getSpecForType("text")!;
  const todoSpec = getSpecForType("todo")!;
  const shoppingSpec = getSpecForType("shopping")!;
  const locationSpec = getSpecForType("location")!;

  return (
    <>
      <Position
        inset={0}
        backgroundColor="black"
        opacity={isAddItemMenuOpened ? 0.25 : 0}
        onClick={() => state_isAddItemMenuOpened.set(false)}
        pointerEvents={isAddItemMenuOpened ? "auto" : "none"}
      />

      <Position
        className="AddItemMenu_Position"
        left={0}
        right={0}
        bottom={0}
        height={ADD_ITEM_MENU_HEIGHT}
        translateY={isAddItemMenuOpened ? 0 : ADD_ITEM_MENU_HEIGHT}
        opacity={isAddItemMenuOpened ? 1 : 0}
        pointerEvents={isAddItemMenuOpened ? "auto" : "none"}
      >
        <div className="AddItemMenu">
          {/* doShow={isAddItemMenuOpened} */}

          {isAddItemMenuOpened && (
            <>
              <PasteBar onClick={() => actions_addClipboardText()} />
              <div className="grid">
                <AddItemMenuTile
                  idx={1}
                  icon={textSpec.icon}
                  caption={textSpec.caption}
                  iconColor={textSpec.color}
                  onClick={() => actions_addBasicItem("text")}
                />

                <AddItemMenuTile
                  idx={2}
                  icon={todoSpec.icon}
                  caption={todoSpec.caption}
                  iconColor={todoSpec.color}
                  onClick={() => actions_addBasicItem("todo")}
                />

                <AddItemMenuTile
                  idx={3}
                  icon={shoppingSpec.icon}
                  caption={shoppingSpec.caption}
                  iconColor={shoppingSpec.color}
                  onClick={() => actions_addBasicItem("shopping")}
                />

                <AddItemMenuTile
                  idx={0}
                  icon={faCamera}
                  caption="Image"
                  iconColor="darkorange"
                >
                  <ImageUploadButton />
                </AddItemMenuTile>

                <div />

                <AddItemMenuTile
                  idx={2}
                  icon={locationSpec.icon}
                  caption={locationSpec.caption}
                  iconColor={locationSpec.color}
                  onClick={() => actions_addLocationItem()}
                />
              </div>
              <Spacer />
            </>
          )}
        </div>
      </Position>
    </>
  );
}

export default AddItemMenu;
