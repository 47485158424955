import { dripdrop } from "dripdrop";

export const ITEMLIST_PAGESIZE = 50;
let numLoadedItems: number = ITEMLIST_PAGESIZE * 2;

function reset() {
  numLoadedItems = ITEMLIST_PAGESIZE * 2;
}

function set(value: number) {
  if (numLoadedItems === value) return;

  numLoadedItems = value;
  dripdrop.notifySubscribers("numLoadedItems");
}

function useSubscribe() {
  dripdrop.useSubscribe("numLoadedItems");
  return numLoadedItems;
}

function get() {
  return numLoadedItems;
}

export const state_numLoadedItems = {
  get,
  reset,
  set,
  useSubscribe,
};
