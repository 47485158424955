import { $Item } from "../types";
import { $TweetItem } from "./types";

export default async function userShare(item: $Item): Promise<ShareData> {
  return new Promise((resolve) => {
    const _item = item as $TweetItem;

    // https://stackoverflow.com/a/45201739
    resolve({ text: `https://twitter.com/i/web/status/${_item.tweetId}` });
  });
}
