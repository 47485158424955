import {
  faTwitter,
  faYoutube,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { faCircle, faImage } from "@fortawesome/free-regular-svg-icons";
import {
  faAlignLeft,
  faCamera,
  faCartShopping,
  faLink,
  faLocationDot,
  faShapes,
} from "@fortawesome/free-solid-svg-icons";
import { $Base64ImageItem } from "./base64Image/types";
import { $ImageItem } from "./image/types";
import { $LinkItem } from "./link/types";
import { $LocationItem } from "./location/types";
import { $ShoppingItem } from "./shopping/types";
import { $TextItem } from "./text/types";
import { $TodoItem } from "./todo/types";
import { $TweetItem } from "./tweet/types";
import { $YouTubeItem } from "./youTube/types";

export type $Item = {
  id: string;
  createdAt: Date;
  rawInput?: string;
  isHearted?: boolean;
  isCreated?: boolean;

  startedProcessingAt?: Date;
  isProcessed?: boolean;
  isProcessedFailure?: string;
} & (
  | $TextItem
  | $TodoItem
  | $TweetItem
  | $LinkItem
  | $LocationItem
  | $Base64ImageItem
  | $ImageItem
  | $YouTubeItem
  | $ShoppingItem
);

export type $ItemsMap = { [key: string]: $Item };

export type $ItemIdsMap = { [key: string]: string[] };

export type $ActionUpdateItem = (
  itemId: string,
  itemUpdates: Partial<$Item>
) => void;

export type $BasicItem = $TextItem | $TodoItem | $ShoppingItem;

// type guard
// https://www.typescriptlang.org/docs/handbook/advanced-types.html
export function isBasicItem(item: $Item | undefined): item is $Item {
  return ["link", "text", "todo", "shopping", "location"].includes(
    (item as $BasicItem).type
  );
}

export type $UserShareFunction = (item: $Item) => Promise<ShareData>;
export type $Plugin = {
  ItemPlugin: React.ElementType;
  userShare?: $UserShareFunction;
  processRawInput?: (itemId: string, value: string) => $ItemType | false;
  restartItemProcessing?: (itemId: string) => void;
};

export type $PluginsType = { [key: string]: $Plugin };
export type $ItemType =
  | "link"
  | "text"
  | "todo"
  | "shopping"
  | "link"
  | "youTube"
  | "tweet"
  | "location"
  | "image"
  | "base64Image"
  | "all";

export type $TypeSpec = {
  type: $ItemType;
  icon: IconDefinition;
  caption: string;
  captionPlural: string;
  color: string;
};

export const allTypes: { [key: string]: $TypeSpec } = {
  all: {
    type: "all",
    icon: faShapes,
    caption: "Item",
    captionPlural: "Items",
    color: "black",
  },
  text: {
    type: "text",
    icon: faAlignLeft,
    caption: "Note",
    captionPlural: "Notes",
    color: "black",
  },

  location: {
    type: "location",
    icon: faLocationDot,
    caption: "Location",
    captionPlural: "Locations",
    color: "hotpink",
  },

  todo: {
    type: "todo",
    icon: faCircle,
    caption: "Todo",
    captionPlural: "Todos",
    color: "blue",
  },

  shopping: {
    type: "shopping",
    icon: faCartShopping,
    caption: "Shopping",
    captionPlural: "Shopping",
    color: "green",
  },

  link: {
    type: "link",
    icon: faLink,
    caption: "Link",
    captionPlural: "Links",
    color: "dodgerblue",
  },

  youTube: {
    type: "youTube",
    icon: faYoutube,
    caption: "Video",
    captionPlural: "Videos",
    color: "red",
  },

  tweet: {
    type: "tweet",
    icon: faTwitter,
    caption: "Tweet",
    captionPlural: "Tweets",
    color: "dodgerblue",
  },

  image: {
    type: "image",
    icon: faImage,
    caption: "Image",
    captionPlural: "Images",
    color: "darkorange",
  },

  base64Image: {
    type: "base64Image",
    icon: faCamera,
    caption: "Photo",
    captionPlural: "Photos",
    color: "darkorange",
  },
};

export function getSpecForType(type: $ItemType): $TypeSpec {
  if (type === undefined) return allTypes["all"];
  return allTypes[type];
}
