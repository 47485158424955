import React from "react";
import { getSpecForType } from "../../../plugins/types";
import { RelativePosition } from "../../lib/RelativePosition/RelativePosition";
import AddItemMenuTile from "../AddItemMenuTile/AddItemMenuTile";
import { $AllSelectableTypes } from "../TypeSwitcher/TypeSwitcher.types";

function TypeSwitcherChoice({
  renderIdx,
  type,
  isSelected,
  onClick,
}: {
  renderIdx: number;
  type: $AllSelectableTypes;
  isSelected: boolean;
  onClick: () => void;
}) {
  const typeSpec = getSpecForType(type);
  if (typeSpec === undefined) return null;

  return (
    <RelativePosition opacity={isSelected ? 1 : 0.25}>
      <AddItemMenuTile
        idx={renderIdx}
        iconColor={typeSpec.color}
        icon={typeSpec.icon}
        caption={typeSpec.caption}
        onClick={() => onClick()}
      />
    </RelativePosition>
  );
}

export default React.memo(TypeSwitcherChoice);
