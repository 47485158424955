import { state_keyboardHeight } from "../state/state_keyboardHeight";

let virtualKeyboard: any = undefined;
export function startup_registerVirtualKeyboard() {
  if (!window.isSecureContext) {
    console.log("🛑 Browser is NOT in SecureContext");
    console.log("🛑 window.navigator.virtualKeyboard will be unavailable");
    console.log("🛑 See for yourself:", { navigator });
    console.log("🛑 Enable SecureContext for localhost in chrome://flags");
    return;
  }

  console.log("💚 IS in SecureContext");

  if ("virtualKeyboard" in navigator) {
    console.log("💚 The VirtualKeyboard API is supported!");
    virtualKeyboard = (window.navigator as any).virtualKeyboard;

    const vk = virtualKeyboard;

    vk.overlaysContent = true;

    vk.addEventListener("geometrychange", () => {
      // Request the canvas be repainted so it can reposition
      // the active cell above the virtual keyboard.
      //requestAnimationFrame(() => console.log("paint"));
    });

    vk.addEventListener("geometrychange", (event: any) => {
      const { x, y, width, height } = event.currentTarget.boundingRect;
      console.log(
        `The keyboard area coordinates are x:${x}, y:${y}, width:${width}, height:${height}`
      );

      virtualKeyboard.hide();
      state_keyboardHeight.set(height);
    });
  }
}
