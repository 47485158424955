import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lib_getDateCaption } from "../../../libs/libs_date";
import { actionPushNotification } from "../../lib/PushNotifications/pushNotifications.state";
import "./DateSeparator.scss";

function onClick(e: React.MouseEvent) {
  actionPushNotification("Thanks for clicking. ", 1, true);
}

function DateSeparator({ createdAt }: { createdAt: Date }) {
  const listDateCaption = lib_getDateCaption(createdAt);

  return (
    <div className="DateSeparator">
      {/* <div className="DateSeparatorLine" /> */}

      <div className="DateSeparatorContent" onClick={onClick}>
        <span className="captionLight">{listDateCaption.captionLight}</span>
        {listDateCaption.caption && (
          <>
            <span className="DateSeparatorDash">|</span>
            <span className="caption">{listDateCaption.caption}</span>
          </>
        )}

        <FontAwesomeIcon icon={faArrowUp} />
      </div>
    </div>
  );
}

export default DateSeparator;
