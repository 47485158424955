import { dripdrop } from "dripdrop";

let focusedItemHasText: boolean = false;

function set(value: boolean) {
  focusedItemHasText = value;
  dripdrop.notifySubscribers("focusedItemHasText");
}

function useSubscribe() {
  dripdrop.useSubscribe("focusedItemHasText");
  return focusedItemHasText;
}

function get() {
  return focusedItemHasText;
}

export const state_focusedItemHasText = {
  set,
  get,
  useSubscribe,
};
