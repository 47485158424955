import { state_items } from "../../state/state_items";

function isTodoType(itemId: string) {
  return state_items.getItem(itemId).type === "todo";
}

export function processRawInput(itemId: string, rawInput: string) {
  if (!isTodoType(itemId)) {
    return false;
  }

  state_items.updateItem(itemId, { text: rawInput, isProcessed: true });

  return "todo";
}
