import { faCircle, faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";
import Button from "../../../components/lib/Button/Button";
import { Position } from "../../../components/lib/Position/Position";
import { RelativePosition } from "../../../components/lib/RelativePosition/RelativePosition";
import { SimpleAnimation } from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import {
  ANIMATIONS_DURATION,
  ANIMATIONS_ENTRY_ANIMATION,
} from "../../../config/constants";
import { getSpecForType } from "../../types";
import "./TodoItemCheckbox.scss";

function TodoItemCheckbox({ isDone }: { isDone: boolean }) {
  const todoSpec = getSpecForType("todo");
  return (
    <Position
      className="TodoItemCheckbox_Position"
      inset={0}
      alignItems="center"
      justifyContent="center"
    >
      <SimpleAnimation
        className="TodoItemCheckbox_Animation"
        showAnimation={ANIMATIONS_ENTRY_ANIMATION}
        showAnimationDuration={ANIMATIONS_DURATION}
      >
        <div className={classNames("TodoItemCheckbox", { isDone })}>
          <RelativePosition scale={isDone ? 1.75 : 2}>
            <Button
              icon={isDone ? faCircleCheck : faCircle}
              iconColor={todoSpec.color}
              buttonSize={30}
            />
          </RelativePosition>
        </div>
      </SimpleAnimation>
    </Position>
  );
}

export default TodoItemCheckbox;
