import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEventHandler, ReactNode } from "react";
import { ANIMATIONS_DURATION } from "../../../config/constants";
import { lib_styleSheets } from "../../../libs/functions/lib_stylesheets";
import {
  ScaleAnimation,
  SimpleAnimation,
} from "../SimpleAnimation/SimpleAnimation";
import { View } from "../View/View";
import "./Button.scss";

const _DEFAULT_COLOR = "black";

export type $ButtonComponentParams = {
  buttonSize?: number;
  icon?: IconProp;
  iconJsx?: ReactNode;
  iconRotate?: number;
  iconFlip?: "vertical" | "horizontal";
  iconScale?: number;
  iconColor?: string;
  isOverflowHidden?: boolean;
  caption?: string;
  captionWeight?: "normal" | "bold";
  captionSize?: number;
  smallCaption?: string;
  smallCaptionColor?: string;
  smallIcon?: IconProp;
  smallIconColor?: string;
  borderColor?: string;
  borderRadius?: number;
  borderWidth?: number;
  vibrate?: boolean;
  onClick?: MouseEventHandler;
  captionOnClick?: MouseEventHandler;
  captionColor?: string;
  backgroundColor?: string;
  isDisabled?: boolean;
  doPulsate?: boolean;
  flex?: number;
  justifyContent?: "flex-start" | "center";
  type?: "primary" | "secondary";
};

function Button({
  buttonSize = 50,
  icon,
  iconJsx,
  iconRotate = 0,
  iconFlip,
  iconScale = 1,
  isOverflowHidden = true,
  caption,
  captionWeight = "bold",
  captionSize,
  smallCaption,
  smallIcon,
  onClick,
  captionOnClick,
  vibrate,

  /** colors */
  iconColor,
  captionColor,
  backgroundColor,
  smallIconColor,
  smallCaptionColor,
  borderColor,
  borderRadius = 100,
  borderWidth = 1,

  isDisabled,
  doPulsate = false,
  flex,
  justifyContent = "center",
  type = "primary",
}: $ButtonComponentParams) {
  // console.log("🖌️ render <Button>");

  const _onClick: MouseEventHandler = (
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    if (isDisabled) return;

    if (onClick) {
      onClick(e);
      // window.navigator.vibrate(100);
      if (vibrate) {
        window.navigator.vibrate(100);
      }
    }
  };

  const classes = {
    Button: classNames({
      Button: true,
      isDisabled,
      hasIconAndCaption: icon && caption,
      hasIconJsx: !!iconJsx,
      [type]: true,
      Pulsate: doPulsate,
    }),
    Caption: classNames({
      Caption: true,
    }),
    SmallCaption: classNames({
      SmallCaption: true,
    }),
  };

  const styles = lib_styleSheets.create({
    Button: {
      opacity: isDisabled ? 0.25 : 1,
      backgroundColor: backgroundColor,
      flex,
      justifyContent,
      minHeight: buttonSize,
      height: buttonSize,
      borderColor: borderColor ? borderColor : "transparent",
      borderStyle: "solid",
      borderWidth: borderColor ? borderWidth : 0,
      borderRadius,
      overflow: isOverflowHidden ? "hidden" : "none",
    },
    ButtonIcon: {
      minHeight: buttonSize,
      minWidth: buttonSize,
      borderRadius: buttonSize / 2,
      pointerEvents: "none",
      transform:
        iconFlip && (iconFlip === "horizontal" ? "scaleX(-1)" : "scaleY(-1)"),
    },
    Caption: {
      fontWeight: captionWeight,
      fontSize: captionSize,
      paddingLeft: icon && caption ? 0 : buttonSize / 4,
      paddingRight: buttonSize / 3,
      minHeight: buttonSize,

      color:
        type === "secondary"
          ? undefined
          : captionColor || iconColor || _DEFAULT_COLOR,
    },
    SmallCaption: {
      color: smallCaptionColor || _DEFAULT_COLOR,
    },
  });

  return (
    <View className={classes.Button} onClick={_onClick} style={styles.Button}>
      <SimpleAnimation
        className="BasicItemIcon_Animation"
        showAnimation="bounceIn"
        showAnimationDuration={2 * ANIMATIONS_DURATION}
      >
        <ScaleAnimation scale={iconScale}>
          {iconJsx && (
            <div className="ButtonIcon" style={styles.ButtonIcon}>
              {iconJsx}
            </div>
          )}

          {icon && (
            <div className="ButtonIcon" style={styles.ButtonIcon}>
              <FontAwesomeIcon
                icon={icon}
                color={iconColor || captionColor || _DEFAULT_COLOR}
                className="Icon"
                style={{
                  transform: `rotate(${iconRotate}deg)`,
                }}
              />
            </div>
          )}
        </ScaleAnimation>
      </SimpleAnimation>

      <SimpleAnimation
        showAnimation="fadeIn"
        showAnimationDelay={ANIMATIONS_DURATION / 2}
        showAnimationDuration={ANIMATIONS_DURATION}
      >
        {caption && (
          <span
            className={classes.Caption}
            style={styles.Caption}
            onClick={(e) => {
              if (captionOnClick) {
                e.stopPropagation();
                captionOnClick(e);
              }
            }}
          >
            {caption}
            {smallCaption && (
              <span
                className={classes.SmallCaption}
                style={styles.SmallCaption}
              >
                {smallIcon && (
                  <FontAwesomeIcon
                    icon={smallIcon}
                    className="SmallIcon"
                    color={smallIconColor}
                  />
                )}

                {smallCaption}
              </span>
            )}
          </span>
        )}
      </SimpleAnimation>
    </View>
  );
}

export default React.memo(Button);
