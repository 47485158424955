import { state_items } from "../../state/state_items";

// https://dailydevsblog.com/troubleshoot/resolved-javascript-regex-for-youtube-video-and-shorts-id-168636/
function getYouTubeId(url: string) {
  let regex =
    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;

  if (regex !== null) {
    const matches = regex.exec(url);
    return matches === null ? false : matches[3];
  }

  return false;
}

// console.log(
//   getYouTubeId("https://youtube.com/shorts/GRwaTPKq1qg?feature=share")
// );
// console.log(getYouTubeId("https://www.youtube.com/watch?v=iK1uBBv83M0"));
//console.log(getYouTubeId("https://www.youtube.com/watch?v=FcoVzLyFqNs"));

export function processRawInput(itemId: string, rawInput: string) {
  const youTubeId = getYouTubeId(rawInput);
  if (!youTubeId) return false;

  state_items.updateItem(itemId, {
    type: "youTube",
    youTubeId,
    isProcessed: true,
  });

  return "youTube";
}
