import { lib_isBase64ImageItem } from "../../../libs/libs_image";
import { actions_addBase64Image } from "../../../state/actions/actions_addBase64Image";
import { Position } from "../../lib/Position/Position";
import "./ImageUploadButton.scss";

const onUpload = (): Promise<void> => {
  return new Promise<void>(async (resolve, reject) => {
    const filePicker = document.querySelector("input");

    if (!filePicker || !filePicker.files || filePicker.files.length <= 0) {
      reject("No file selected.");
      return;
    }

    const imageFile = filePicker.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        const base64 = reader.result?.toString();
        if (base64 && lib_isBase64ImageItem(base64)) {
          actions_addBase64Image(base64);
        }
      },
      false
    );

    reader.readAsDataURL(imageFile);
    resolve();
  });
};

export function ImageUploadButton() {
  return (
    <Position inset={0} opacity={0}>
      <input
        type="file"
        accept="image/x-png,image/jpeg,image/gif"
        onChange={onUpload}
      />
    </Position>
  );
}
