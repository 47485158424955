import { useWindowHeight } from "@react-hook/window-size";
import classNames from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import baseSwipeConfig from "../../../config/baseSwipeConfig";
import { ANIMATIONS_DURATION } from "../../../config/constants";
import "./BottomSheetContent.scss";

function BottomSheetContent({
  doHide,
  doHideCallback,
  backgroundColor = "white",
  canResizeFullscreen = false,
  isSwipeable = true,
  shadowColor,
  children,
  Header,
  Footer,
}: {
  doHide: boolean;
  doHideCallback?: () => void;
  backgroundColor?: string;
  canResizeFullscreen?: boolean;
  isSwipeable?: boolean;
  shadowColor?: string;
  children: ReactNode;
  Header?: ReactNode;
  Footer?: ReactNode;
}) {
  const childrenRef = useRef<HTMLDivElement>(null);
  const hasReachedTop = useRef(true);
  const hasReachedBottom = useRef(true);

  const windowHeight = useWindowHeight();
  const [translateY, setTranslateY] = useState(windowHeight);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [animationDuration, setAnimationDuration] =
    useState(ANIMATIONS_DURATION);

  useEffect(() => {
    if (doHide) {
      setTranslateY(windowHeight);
    } else {
      setTranslateY(0);
    }

    return () => {
      setTranslateY(0);
    };
  }, [doHide, windowHeight]);

  useEffect(() => {
    onScroll();
  }, []);

  const doHideLocal = () => {
    doHideCallback && doHideCallback();
  };

  const onScroll = () => {
    if (childrenRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = childrenRef.current;

      hasReachedTop.current = scrollHeight === clientHeight;
      hasReachedBottom.current = scrollHeight === clientHeight;

      // top reached
      if (scrollTop === 0) {
        hasReachedBottom.current = true;
      }

      // bottom reached
      if (Math.floor(scrollTop + clientHeight) === scrollHeight) {
        hasReachedTop.current = true;
      }
    }
  };

  const swipeHandlers = useSwipeable({
    onSwiping: (eventData) => {
      setIsSwiping(true);
      setAnimationDuration(0);
      setTranslateY(Math.min(Math.max(-100, eventData.deltaY)));

      if (eventData.deltaY > 100 && isFullscreen) {
        setAnimationDuration(2 * ANIMATIONS_DURATION);
        setIsFullscreen(false);

        setTimeout(() => {
          // TODO
          setAnimationDuration(0);
        }, 2000);

        return;
      }

      if (canResizeFullscreen) {
        if (eventData.deltaY <= -100) {
          setAnimationDuration(ANIMATIONS_DURATION);
          setIsFullscreen(true);
          setTranslateY(0);
          return;
        }
      }
    },

    onSwiped: (eventData) => {
      // TODO clearTimeout. Delay so it won't trigger an onClick Event on Buttons
      window.setTimeout(() => {
        setIsSwiping(false);
      }, 100);

      setAnimationDuration(ANIMATIONS_DURATION);
      if (eventData.deltaY > 100) {
        doHideLocal();
        return;
      }

      if (canResizeFullscreen) {
        if (eventData.deltaY <= -100) {
          setAnimationDuration(ANIMATIONS_DURATION);
          setIsFullscreen(true);
          setTranslateY(0);
          return;
        }
      }

      setTranslateY(0);
    },
    ...baseSwipeConfig,
  });

  const _swipeHandlers = isSwipeable ? swipeHandlers : undefined;
  const isFloating = !isFullscreen && isSwipeable; // adds a margin

  return (
    <div
      {..._swipeHandlers}
      className={classNames({
        BottomSheetContent: true,
        isFullscreen,
        isFloating,
        isNotFloating: !isFloating,
        isSwipeable,
        isSwiping,
      })}
      style={{
        transform: `translateY(${translateY}px)`,
        transition: `transform ${animationDuration}ms`,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor,
          boxShadow: shadowColor ? `0 0 20px ${shadowColor}` : undefined,
          transition: `background-color ${ANIMATIONS_DURATION}ms`,
          borderRadius: isFloating ? 10 : 0,
          overflow: "hidden",
        }}
      >
        {isSwipeable && (
          <div className="BottomSheetHeaderHandler">
            <div />
            <div />
          </div>
        )}

        {Header}

        <div
          ref={childrenRef}
          className="BottomSheetChildren"
          onScrollCapture={onScroll}
        >
          {children}
        </div>
        {Footer}
      </div>
    </div>
  );
}

export default React.memo(BottomSheetContent);
