// https://stackoverflow.com/questions/20958078/resize-a-base-64-image-in-javascript-without-using-canvas
export function lib_resizeBase64Image(
  base64: string,
  newWidth: number,
  newHeight: number,
  onResult: (base64: string) => void
) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (ctx === null) throw Error("Cannot resize image");

  canvas.width = newWidth;
  canvas.height = newHeight;

  const image = new Image();
  image.onload = function () {
    ctx.drawImage(image, 0, 0, newWidth, newHeight);
    onResult(canvas.toDataURL());
  };
  image.src = base64;
}

export function lib_isBase64ImageItem(base64: string) {
  return base64.substring(0, 10) === "data:image";
}

// https://stackoverflow.com/a/58534665
export function lib_blobToFile(theBlob: Blob, fileName: string): File {
  // TODO quick hack, this might break:
  const fileNameWithExtension = fileName + "." + theBlob.type.split("/")[1];
  return new File([theBlob], fileNameWithExtension, {
    type: theBlob.type,
    lastModified: new Date().getTime(),
  });
}
