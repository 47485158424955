import { actions_goBack } from "../state/actions/actions_goBack";

const popStateHandler = () => {
  // https://stackoverflow.com/a/49719812
  actions_goBack();
};

const keyDownHandler = (e: KeyboardEvent) => {
  if (e.key === "Escape") {
    actions_goBack();
  }
};

export function startup_registerGoBackBindings() {
  window.addEventListener("popstate", popStateHandler);
  window.addEventListener("keydown", keyDownHandler);
}
