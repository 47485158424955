// SOURCE => https://github.com/cra-template/pwa/blob/main/packages/cra-template-pwa-typescript/template/src/serviceWorkerRegistration.ts

import { lib_encodeHtmlEntities } from "./libs/functions/lib_encodeHtmlEntities";
import { actions_addBase64Image } from "./state/actions/actions_addBase64Image";
import { actions_addItem } from "./state/actions/actions_addItem";
import { actions_reloadApp } from "./state/actions/actions_reloadApp";

function register() {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    const channel = new BroadcastChannel("items-channel");
    channel.addEventListener("message", (event) => {
      const data = event.data;
      console.log("Received Broadcast", { data });
      //   console.log("received message", { event });
      if (event.data.action === "receive-image" && event.data.base64) {
        actions_addBase64Image(event.data.base64);
      }

      if (event.data.action === "receive-rawInput" && event.data.rawInput) {
        actions_addItem({
          rawInput: lib_encodeHtmlEntities(event.data.rawInput),
        });
      }
    });

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      registerValidSW(swUrl);
    });
  }
}

function registerValidSW(swUrl: string) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      let updating = false;
      // Function handler for the ServiceWorker updates.
      registration.onupdatefound = () => {
        const serviceWorker = registration.installing;
        if (serviceWorker == null) {
          return;
        }

        // https://stackoverflow.com/a/63058486
        // Listen to the browser's service worker state changes
        serviceWorker.addEventListener("statechange", () => {
          // IF ServiceWorker has been installed
          // AND we have a controller, meaning that the old chached files got deleted and new files cached
          // AND ServiceWorkerRegistration is waiting
          // THEN let ServieWorker know that it can skip waiting.
          if (
            serviceWorker.state === "installed" &&
            navigator.serviceWorker.controller &&
            registration &&
            registration.waiting
          ) {
            updating = true;
            // In my "~/serviceworker.js" file there is an event listener that got added to listen to the post message.
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
          }

          // IF we had an update of the cache files and we are done activating the ServiceWorker service
          // THEN let the user know that we updated the files and we are reloading the website.
          if (updating && serviceWorker.state === "activated") {
            // I am using an alert as an example, in my code I use a custom dialog that has an overlay so that the user can't do anything besides clicking okay.
            console.log(
              "The cached files have been updated, the browser will re-load."
            );

            actions_reloadApp();
          }
        });
      };
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export const serviceWorkerRegistration = { register, unregister };
