import React, { useEffect, useState } from "react";
import BasicItemImage from "../../../components/app/BasicItem/BasicItemImage";
import BasicItemText from "../../../components/app/BasicItem/BasicItemText";
import { state_albumImages } from "../../../state/state_albumImages";
import { state_itemDraft } from "../../../state/state_itemDraft";
import { $Item } from "../../types";
import { $Base64ImageItem } from "../types";
import "./Base64ImageItem.scss";
import { base64ImageToBlob } from "./base64ImageToBlob";

type $ImageEntry = {
  imageSrc: string;
  createdAt: Date;
};
type $ImageCache = { [itemId: string]: $ImageEntry };
const imageCache: $ImageCache = {};

function Base64ImageItem({
  item,
  isInFocus,
}: {
  item: $Item;
  isInFocus: boolean;
}) {
  const [isImageUrlCached, setIsImageUrlCached] = useState(
    imageCache[item.id] !== undefined
  );

  const base64ImageItem = item as $Base64ImageItem;

  useEffect(() => {
    if (imageCache[item.id]) {
      return;
    }

    base64ImageToBlob(base64ImageItem.base64, (imageBlob) => {
      // console.log("blob was calculated. back in main thread", itemId);
      imageCache[item.id] = {
        imageSrc: URL.createObjectURL(imageBlob),
        createdAt: new Date(),
      };

      setIsImageUrlCached(true);
    });
  }, [base64ImageItem.base64, item.id]);

  // console.log({ itemId: item.id, imageCache });
  // console.log("<ImageItem>", item.id, imageItem.imageSrc);

  const doZoomIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    state_albumImages.set([imageCache[item.id].imageSrc]);
  };

  const text =
    (isInFocus && state_itemDraft.get(item.id)?.text) ||
    base64ImageItem.text ||
    "";

  // console.log(base64ImageItem.base64);
  return (
    <div className="Base64ImageItem">
      {isImageUrlCached && (
        <>
          <BasicItemText itemId={item.id} text={text} isInFocus={isInFocus} />

          {imageCache[item.id].imageSrc && (
            <BasicItemImage
              url={imageCache[item.id].imageSrc}
              alt="Put something meaningful here"
              onClick={doZoomIn}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Base64ImageItem;
