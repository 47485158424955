let isMobileBrowser = false;
if (typeof window !== "undefined") {
  isMobileBrowser =
    window.navigator && /Mobi/i.test(window.navigator.userAgent);
}

function lib_isMobileBrowser() {
  return isMobileBrowser;
}

export { lib_isMobileBrowser };
