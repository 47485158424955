import { state_gyroscopeData } from "../state/state_gyroscopeData";

function deviceOrientationHandler(event: DeviceOrientationEvent) {
  state_gyroscopeData.set({
    alpha: event.alpha || 0,
    beta: event.beta || 0,
    gamma: event.gamma || 0,
  });
}

export function startup_registerGyroscopeListener() {
  window.addEventListener("deviceorientation", deviceOrientationHandler);
}
