import { dripdrop } from "dripdrop";
import { $ItemType } from "../plugins/types";

export type $FilterItem = {
  isHearted?: boolean;
  type?: $ItemType;
  matchingIds?: string[];
};

let filterItem: $FilterItem = {};

function set(value: $FilterItem) {
  if (filterItem === value) return;

  filterItem = value;
  dripdrop.notifySubscribers("filterItem");
}

function setIsHearted(isHeartedValue: boolean | undefined) {
  filterItem.isHearted = isHeartedValue;
  dripdrop.notifySubscribers("filterItem");
}

function setType(typeValue: $ItemType | undefined) {
  filterItem.type = typeValue;
  dripdrop.notifySubscribers("filterItem");
}

function useSubscribe() {
  dripdrop.useSubscribe("filterItem");
  return filterItem;
}

function useSubscribeIsSet() {
  dripdrop.useSubscribe("filterItem");
  return getIsSet();
}


function get() {
  return filterItem;
}

function getType() {
  return filterItem.type || "all";
}

function getIsSet() {
  if (![undefined, "all"].includes(filterItem.type)) {
    return true;
  }

  return ![undefined, false].includes(filterItem.isHearted);
}

export const state_filterItem = {
  get,
  getType,
  set,
  useSubscribe,
  useSubscribeIsSet,
  setIsHearted,
  setType,
  getIsSet,
};
