import { dripdrop } from "dripdrop";

let isRecalcingItemIds: boolean = false;

function set(value: boolean) {
  if (isRecalcingItemIds === value) return;

  isRecalcingItemIds = value;
  dripdrop.notifySubscribers("isRecalcingItemIds");
}

function useSubscribe() {
  dripdrop.useSubscribe("isRecalcingItemIds");
  return isRecalcingItemIds;
}

function get() {
  return isRecalcingItemIds;
}

export const state_isRecalcingItemIds = {
  set,
  get,
  useSubscribe,
};
