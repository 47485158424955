import classNames from "classnames";
import React, {useCallback, useEffect} from "react";
import {state_filterItem} from "../../../state/state_filterItem";
import {state_hasBeenUpdatedItemId} from "../../../state/state_hasBeenUpdatedItemId";
import {state_isItemsDataLoaded} from "../../../state/state_isItemDataLoaded";
import {state_isListScrolledToBottom} from "../../../state/state_isListScrolledToBottom";
import {state_isScrolling} from "../../../state/state_isScrolling";
import {state_isTyping} from "../../../state/state_isTyping";
import {state_itemsListHandle} from "../../../state/state_itemListHandle";
import {state_numLoadedItems} from "../../../state/state_numLoadedItems";
import {state_openedItemId} from "../../../state/state_openedItemId";
import {state_toBeHiddenItemId} from "../../../state/state_toBeHiddenItemId";
import {superState_itemIds} from "../../../superState/superState_itemIds";
import {FullscreenLoader} from "../../lib/Loader/Loader";
import {Position} from "../../lib/Position/Position";
import ItemsListEmpty from "../ItemsListEmpty/ItemsListEmpty";
import ItemsListItems from "../ItemsListItems/ItemsListItems";
import "./ItemsList.scss";
import ListTopMarker from "../ListTopMarker/ListTopMarker";

const ItemsList = function () {
  const isItemsDataLoaded = state_isItemsDataLoaded.useSubscribe();
  const itemsListHandle = state_itemsListHandle.useSubscribe();

  const openedItemId = state_openedItemId.useSubscribe();
  const numLoadedItems = state_numLoadedItems.useSubscribe();
  const isTyping = state_isTyping.useSubscribe();

  const toBeHiddenItemId = state_toBeHiddenItemId.useSubscribe();
  const hasBeenUpdatedItemId = state_hasBeenUpdatedItemId.useSubscribe();

  const currentIsAtBottom = state_isListScrolledToBottom.useSubscribe();

  const itemsListRef = useCallback<React.RefCallback<HTMLDivElement>>(
    (node) => {
      if (node !== null) {
        state_itemsListHandle.set(node);
      }
    },
    []
  );

  const unsetOpenedItemId = useCallback(() => {
    state_openedItemId.set(undefined);
  }, []);

  useEffect(() => {
    if (!itemsListHandle) return;

    const handleScroll = (e: Event) => {
      // Only prevent default and unset opened item if an item is actually opened
      if (state_openedItemId.get()) {
        e.preventDefault();
        unsetOpenedItemId();
      }
    };

    // Add event listeners with passive: false only when an item is opened
    if (state_openedItemId.get()) {
      itemsListHandle.addEventListener("touchmove", handleScroll, {
        passive: false,
      });
      itemsListHandle.addEventListener("wheel", handleScroll, {passive: false});

      return () => {
        itemsListHandle.removeEventListener("touchmove", handleScroll);
        itemsListHandle.removeEventListener("wheel", handleScroll);
      };
    }
  }, [itemsListHandle, unsetOpenedItemId]);

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (isTyping) return;

      const target = e.currentTarget;
      const isAtBottom = target.scrollTop === 0;

      // Scroll-Status aktualisieren
      state_isScrolling.set(true);

      // Only update state if the value has changed
      if (currentIsAtBottom !== isAtBottom) {
        state_isListScrolledToBottom.set(isAtBottom);
      }
    },
    [currentIsAtBottom, isTyping]
  );

  // console.log("ItemsList", { isItemsDataLoaded, color, itemIds });

  let itemIds: string[] = [];
  const itemIdsRaw = superState_itemIds.useSubscribe();
  const filterItem = state_filterItem.useSubscribe();

  if (itemIdsRaw.length) {
    // Apply filters
    let filteredIds = itemIdsRaw;
    if (filterItem.matchingIds) {
      filteredIds = itemIdsRaw.filter((id) =>
        filterItem.matchingIds?.includes(id)
      );
    }
    itemIds = filteredIds.slice(0, numLoadedItems);
  }

  if (!isItemsDataLoaded) {
    return <FullscreenLoader />;
  }

  const classes = {
    ItemsList: classNames("ItemsList", {
      isItemOpened: openedItemId !== undefined,
      isTyping,
    }),
  };

  if (isItemsDataLoaded && itemIds.length === 0) {
    return <ItemsListEmpty />;
  }

  return (
    <Position className="ItemsList_Position" flex={1} inset={0}>
      {itemIds.length > 0 && (
        <div
          className={classes.ItemsList}
          ref={itemsListRef}
          onScroll={onScroll}
        >
          <ItemsListItems
            itemIds={itemIds}
            openedItemId={openedItemId}
            hasBeenUpdatedItemId={hasBeenUpdatedItemId}
            toBeHiddenItemId={toBeHiddenItemId}
          />

          {itemIds.length > 0 && <ListTopMarker />}
        </div>
      )}
    </Position>
  );
};

export default React.memo(ItemsList);
