import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import React, { ReactNode } from "react";
import "./BasicItem.scss";
import BasicItemIcon from "./BasicItemIcon";
import BasicItemText from "./BasicItemText";

function BasicItem({
  itemId,
  icon,
  text,
  isInactive = false,
  isInFocus,
  onClick,
}: {
  itemId: string;
  icon?: IconDefinition | ReactNode;
  text: string;
  isInactive?: boolean;
  isInFocus?: boolean;
  onClick: React.MouseEventHandler;
}) {
  const basicItemStyle = {
    transform: `translateX(${icon ? -5 : 0}px)`,
    //backgroundColor: colorAlpha(backgroundColor, isInactive ? 0.25 : 1),
  };

  return (
    <div
      className={classNames("BasicItem", { isInactive })}
      style={basicItemStyle}
    >
      {icon && <BasicItemIcon icon={icon} onClick={onClick} />}
      <BasicItemText itemId={itemId} text={text} isInFocus={isInFocus} />
    </div>
  );
}

export default BasicItem;
