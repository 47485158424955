import { $TodoItem } from "../../plugins/todo/types";
import { sounds_setIsDone } from "../../sounds/sounds";
import { state_hasBeenUpdatedItemId } from "../state_hasBeenUpdatedItemId";
import { state_items } from "../state_items";
import { state_openedItemId } from "../state_openedItemId";

export function actions_toggleItemIsDone(itemId: string) {
  const item = state_items.getItem(itemId);

  if (item.type !== "todo") {
    return;
  }

  const todoItem = item as $TodoItem;

  if (!todoItem.isDone) {
    sounds_setIsDone.play();
  }

  state_items.updateItem(itemId, { isDone: !todoItem.isDone });
  state_hasBeenUpdatedItemId.set(itemId);

  if (itemId !== state_openedItemId.get()) {
    state_openedItemId.set(undefined);
  }
}
