import { dripdrop } from "dripdrop";

let areAnimationsSlowed: boolean = false;

function set(value: boolean) {
  if (areAnimationsSlowed === value) return;

  areAnimationsSlowed = value;
  dripdrop.notifySubscribers("areAnimationsSlowed");
}

function get() {
  return areAnimationsSlowed;
}

function useSubscribe() {
  dripdrop.useSubscribe("areAnimationsSlowed");
  return areAnimationsSlowed;
}

export const state_areAnimationsSlowed = {
  get,
  set,
  useSubscribe,
};
