import React from "react";
import {
  ANIMATIONS_DURATION,
  ANIMATIONS_ENTRY_ANIMATION,
} from "../../../config/constants";
import { RelativePosition } from "../../lib/RelativePosition/RelativePosition";
import { SimpleAnimation } from "../../lib/SimpleAnimation/SimpleAnimation";
import "./ItemsListEmpty.scss";

function ItemsListEmpty() {
  return (
    <RelativePosition
      className="ItemsListEmpty"
      flex={1}
      justifyContent="center"
      alignItems="center"
    >
      <SimpleAnimation
        showAnimation={ANIMATIONS_ENTRY_ANIMATION}
        showAnimationDuration={ANIMATIONS_DURATION}
      >
        <span>No Result</span>
      </SimpleAnimation>
    </RelativePosition>
  );
}

export default React.memo(ItemsListEmpty);
