// https://stackoverflow.com/a/69769645
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { $Item } from "../../types";
import { $YouTubeItem } from "../types";
import "./YouTubeItem.scss";

function YouTubeItem({ item }: { item: $Item }) {
  const youTubeItem = item as $YouTubeItem;

  if (item === undefined) return null;

  // console.log("<YouTubeItem>", item.id, youTubeItem.youTubeId);

  return (
    <div className="YouTubeItem">
      <iframe
        src={"https://www.youtube.com/embed/" + youTubeItem.youTubeId}
        width="560"
        height="315"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default React.memo(YouTubeItem);
