import { actionPushNotification } from "../components/lib/PushNotifications/pushNotifications.state";
import { lib_isMobileBrowser } from "../libs/functions/lib_isMobileBrowser";
import { actions_addBasicItem } from "../state/actions/actions_addBasicItem";
import { actions_addClipboardText } from "../state/actions/actions_addClipboardText";
import { actions_addLocationItem } from "../state/actions/actions_addLocationItem";
import { actions_deleteOpenedItem } from "../state/actions/actions_deleteOpenedItem";
import { actions_jumpToItem } from "../state/actions/actions_jumpToItem";
import { actions_toggleItemIsBought } from "../state/actions/actions_setItemIsBought";
import { actions_toggleItemIsDone } from "../state/actions/actions_setItemIsDone";
import { actions_setItemIsHearted } from "../state/actions/actions_setItemIsHearted";
import { action_submitOpenedItem } from "../state/actions/actions_submitOpenedItem";
import { state_filterItem } from "../state/state_filterItem";
import { state_isAddItemMenuOpened } from "../state/state_isAddItemMenuOpened";
import { state_isItemFocused } from "../state/state_isItemFocused";
import { state_isTyping } from "../state/state_isTyping";
import { state_items } from "../state/state_items";
import { state_openedItemId } from "../state/state_openedItemId";
import { superState_currentScreen } from "../superState/superState_currentScreen";
import { superState_itemIds } from "../superState/superState_itemIds";
import { superState_sortedTypes } from "../superState/superState_sortedTypes";

const PAGE_SIZE = 10;
const DELTA_INFINITY = 999999999999999;

// outside of react context
// Useful: https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes/
function keyDownHandler(e: KeyboardEvent) {
  const currentScreen = superState_currentScreen.get();
  const itemIds = superState_itemIds.get();

  if (currentScreen === "list") {
    if (["Insert", "Space"].includes(e.code) || e.key === "+") {
      if (!e.metaKey) {
        e.preventDefault();
        state_isAddItemMenuOpened.set(true);
      }
    }

    // if (["Home", "End", "PageUp", "PageDown"].includes(e.code)) {
    //   e.preventDefault();
    // }

    if (e.key === "Home") {
      e.preventDefault();
      if (itemIds.length > 0) {
        state_openedItemId.set(itemIds[itemIds.length - 1]);
      }
    }

    if (e.key === "End") {
      e.preventDefault();
      if (itemIds.length > 0) {
        state_openedItemId.set(itemIds[0]);
      }
    }

    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      e.preventDefault();
      const sortedTypes = superState_sortedTypes.get();
      const currentFilterItem = state_filterItem.get();

      let currentIndex = 0;
      if (currentFilterItem.type) {
        currentIndex = sortedTypes.findIndex(
          (t) => t.type === currentFilterItem.type
        );
      }

      const nextIndex = Math.min(
        sortedTypes.length - 1,
        Math.max(0, currentIndex + (e.key === "ArrowLeft" ? -1 : +1))
      );

      state_filterItem.setType(sortedTypes[nextIndex].type);
    }

    if (e.key === "PageUp" || e.key === "PageDown") {
      e.preventDefault();
      actions_jumpToItem(e.key === "PageDown" ? -PAGE_SIZE : +PAGE_SIZE);
    }

    if (["ArrowUp", "ArrowDown"].includes(e.code)) {
      e.preventDefault();

      if (itemIds.length > 0) {
        const openedItemId = state_openedItemId.get();
        if (openedItemId === undefined) {
          state_openedItemId.set(itemIds[0]);
        }
      }
    }
  }

  if (currentScreen === "item-opened") {
    if (e.key === "h") {
      e.preventDefault();
      const openedItemId = state_openedItemId.get();
      if (openedItemId) {
        const openedItem = state_items.getItem(openedItemId);
        actions_setItemIsHearted(openedItemId, !openedItem.isHearted);
      }
      return;
    }

    if (e.key === "Delete") {
      e.preventDefault();
      actions_deleteOpenedItem();
    }

    if (e.code === "Space") {
      e.preventDefault();
      const openedItemId = state_openedItemId.get();
      if (openedItemId) {
        actions_toggleItemIsBought(openedItemId);
        actions_toggleItemIsDone(openedItemId);
      }
    }

    if (e.key === "Enter") {
      e.preventDefault();
      state_isItemFocused.set(true);
      state_isTyping.set(true);
    }

    if (e.key === "Home") {
      e.preventDefault();
      actions_jumpToItem(DELTA_INFINITY);
    }

    if (e.key === "End") {
      e.preventDefault();
      actions_jumpToItem(-DELTA_INFINITY);
    }

    if (e.key === "PageUp" || e.key === "PageDown") {
      e.preventDefault();
      actions_jumpToItem(e.key === "PageDown" ? -PAGE_SIZE : +PAGE_SIZE);
    }

    if (["ArrowUp", "ArrowDown"].includes(e.key)) {
      e.preventDefault();

      if (itemIds.length > 0) {
        const openedItemId = state_openedItemId.get();
        const openedItemIdIndex = itemIds.findIndex(
          (itemId) => openedItemId === itemId
        );

        if (openedItemIdIndex >= 0) {
          actions_jumpToItem(e.key === "ArrowDown" ? -1 : +1);
        }
      }
    }
  }

  if (currentScreen === "additem-menu") {
    if (["Insert", "Space"].includes(e.code) || e.key === "+") {
      e.preventDefault();
      state_isAddItemMenuOpened.set(false);
    }

    if (e.key === "PageUp" || e.key === "PageDown") {
      e.preventDefault();
    }

    if (e.key === "p") {
      e.preventDefault();
      actions_addClipboardText();
    }
    if (e.key === "n") {
      e.preventDefault();
      actions_addBasicItem("text");
    }
    if (e.key === "t") {
      e.preventDefault();
      actions_addBasicItem("todo");
    }
    if (e.key === "s") {
      e.preventDefault();
      actions_addBasicItem("shopping");
    }
    if (e.key === "i") {
      e.preventDefault();
      actionPushNotification(
        `Please ${lib_isMobileBrowser() ? "TAP" : "CLICK"} the 'Image' button.`
      );
    }
    if (e.key === "l") {
      actions_addLocationItem();
    }
  }

  if (currentScreen === "item-focused") {
    if (e.key === "PageUp" || e.key === "PageDown") {
      e.preventDefault();
    }

    if (e.key === "Enter") {
      e.preventDefault();
      action_submitOpenedItem();
    }
  }
}

// App-wide key bindings. Should only be used once in App.tsx
export function startup_registerKeyBindings() {
  window.addEventListener("keydown", keyDownHandler);
}
