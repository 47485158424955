import { ANIMATIONS_DURATION } from "../../../config/constants";
import { isBasicItem } from "../../../plugins/types";
import { state_isItemFocused } from "../../../state/state_isItemFocused";
import { state_items } from "../../../state/state_items";
import { state_openedItemId } from "../../../state/state_openedItemId";
import { Position } from "../../lib/Position/Position";
import { SimpleAnimation } from "../../lib/SimpleAnimation/SimpleAnimation";
import Item from "../Item/Item";
import TypeSwitcher from "../TypeSwitcher/TypeSwitcher";
import "./ItemInFocus.scss";

function ItemInFocus() {
  const openedItemId = state_openedItemId.useSubscribe();
  const isItemFocused = state_isItemFocused.useSubscribe();
  const isItemOpened = openedItemId !== undefined;
  const doShow = isItemOpened && isItemFocused;

  if (!doShow) return null;

  const openedItem = state_items.getItem(openedItemId);
  const showTypeSwitcher = isBasicItem(openedItem);

  return (
    <Position
      className="ItemInFocus_Position"
      inset={0}
      pointerEvents={isItemFocused ? "auto" : "none"}
      flexDirection="column"
      backgroundColor="#ccc"
    >
      <div className="ItemInFocus">
        {showTypeSwitcher && <TypeSwitcher />}

        {openedItemId && (
          <div className="ItemInFocusPadder">
            <SimpleAnimation
              flex={1}
              showAnimation="bounceIn"
              hideAnimation="fadeOut"
              doShow={doShow}
              showAnimationDelay={ANIMATIONS_DURATION / 2}
              showAnimationDuration={ANIMATIONS_DURATION * 2}
            >
              <Item
                itemId={openedItemId}
                isOpened={true}
                isToBeHidden={false}
                isInFocus={true}
                renderIdx={undefined}
              />
            </SimpleAnimation>
          </div>
        )}
      </div>
    </Position>
  );
}

export default ItemInFocus;
