export function lib_getDaysBetween(startDate: Date, endDate: Date) {
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)

  const start = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );
  const end = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );

  // so it's safe to divide by 24 hours
  return (start - end) / oneDay;
}

export class lib_DateSwitcher {
  previousDaysBetween: undefined | number = undefined;

  hasDateDaySwitched(date: Date) {
    const today = new Date();
    const daysBetween = lib_getDaysBetween(date, today);

    // console.log({
    //   previousDaysOld: this.previousDaysBetween,
    //   db: daysBetween,
    // });

    if (daysBetween !== this.previousDaysBetween) {
      this.previousDaysBetween = daysBetween;
      return true;
    }

    return false;
  }
}

export function lib_getDateCaption(date: Date, today = new Date()) {
  const dayText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  let captionLight = "";
  let caption = "";
  const daysInBetween = lib_getDaysBetween(date, today);

  if (daysInBetween === 0) {
    return { caption, captionLight: "Today" };
  }

  if (daysInBetween === 1) {
    return { caption, captionLight: "Yesterday" };
  }

  if (daysInBetween < 7) {
    return {
      captionLight: dayText[date.getDay()],
      caption: daysInBetween + " days ago",
    };
  }

  const weeksInBetween = Math.floor(daysInBetween / 7);

  captionLight = dayText[date.getDay()];
  caption =
    weeksInBetween + " week" + (weeksInBetween === 1 ? "" : "s") + " ago";

  return { caption, captionLight };
}

// https://stackoverflow.com/a/1353711
export function lib_isValidDate(d: any) {
  if (Object.prototype.toString.call(d) === "[object Date]") {
    if (isNaN(d)) {
      return false;
    }

    return true;
  }
  return false;
}
