import { dripdrop } from "dripdrop";
import React from "react";

let openedItemId: string | undefined = undefined;

function set(value?: string) {
  if (openedItemId === value) return;

  React.startTransition(() => {
    // store old opened item id
    const oldOpenedItemId = openedItemId;

    // set value
    openedItemId = value;

    // trigger re-render of the old opened item
    if (oldOpenedItemId !== undefined) {
      dripdrop.notifySubscribers("isEqualToOpenedItemId-" + oldOpenedItemId);
    }

    // trigger re-render of the newly opened item
    if (openedItemId !== undefined) {
      dripdrop.notifySubscribers("isEqualToOpenedItemId-" + openedItemId);
    }

    dripdrop.notifySubscribers("openedItemId");
  });
}

function get() {
  return openedItemId;
}

function useSubscribe() {
  dripdrop.useSubscribe("openedItemId");
  return openedItemId;
}

function useSubscribeIsItemOpened() {
  dripdrop.useSubscribe("openedItemId");
  return openedItemId !== undefined;
}

function useSubscribeIsEqualToOpenedItemId(itemId: string) {
  dripdrop.useSubscribe("isEqualToOpenedItemId-" + itemId);
  return itemId === openedItemId;
}

export const state_openedItemId = {
  set,
  get,
  useSubscribe,
  useSubscribeIsItemOpened,
  useSubscribeIsEqualToOpenedItemId,
};
