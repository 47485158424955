import { dripdrop } from "dripdrop";
import { dexieDb } from "../dexieDb/dexieDb";

let settingsBackground: number = 1;

// on startup, see if there is some value already stored inside dexie
dexieDb.kvMap.get("settingsBackground").then((kv) => {
  if (kv) {
    set(parseInt(kv.value));
  }
});

function set(value: number) {
  if (settingsBackground === value) return;
  if (value < 1 || value > 9) value = 1;

  dexieDb.kvMap.put({
    key: "settingsBackground",
    value: value.toString(),
  });

  settingsBackground = value;
  dripdrop.notifySubscribers("settingsBackground");
}

function useSubscribe() {
  dripdrop.useSubscribe("settingsBackground");
  return settingsBackground;
}

export const state_settingsBackground = {
  set,
  useSubscribe,
};
