import { dripdrop } from "dripdrop";
import isOnline from "is-online";
import { dexieDb } from "../dexieDb/dexieDb";
import { lib_getDeviceId } from "../libs/functions/lib_getDeviceId";
import { $BasicItem, $Item, $ItemIdsMap, $ItemsMap } from "../plugins/types";
import { recalcItemIdsMap } from "./state_items/recalcItemIdsMap";

let itemsMap: $ItemsMap = {};
let itemIdsMap: $ItemIdsMap = {};

// WORKS!!
// completely replaces existing memory structures (itemsMap, itemIdsMap)
// BEWARE: this does NOT sync to indexedDB or server
// all items MUST be in indexeddb and supabase before calling this function
export function setItemsBulk(
  unsortedItems: $Item[],
  existingItemsMap: $ItemsMap = {}
) {
  return new Promise<void>((resolve) => {
    Object.values(existingItemsMap).forEach((i) => unsortedItems.push(i));

    const sortedItems = unsortedItems.sort(
      (a: $Item, b: $Item) => a.createdAt.getTime() - b.createdAt.getTime()
    );

    const newItemsMap: $ItemsMap = {};
    sortedItems.forEach(async (i: $Item) => {
      newItemsMap[i.id] = i;
    });

    // recalculate after bulk insert
    recalcItemIdsMap(newItemsMap, async (_itemIdsMap) => {
      // clear all item queues as we are just about to  insert a clean db state
      await dexieDb.itemIdsUpdatedQueue.clear();
      await dexieDb.itemIdsDeletedQueue.clear();

      // const numItemsBefore = await dexieDb.items.count();
      await dexieDb.items.clear();
      // console.log(`Removed ${numItemsBefore} existing items from IndexedDb`);
      await dexieDb.items.bulkPut(sortedItems);
      // console.log(`Saved ${sortedItems.length} items to IndexedDb`);

      // replace memory structures
      itemsMap = newItemsMap;
      setItemIdsMap(_itemIdsMap);

      dripdrop.notifySubscribers("itemIdsMap");
      resolve();
    });
  });
}

// WORKS!!
// adds to existing data structures
// all items MUST be in indexeddb and supabase before calling this function
export function addItemsBulk(sortedItems: $Item[]) {
  return setItemsBulk(sortedItems, itemsMap);
}

function addItem(newItem: $Item) {
  itemsMap[newItem.id] = newItem;

  dexieDb.items.put(newItem);
  dexieDb.itemIdsUpdatedQueue.put({
    itemId: newItem.id,
    createdAt: new Date(),
  });
  dripdrop.notifySubscribers("item-" + newItem.id);

  recalcItemIdsMap(itemsMap, (_itemIdsMap) => {
    setItemIdsMap(_itemIdsMap);
    dripdrop.notifySubscribers("itemIdsMap");
  });

  return newItem.id;
}

// TODO eliminate "any". Why does Partial<$Item> not work?
function updateItem(itemId: string, updates: any) {
  // sync
  itemsMap[itemId] = { ...itemsMap[itemId], ...updates };

  // async
  dexieDb.items.update(itemId, itemsMap[itemId]);
  dexieDb.itemIdsUpdatedQueue.put({ itemId, createdAt: new Date() });

  // sync
  dripdrop.notifySubscribers("item-" + itemId);
}

// async function that deletes and notifys "itemIds".
// it preserves the state of itemsMap until the recalc
// is done so we have no unreferencable itemsMap[itemId]
// somewhere else
function removeItem$(itemId: string) {
  return new Promise<void>((resolve) => {
    const oldItemsMap = { ...itemsMap };
    delete oldItemsMap[itemId];

    recalcItemIdsMap(oldItemsMap, (_itemIdsMap) => {
      delete itemsMap[itemId];
      dexieDb.items.delete(itemId);
      dexieDb.itemIdsDeletedQueue.put({ itemId, createdAt: new Date() });

      setItemIdsMap(_itemIdsMap);
      dripdrop.notifySubscribers("itemIdsMap");
      resolve();
    });
  });
}

function setItemIsHearted(itemId: string, isHearted: boolean | undefined) {
  itemsMap[itemId].isHearted = isHearted;
  dexieDb.items.update(itemId, { isHearted });
  dexieDb.itemIdsUpdatedQueue.put({ itemId: itemId, createdAt: new Date() });

  dripdrop.notifySubscribers("item-" + itemId);
  dripdrop.notifySubscribers("item-" + itemId + ".isHearted");
}

function useSubscribeItemIsHearted(itemId: string) {
  dripdrop.useSubscribe("item-" + itemId + ".isHearted");
  return itemsMap[itemId].isHearted;
}

function setItemText(itemId: string, text: string) {
  const basicItem = itemsMap[itemId] as $BasicItem;
  if (basicItem.text === text) {
    return;
  }

  basicItem.text = text;
  dexieDb.items.update(itemId, { text });
  dexieDb.itemIdsUpdatedQueue.put({ itemId: itemId, createdAt: new Date() });

  dripdrop.notifySubscribers("item-" + itemId);
  dripdrop.notifySubscribers("item-" + itemId + ".text");
}

function setItemRawInput(itemId: string, text: string) {
  itemsMap[itemId].rawInput = text;
  dexieDb.items.update(itemId, { rawInput: text });
  dexieDb.itemIdsUpdatedQueue.put({ itemId: itemId, createdAt: new Date() });
}

function setItemIsCreated(itemId: string) {
  if (itemsMap[itemId] === undefined) return;

  itemsMap[itemId].isCreated = true;
  dexieDb.items.update(itemId, { isCreated: true });
  dexieDb.itemIdsUpdatedQueue.put({ itemId: itemId, createdAt: new Date() });
  dripdrop.notifySubscribers("item-" + itemId);
}

function triggerRecalcItemIdsMap(callback?: () => void) {
  recalcItemIdsMap(itemsMap, (_itemIdsMap) => {
    setItemIdsMap(_itemIdsMap);
    dripdrop.notifySubscribers("itemIdsMap");

    if (callback) callback();
  });
}

function setItemType(itemId: string, type: any) {
  // TODO TS: "type: any"
  if (itemsMap[itemId].type === type) {
    return;
  }

  itemsMap[itemId].type = type;
  dexieDb.items.update(itemId, { type });
  dexieDb.itemIdsUpdatedQueue.put({ itemId: itemId, createdAt: new Date() });
  dripdrop.notifySubscribers("item-" + itemId);
  dripdrop.notifySubscribers("item-" + itemId + ".type");
}

function useSubscribeItemType(itemId: string | undefined) {
  dripdrop.useSubscribe("item-" + itemId + ".type");
  return itemId === undefined ? undefined : itemsMap[itemId].type;
}

function getItem(itemId: string) {
  return itemsMap[itemId];
}

function useSubscribeItem(itemId: string | undefined) {
  dripdrop.useSubscribe("item-" + itemId);

  if (itemId === undefined) {
    return undefined;
  }

  return itemsMap[itemId];
}

function getItemsMap() {
  return itemsMap;
}

////////////////////

function loadAllItemsFromSupabase(
  onSuccess: (items: $Item[]) => void,
  onError: (error: any) => void
) {
  loadItemsDiffFromSupabase([], onSuccess, onError);
}

async function loadItemsDiffFromSupabase(
  allItemIdsOnClient: string[],
  onSuccess: (items: $Item[]) => void,
  onError: (error: any) => void
) {
  if (!(await isOnline())) {
    onError("Your are offline");
    return;
  }

  fetch(
    "/.netlify/functions/functions_loadItemsDiffFromSupabase?deviceId=" +
    lib_getDeviceId() +
    "&allItemIdsOnClient=" +
    allItemIdsOnClient
  )
    .then((response) => response.json())
    .then((result: { items: $Item[] }) => {
      onSuccess(
        result.items.map((i) => ({
          ...i,
          createdAt: new Date(i.createdAt),
        }))
      );
    })
    .catch((e) => {
      onError("Could not run lambda function");
    });
}

function saveItemsMapToSupabase(onSuccess: () => void, onError: () => void) {
  //actionPushNotification("send url:" + url);

  fetch("/.netlify/functions/functions_saveItemsMapToSupabase", {
    method: "POST", // or 'PUT'
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ deviceId: lib_getDeviceId(), itemsMap }),
  })
    .then((response) => {
      onSuccess();
      return response.json();
    })
    .catch((error: any) => {
      console.log("AN ERROR");
      onError();
    });
}

async function saveItemUpdateToSupabase(
  itemId: string,
  onSuccess: () => void,
  onError: () => void
) {
  const item = state_items.getItem(itemId);
  if (!item || !item.isCreated) return;

  const response = await fetch(
    "/.netlify/functions/functions_saveItemUpdateToSupabase",
    {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        deviceId: lib_getDeviceId(),
        item,
      }),
    }
  );

  if (!response.ok) {
    onError();
    return;
  }

  onSuccess();
}

async function deleteItemFromSupabase(
  itemId: string,
  onSuccess: () => void,
  onError: () => void
) {
  const response = await fetch(
    "/.netlify/functions/functions_deleteItemFromSupabase",
    {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        deviceId: lib_getDeviceId(),
        itemId,
      }),
    }
  );

  if (!response.ok) {
    onError();
    return;
  }

  onSuccess();
}

function getNumAllItems() {
  return Object.values(itemsMap).filter((i) => i.isCreated).length;
}

function getItemIdsMap() {
  return itemIdsMap;
}

function setItemIdsMap(value: $ItemIdsMap) {
  if (itemIdsMap === value) return;

  itemIdsMap = value;
  dripdrop.notifySubscribers("itemIdsMap");
}

function useSubscribeItemIdsMap() {
  dripdrop.useSubscribe("itemIdsMap");
  return itemIdsMap;
}

function useSubscribeNumAllItems() {
  useSubscribeItemIdsMap();
  return getNumAllItems();
}

function startOrRestartItemProcessing(itemId: string) {
  state_items.updateItem(itemId, {
    startedProcessingAt: new Date(),
    isProcessed: false,
    isProcessedFailure: undefined,
  });
}

export const state_items = {
  // setters
  addItem,
  updateItem,
  removeItem$,
  setItemRawInput,
  setItemIsCreated,
  setItemText,
  setItemType,
  setItemIsHearted,
  triggerRecalcItemIdsMap,
  startOrRestartItemProcessing,

  setItemsBulk,
  addItemsBulk,

  // getters
  getItem,
  getItemsMap,
  getItemIdsMap,
  getNumAllItems,
  useSubscribeItemIdsMap,
  useSubscribeNumAllItems,
  useSubscribeItem,
  useSubscribeItemType,
  useSubscribeItemIsHearted,

  // supabase
  saveItemsMapToSupabase,
  loadItemsDiffFromSupabase,
  loadAllItemsFromSupabase,
  saveItemUpdateToSupabase,
  deleteItemFromSupabase,
};
