import React, { useCallback } from "react";
import BasicItem from "../../../components/app/BasicItem/BasicItem";
import { actions_toggleItemIsBought } from "../../../state/actions/actions_setItemIsBought";
import { state_itemDraft } from "../../../state/state_itemDraft";
import { $Item } from "../../types";
import { $ShoppingItem } from "../types";
import "./ShoppingItem.scss";
import ShoppingItemIcon from "./ShoppingItemIcon";

function ShoppingItem({
  item,
  isInFocus,
}: {
  item: $Item;
  isInFocus?: boolean;
}) {
  const shoppingItem = item as $ShoppingItem;
  const isBought = !!shoppingItem.isBought; // important as isBought can also be undefined

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      actions_toggleItemIsBought(item.id);
    },
    [item.id]
  );

  const text =
    (isInFocus && state_itemDraft.get(item.id)?.text) || shoppingItem.text;
  return (
    <BasicItem
      itemId={item.id}
      isInactive={shoppingItem.isBought}
      isInFocus={isInFocus}
      onClick={onClick}
      icon={<ShoppingItemIcon isBought={isBought} />}
      text={text}
    />
  );
}

export default ShoppingItem;
