import React from "react";
import { ANIMATIONS_DURATION } from "../../config/constants";
import { $ItemIdsMap, $ItemsMap } from "../../plugins/types";
import { state_filterItem } from "../state_filterItem"; // TODO access violation
import { state_isRecalcingItemIds } from "../state_isRecalcingItemIds"; // TODO access violation
import { recalcItemIdsMapWorker } from "./recalcItemIdsMapWorker";

export function recalcItemIdsMap(
  _itemsMap: $ItemsMap,
  onSuccess: (itemIdsMap: $ItemIdsMap) => void
) {
  let timeout: number = 0;

  if (state_filterItem.getIsSet()) {
    timeout = window.setTimeout(() => {
      state_isRecalcingItemIds.set(true);
    }, ANIMATIONS_DURATION / 2); // only show <Loader> if it takes more than ANIMATIONS_DURATION time...
  } else {
    state_isRecalcingItemIds.set(true);
  }

  // process the answer from our worker thread:
  recalcItemIdsMapWorker.onmessage = ({ data }) => {
    console.log(`<< Received filteredItemIds arrays from web worker`, data);

    if (timeout) window.clearTimeout(timeout); // make sure we don't show <Loader>

    state_isRecalcingItemIds.set(false);
    React.startTransition(() => {
      onSuccess(data.itemIdsMap);
    });
  };

  console.log(`>> Sending request to web worker:")`, { itemsMap: _itemsMap });

  // Send request to worker thread: for a given $FilterItem,
  // calculate the resulting map `itemIdsMap` of itemIds
  recalcItemIdsMapWorker.postMessage({ itemsMap: _itemsMap });
}
