import {faCircle, faHeart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Position} from "../../../components/lib/Position/Position";
import {SimpleAnimation} from "../../../components/lib/SimpleAnimation/SimpleAnimation";
import {$ItemType, getSpecForType} from "../../../plugins/types";
import {actions_setItemIsHearted} from "../../../state/actions/actions_setItemIsHearted";
import {state_filterItem} from "../../../state/state_filterItem";

import {state_items} from "../../../state/state_items";
import {state_toBeHiddenItemId} from "../../../state/state_toBeHiddenItemId";
import {RelativePosition} from "../../lib/RelativePosition/RelativePosition";
import "./HeartIndicator.scss";

function HeartIndicator({
  itemId,
  isClickable,
}: {
  itemId: string;
  isClickable: boolean;
}) {
  const isHearted = state_items.useSubscribeItemIsHearted(itemId);
  const type = state_items.useSubscribeItemType(itemId);
  const color = getSpecForType(type as $ItemType).color;

  return (
    <Position className="HeartIndicator_Position" left={-18}>
      <SimpleAnimation
        className="HeartIndicator_Animation"
        showAnimation={isHearted ? "rotateIn" : "zoomIn"}
      >
        <RelativePosition
          width={36}
          height={36}
          alignItems="center"
          justifyContent="center"
          className="HeartIndicator"
          rotate={(isHearted ? -25 : 0) + "px"}
          onClick={(e) => {
            if (!isClickable) return false;
            e.stopPropagation();

            const newIsHearted = !isHearted;

            const filterItem = state_filterItem.get();
            if (!filterItem.isHearted) {
              actions_setItemIsHearted(itemId, newIsHearted);
              state_items.triggerRecalcItemIdsMap();
              return;
            }

            // filter is Hearted is SET but item needs to be hidden now
            if (!newIsHearted) {
              actions_setItemIsHearted(itemId, newIsHearted);
              state_toBeHiddenItemId.set(itemId, () => {
                state_items.triggerRecalcItemIdsMap();
              });
            }
          }}
          scale={isHearted ? 1.25 : 1}
        >
          <FontAwesomeIcon
            // icon={isHearted ? faHeart : faCircle}
            icon={isHearted ? faHeart : faCircle}
            color={color}
          />
        </RelativePosition>
      </SimpleAnimation>
    </Position>
  );
}

export default React.memo(HeartIndicator);
