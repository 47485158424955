import { state_filterItem } from "../state/state_filterItem";
import { state_items } from "../state/state_items";
import { registeredPlugins } from "./registeredPlugins";
import { $Plugin } from "./types";

const TIMEOUT = 10 * 1000; // 10 seconds
function isDateOlderThanMs(date: Date, ms: number) {
  const timeoutDate = new Date(Date.now() - ms);
  return date < timeoutDate;
}

function startProcessUnprocessedItemsInterval() {
  return setInterval(processUnprocessedItems, 1000); // every seconds
}

function processUnprocessedItems() {
  const notYetProcessedItemIds = Object.values(state_items.getItemsMap())
    .filter((item) => {
      return !item.isProcessed;
    })
    .map((item) => item.id);
  // console.log("processUnprocessedItems", { notYetProcessedItemIds });

  //console.log("processUnprocessedItems", notYetProcessedItemIds);
  doProcessRawInput(notYetProcessedItemIds);
  doTimeoutWithFailureForOldProcessings(notYetProcessedItemIds);
}

function doTimeoutWithFailureForOldProcessings(
  notYetProcessedItemIds: string[]
) {
  // it is okay to be not processed, until a timeout is reached
  notYetProcessedItemIds.forEach((itemId) => {
    const item = state_items.getItem(itemId);

    // if that timeout was reached, set item to processed and set a failure
    if (
      item.startedProcessingAt &&
      isDateOlderThanMs(item.startedProcessingAt, TIMEOUT)
    ) {
      console.log("Timeout exceeded", { item });
      state_items.updateItem(item.id, {
        isProcessed: true,
        isProcessedFailure: "Timeout exceeded",
      });
    }
  });
}

function doProcessRawInput(notYetProcessedItemIds: string[]) {
  notYetProcessedItemIds.forEach((itemId) => {
    let didAPluginProcess = false;

    // see if any plugin wants to process the item
    for (const [, plugin] of Object.entries(registeredPlugins)) {
      // found one?
      if (!didAPluginProcess) {
        didAPluginProcess = pluginProcessRawInput(plugin, itemId);
      }
    }
  });
}

function pluginProcessRawInput(plugin: $Plugin, itemId: string) {
  const rawInput = state_items.getItem(itemId).rawInput;

  if (
    // if a plugin callback is configured
    plugin.processRawInput
  ) {
    // then check to see if it has claimed the item and processed its rawInput
    const itemType = plugin.processRawInput(itemId, rawInput || "");

    if (itemType === false) {
      // not processed. try next processor/plugin
      return false;
    }

    state_items.triggerRecalcItemIdsMap(() => {
      if (state_filterItem.getIsSet()) {
        state_filterItem.set({ isHearted: false, type: itemType });
      }
    });

    // signals, that the plugin processed and took over responsibility for the item
    return true;
  }

  // not processed. try next processor/plugin
  return false;
}

const itemProcessor = {
  startProcessUnprocessedItemsInterval,
};

export { itemProcessor };
