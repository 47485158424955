import { ANIMATIONS_DURATION } from "../../config/constants";
import { state_isAddItemMenuOpened } from "../state_isAddItemMenuOpened";
import { state_itemsListHandle } from "../state_itemListHandle";

// make sure to correctly close the overlay and - if needed - take
// next actions in a ux-controlled manner

export function actions_closeAddItemMenu(nextActions?: () => void) {
  state_isAddItemMenuOpened.set(false);
  const itemsListHandle = state_itemsListHandle.get();
  itemsListHandle?.scrollTo({ top: 0 }); // jump to bottom

  if (nextActions) {
    setTimeout(nextActions, ANIMATIONS_DURATION);
  }
}
