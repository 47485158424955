import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import colorAlpha from "color-alpha";
import React from "react";
import {VERTICAL_MARGIN} from "../../../config/constants";
import {actions_deleteOpenedItem} from "../../../state/actions/actions_deleteOpenedItem";
import {state_isItemFocused} from "../../../state/state_isItemFocused";
import {state_isScrolling} from "../../../state/state_isScrolling";
import {state_isTyping} from "../../../state/state_isTyping";
import {state_openedItemId} from "../../../state/state_openedItemId";
import Button from "../../lib/Button/Button";
import {Position} from "../../lib/Position/Position";

function ItemDeleteButton() {
  const isTyping = state_isTyping.useSubscribe();
  const isItemFocused = state_isItemFocused.useSubscribe();
  const openedItemId = state_openedItemId.useSubscribe();
  const isScrolling = state_isScrolling.useSubscribe();

  const doShow =
    openedItemId !== undefined && !isTyping && !isItemFocused && !isScrolling;

  if (!doShow) return null;
  return (
    <Position
      className="ItemDeleteButton_Position"
      bottom={10 + VERTICAL_MARGIN}
      left={10}
      pointerEvents={doShow ? "auto" : "none"}
    >
      <div className="ItemDeleteButton">
        <Button
          icon={faTrashAlt}
          iconScale={1.5}
          iconColor="red"
          captionColor={colorAlpha("red", 0.75)}
          backgroundColor="yellow"
          borderRadius={10}
          onClick={() => actions_deleteOpenedItem()}
        />
      </div>
    </Position>
  );
}

export default React.memo(ItemDeleteButton);
