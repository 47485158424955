import React from "react";
import { $LinkItem } from "../../../plugins/link/types";
import { $LocationItem } from "../../../plugins/location/types";
import { state_items } from "../../../state/state_items";
import { state_openedItemId } from "../../../state/state_openedItemId";
import TypeSwitcherChoice from "../TypeSwitcherChoice/TypeSwitcherChoice";
import { ALL_SELECTABLE_TYPES } from "./TypeSwitcher.constants";
import "./TypeSwitcher.scss";
import { $AllSelectableTypes } from "./TypeSwitcher.types";

function filterTypeChoicesForItem(openedItemId: string) {
  const openedItem = state_items.getItem(openedItemId);
  return ALL_SELECTABLE_TYPES.filter((t) => {
    if (t === "link") {
      const linkItem = openedItem as $LinkItem;
      return linkItem.url !== undefined;
    }

    if (t === "location") {
      const locationItem = openedItem as $LocationItem;
      return locationItem.infos !== undefined;
    }

    return true;
  });
}

function TypeSwitcher() {
  const openedItemId = state_openedItemId.useSubscribe();
  const openedItemType = state_items.useSubscribeItemType(openedItemId);
  if (!openedItemId) return null;

  const onClick = (type: $AllSelectableTypes) => {
    state_items.setItemType(openedItemId, type);
  };

  const typeChoicesForItem = filterTypeChoicesForItem(openedItemId);

  return (
    <div className="TypeSwitcher">
      {typeChoicesForItem.map((type, renderIdx) => {
        const isSelected = (openedItemType as string) === (type as string);
        return (
          <TypeSwitcherChoice
            key={type}
            renderIdx={renderIdx}
            type={type}
            isSelected={isSelected}
            onClick={() => onClick(type)}
          />
        );
      })}
    </div>
  );
}

export default React.memo(TypeSwitcher);
