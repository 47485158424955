import React from "react";
import {VERTICAL_MARGIN} from "../../../config/constants";
import {state_filterItem} from "../../../state/state_filterItem";
import {state_openedItemId} from "../../../state/state_openedItemId";
import {Position} from "../../lib/Position/Position";
import {RelativePosition} from "../../lib/RelativePosition/RelativePosition";
import {SimpleAnimation} from "../../lib/SimpleAnimation/SimpleAnimation";
import {FilterStatsContent} from "./FilterStatsContent";
import "./FilterStats.scss";
import {state_items} from "../../../state/state_items";
import {getSpecForType} from "../../../plugins/types";

// TODO refactor this file much

function FilterStats() {
  const isItemOpened = state_openedItemId.useSubscribeIsItemOpened();
  const filterItem = state_filterItem.useSubscribe();
  const isFilterSet = state_filterItem.useSubscribeIsSet();
  const typeSpec = getSpecForType(state_filterItem.getType());
  const numAllItems = state_items.useSubscribeNumAllItems();

  const doShow = !isItemOpened;
  // console.table({
  //   isItemOpened,
  //   doShow,
  //   typeSpec,
  //   filterItem,
  //   allFilteredItemIds,
  // });

  if (typeSpec === undefined || numAllItems === 0) return null;

  const onClickReset = () => {
    if (isFilterSet) {
      state_filterItem.set({});
    }
  };

  return (
    <Position
      className="FilterStats_Position"
      bottom={VERTICAL_MARGIN + 80}
      left={0}
      right={0}
      justifyContent="center"
      pointerEvents="none"
    >
      <SimpleAnimation
        key={filterItem.type}
        doShow={doShow}
        showAnimation="fadeIn"
        hideAnimation="zoomOut"
      >
        <RelativePosition
          className="FilterStats"
          onClick={onClickReset}
          pointerEvents="auto"
        >
          <div className="FilterStatsInner">
            <FilterStatsContent />
          </div>
        </RelativePosition>
      </SimpleAnimation>
    </Position>
  );
}

export default React.memo(FilterStats);
