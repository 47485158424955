import classNames from "classnames";
import React from "react";
import { state_settingsBackground } from "../../../state/state_settingsBackground";
import { Position } from "../../lib/Position/Position";
import "./Background.scss";

function Background() {
  const settingsBackground = state_settingsBackground.useSubscribe();

  const className = classNames("Background", {
    ["backgroundPattern" + settingsBackground]: true,
  });

  return <Position className={className} inset={0}></Position>;
}

export default React.memo(Background);
