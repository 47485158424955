import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faAlignLeft, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { lib_checkIsValidHttpUrl } from "../../../libs/functions/lib_checkIsValidHttpUrl";
import { Position } from "../../lib/Position/Position";
import { ADD_ITEM_MENU_HEIGHT } from "../AddItemMenuButton/AddItemMenuButton";
import "./PasteBar.scss";

function PasteBar({ onClick }: { onClick: () => void }) {
  const [clipboardText, setClipboardText] = useState("");

  useEffect(() => {
    navigator.clipboard
      .readText()
      .then((clipText) => setClipboardText(clipText.trim()));
  }, []);

  if (clipboardText === "") return null;
  const isValidHttpUrl = lib_checkIsValidHttpUrl(clipboardText);

  return (
    <Position
      className="PasteBar"
      bottom={ADD_ITEM_MENU_HEIGHT}
      left={0}
      right={0}
    >
      <div
        className={classNames("ClipboardInner", {
          isValidHttpUrl,
        })}
      >
        <div className="ClipboardText">
          {isValidHttpUrl && (
            <>
              <div className="ClipboardTextIcon">
                <FontAwesomeIcon color="black" icon={faLink} />
                LINK
              </div>
              <a href={clipboardText}>{clipboardText}</a>
            </>
          )}

          {!isValidHttpUrl && (
            <>
              <div className="ClipboardTextIcon">
                <FontAwesomeIcon color="black" icon={faAlignLeft} />
                Note
              </div>
              {clipboardText}
            </>
          )}
        </div>
        <div className="ClipboardPasteButton" onClick={onClick}>
          <FontAwesomeIcon icon={faClipboard} fontSize={20} />
          <div className="ClipboardPasteButtonText">PASTE</div>
        </div>
      </div>
    </Position>
  );
}

export default PasteBar;
