export function base64ImageToBlob(
  base64: string,
  onSuccess: (imageBlob: Blob) => void
) {
  const base64ImageToBlobWorker = new Worker(
    new URL("../../../webworkers/base64ImageToBlob.worker.ts", import.meta.url)
  );

  // process the answer from our worker thread:
  base64ImageToBlobWorker.onmessage = ({ data }) => {
    // console.log(`<< Received imageBlob from web worker`, itemId);
    onSuccess(data.imageBlob);
  };

  // console.log(`>> Sending request to web worker:`);

  // Send request to worker thread: for a given $FilterItem,
  // calculate the resulting map `itemIdsMap` of itemIds
  base64ImageToBlobWorker.postMessage({ base64 });
}
