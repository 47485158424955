import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colorAlpha from "color-alpha";
import React from "react";
import { ANIMATIONS_DURATION } from "../../../config/constants";
import { SimpleAnimation } from "../../lib/SimpleAnimation/SimpleAnimation";
import "./AddItemMenuTile.scss";

function AddItemMenuTile({
  idx,
  icon,
  caption,
  iconColor,
  onClick,
  children,
}: {
  idx: number;
  icon: IconDefinition;
  caption: string;
  onClick?: React.MouseEventHandler;
  iconColor?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className="AddItemMenuTile" onClick={onClick}>
      <SimpleAnimation
        flex={1}
        showAnimation="zoomIn"
        showAnimationDelay={
          ANIMATIONS_DURATION + (ANIMATIONS_DURATION * idx) / 10
        }
      >
        <div
          className="TileInner"
          style={{ backgroundColor: colorAlpha(iconColor || "black", 0.25) }}
        >
          {children && <>{children}</>}
          <FontAwesomeIcon icon={icon} color={iconColor || "black"} />
          <span style={{ color: iconColor || "black" }}>{caption}</span>
        </div>
      </SimpleAnimation>
    </div>
  );
}

export default React.memo(AddItemMenuTile);
