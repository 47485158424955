/* eslint-disable react/prop-types */
// https://stackoverflow.com/a/69769645
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import BasicItemImage from "../../../components/app/BasicItem/BasicItemImage";
import BasicItemText from "../../../components/app/BasicItem/BasicItemText";
import { RelativePosition } from "../../../components/lib/RelativePosition/RelativePosition";
import { state_isItemFocused } from "../../../state/state_isItemFocused";
import { state_isTyping } from "../../../state/state_isTyping";
import { state_itemDraft } from "../../../state/state_itemDraft";
import { $Item } from "../../types";
import { $LinkItem } from "../types";
import "./LinkItem.scss";

function LinkItem({ item, isInFocus }: { item: $Item; isInFocus?: boolean }) {
  const linkItem = item as $LinkItem;

  const doForceOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (linkItem.url) {
      window.open(linkItem.url, "_blank");
    }
  };

  const doOpen = (e: React.MouseEvent) => {
    if (isInFocus) {
      return;
    }

    doForceOpen(e);
  };

  const doZoomIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    state_isItemFocused.set(true);
    state_isTyping.set(true);
    return;
  };

  const text =
    (isInFocus && state_itemDraft.get(item.id)?.text) || linkItem.text || "";

  // processed with success
  return (
    <div className="LinkItem" onClick={doOpen}>
      <RelativePosition className="LinkItemUrl">
        <a href={linkItem.url} onClick={doForceOpen}>
          {linkItem.url}
        </a>
      </RelativePosition>

      <BasicItemText itemId={item.id} text={text} isInFocus={isInFocus} />

      {linkItem.imageUrl !== undefined && (
        <BasicItemImage
          url={linkItem.imageUrl}
          width={linkItem.imageWidth}
          height={linkItem.imageHeight}
          onClick={doZoomIn}
        />
      )}

      <span className="LinkPublisher">{linkItem.publisher}</span>
    </div>
  );
}

export default LinkItem;
