// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
export function lib_checkIsValidHttpUrl(urlString: string) {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
