import { dripdrop } from "dripdrop";

let isAddItemMenuOpened: boolean = false;

function set(value: boolean) {
  if (isAddItemMenuOpened === value) return;

  isAddItemMenuOpened = value;
  dripdrop.notifySubscribers("isAddItemMenuOpened");
}

function get() {
  return isAddItemMenuOpened;
}

function useSubscribe() {
  dripdrop.useSubscribe("isAddItemMenuOpened");
  return isAddItemMenuOpened;
}

export const state_isAddItemMenuOpened = {
  get,
  set,
  useSubscribe,
};
