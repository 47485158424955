import React from "react";
import BasicItemImage from "../../../components/app/BasicItem/BasicItemImage";
import BasicItemText from "../../../components/app/BasicItem/BasicItemText";
import { state_albumImages } from "../../../state/state_albumImages";
import { state_itemDraft } from "../../../state/state_itemDraft";
import { $Item } from "../../types";
import { $ImageItem } from "../types";
import "./ImageItem.scss";

function ImageItem({ item, isInFocus }: { item: $Item; isInFocus: boolean }) {
  const imageItem = item as $ImageItem;

  if (item === undefined) return null;

  //console.log("<ImageItem>", item.id, imageItem.imageSrc);

  const doZoomIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    state_albumImages.set([imageItem.imageSrc]);
  };

  const text =
    (isInFocus && state_itemDraft.get(item.id)?.text) || imageItem.text || "";

  return (
    <div className={"ImageItem"} onClick={doZoomIn}>
      <BasicItemText itemId={item.id} text={text} isInFocus={isInFocus} />
      <BasicItemImage
        url={imageItem.imageSrc}
        alt="Put something meaningful here"
      />
    </div>
  );
}

export default React.memo(ImageItem);
