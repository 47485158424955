import { state_isItemFocused } from "../state_isItemFocused";
import { state_isTyping } from "../state_isTyping";
import { state_openedItemId } from "../state_openedItemId";
import { actions_addItem } from "./actions_addItem";
import { actions_closeAddItemMenu } from "./actions_closeAddItemMenu";

export function actions_addBasicItem(type: "text" | "todo" | "shopping") {
  actions_closeAddItemMenu(() => {
    const insertId = actions_addItem({
      type,
      isProcessed: true,
      isCreated: false,
      text: "",
    });

    state_openedItemId.set(insertId);
    state_isItemFocused.set(true);
    state_isTyping.set(true);
  });
}
