import { dripdrop } from "dripdrop";
import { state_isTyping } from "../state/state_isTyping";
import { state_numLoadedItems } from "../state/state_numLoadedItems";
import { state_openedItemId } from "../state/state_openedItemId";

export function startup_registerReActions() {
  dripdrop.registerCallback("filterItem", () => {
    state_openedItemId.set(undefined);
    state_numLoadedItems.reset();
  });
  dripdrop.registerCallback("openedItemId", () => {
    if (!state_openedItemId.get()) {
      state_isTyping.set(false);
    }
  });
}
