import React from "react";
import { state_albumImages } from "../../../state/state_albumImages";

// This component makes the app scale-able whenever an image/album is
// opened into an overlay, allowing the user to pan & zoom
//
// Note: had tried to use react-helmet before but could'nt make it run
// so I switched to this vanillajs solution

function AppMetaViewport() {
  const albumImages = state_albumImages.useSubscribe();
  const canResizePage = albumImages !== undefined;

  const maximumScale = canResizePage ? "10.0" : "1.0";
  const content = [
    "height=device-height",
    "width=device-width",
    "initial-scale=1.0",
    "minimum-scale=1.0",
    "maximum-scale=" + maximumScale,
    "user-scalable=yes",
  ];

  let viewport = document.querySelector("meta[name=viewport]");
  viewport?.setAttribute("content", content.join(","));
  return null;
}

export default React.memo(AppMetaViewport);
