export const MAP_WIDTH = 500;
export const MAP_HEIGHT = 500;
export const MAP_DEFAULT_ZOOM_LEVEL = 17;
export const MAP_MIN_ZOOM_LEVEL = 10;
export const MAP_MAX_ZOOM_LEVEL = 19; // https://github.com/StephanGeorg/staticmaps/blob/e827d7491739a7e2963275fbec8af45e1d1053fa/README.md#L54

export const LAT_LNG_FERNSEHTURM_BERLIN = {
  lat: 52.52082327391582,
  lng: 13.409419552120086,
};

// is secured by my origin settings on maptiler.com. restricted to domain
export const MAPTILER_KEY = "iwgcXhCgjm8jWQqlTRJB";
