import { dripdrop } from "dripdrop";

type $ItemDraft = { text: string };
type $ItemDraftMap = { [key: string]: $ItemDraft };

let itemDraftMap: $ItemDraftMap = {};

function set(itemId: string, draft: $ItemDraft | undefined) {
  if (draft === undefined) {
    delete itemDraftMap[itemId];
  } else {
    itemDraftMap[itemId] = draft;
  }

  dripdrop.notifySubscribers("itemDraft-" + itemId);
}

function useSubscribe(itemId: string) {
  dripdrop.useSubscribe("itemDraft-" + itemId);
  return itemDraftMap[itemId];
}

function get(itemId: string) {
  return itemDraftMap[itemId] || undefined;
}

export const state_itemDraft = {
  set,
  get,
  useSubscribe,
};
